import React from 'react'
import Header from "../components/Layout/Header";
import SupportedHandles from '../components/Layout/SupportedHandles';
import TrustedBy from '../components/Layout/TrustedBy';
import Hero from "../components/Route/Hero/Hero";
import EarningSection from "../components/EarningSection";

//import BestDeals from "../components/Route/BestDeals/BestDeals";
//import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";

import Footer from "../components/Layout/Footer";

import HorizontalScroll from '../components/Route/HorizontalScroll/HorizontalScroll';
import HorizontalScroll2 from '../components/Route/HorizontalScroll/HorizontalScroll2';
import AnimHeroSection2 from "../AnimationHero/AnimHeroSection2";
import AnimHeroSection3 from "../AnimationHero/AnimHeroSection3";
import CompaniesScroll from "../AnimationHero/CompaniesScroll";
import CounterUp from "../AnimationHero/CounterUp";
import HorizontalCardNew from './HorizontalCardNew';
import HorizontalCardNew2 from './HorizontalCardNew2';
import Slider from '../components/Slider/Slider';
import SliderMobile from '../components/Slider/SliderMobile';
//import Hero2 from '../components/Route/Hero/Hero2';
import Hero3 from '../components/Route/Hero/Hero3';
//import DirectAds from '../pages/DirectAds/DirectAds';
import Intro from '../components/Route/Hero/Intro';
import HorizontalAds from './HorizontalAds';




const HomePage = () => {
  return (
    <div>
        <Header activeHeading={1} />
        <Slider />
        <SliderMobile /> 

        <EarningSection />             

        <Hero />
        <TrustedBy />
        <Intro />
        <HorizontalCardNew />
        <Hero3 />
        <CompaniesScroll />

        <HorizontalAds />  

        <HorizontalScroll />
        <CounterUp />
        <HorizontalScroll2 />
        
        <AnimHeroSection2 />
        <HorizontalCardNew2 />
        <AnimHeroSection3 />
        <SupportedHandles />
        <Footer />
    </div>
  )
}

export default HomePage

// https://res.cloudinary.com/totlesoft/image/upload/v1707729978/bagA3_ayq2lp.png
// https://res.cloudinary.com/totlesoft/image/upload/v1707729977/bagA_hgbhr1.png
// https://res.cloudinary.com/totlesoft/image/upload/v1707729977/bagA4_koeiyk.png
// https://res.cloudinary.com/totlesoft/image/upload/v1707729977/bagA2_sxs56i.png