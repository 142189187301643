import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { Country } from "country-state-city";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { freelanceCategoriesData } from "../../../static/data";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";
import Spinner from "../../../components/Spinner/Spinner";

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const Hero = () => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  
  const { user } = useSelector((state) => state.user);
  const [popUp1Open, setPopUp1Open] = useState(false);
  const [specialization, setSpecialization] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    if (!user) {
     return; // Wait for user data to be available
    }
  
    const userHasSpecialization = user?.specialization;
    const userHasCountry = user?.country;
  
    const isSpecializationValid = userHasSpecialization?.trim() !== "";
    const isCountryValid = userHasCountry?.trim() !== "";
  
    // Only open popup if both specialization and country are valid
    if (user && (!userHasSpecialization || !userHasCountry) && !isSpecializationValid || !isCountryValid) {
  setPopUp1Open(true);
} else {
      setPopUp1Open(false);
    }
  }, [user]);



  const specializationHandler = async () => {
    const userId = user?._id;
    setIsLoading(true);
  
    await axios.put(
      `${server}/user/specialization/${userId}`,
      {
        specialization: specialization,
        country: country,
      },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Update Successful!");
      setPopUp1Open(false);
      setIsLoading(false);
      window.location.reload(); // Refresh the page if needed
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setPopUp1Open(false);
      setIsLoading(false);
    });
  };

        
  return (
    <>
    {isLoading ? <Spinner /> : (
      <div className="mt-5 pb-8">
      <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
        style={{
          backgroundImage:
            "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
        }}
      >
        <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>
          <h1
            className={`text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
          >
            {t('Heading_h')} <br />  <span className="text-[#c89b2a]">{t('Heading_hb')}</span>
          </h1>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p1')}
          </p>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p2')}
          </p>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p3')} 
          </p>
          
          <br />
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          {t('h_p4')}
          <br />
  
          </p>
  
          {user ? (
            <Link to="/tasks" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('hb_View_Tasks')}
                 </span>
            </div>
        </Link>
          ):(
            <Link to="/sign-up" className="inline-block">
              <div className={`${styles.button} mt-5`}>
                   <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                   {t('hb_Join_Now')}
                   </span>
              </div>
          </Link>
          )}
  
          {popUp1Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] bg-[#000] shadow rounded min-h-[40vh] p-3`}>
              <form onSubmit={specializationHandler}>
                      <div>
                      <br />
                      <h4 className="text-[20px] font-[400] text-[#fff]">Welcome {user?.name}!</h4>
                      <h4 className="text-[16px] text-[#fff]">Kindly update your country and area of specialization to enable you make withdrawal and access right tasks.</h4>
                      <br />
  
                      <div>
                      <label
                  htmlFor="specialization"
                  className="block text-sm font-medium text-[#fff]"
                >
                  Country: <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                      <select
                        name=""
                        id=""
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="w-[95%] border h-[40px] rounded-[5px]"
                      >
                        <option value="" className="block border pb-2">
                         -
                        </option>
                        {Country &&
                          Country.getAllCountries().map((item) => (
                            <option
                              className="block pb-2"
                              key={item.isoCode}
                              value={item.isoCode}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
               </div>
              <br />
  
            <div>
            <label
                  htmlFor="specialization"
                  className="block text-sm font-medium text-[#fff]"
                >
                  Skill Strength: <span className="text-red-500">*</span>
                </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={specialization}
              onChange={(e) => setSpecialization(e.target.value)}
            >
              <option value="Select">-</option>
              {freelanceCategoriesData &&
                freelanceCategoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
          <br />
  
         
                       <button
                          className={`${styles.button} !bg-[#c89b2a] !w-[120px] !text-[14px] text-white`}
                          type="submit"
                        >
                          Update
                        </button>
                      </div>
                      <button
                          className={`${styles.button} !bg-[#c89b2a] !w-[120px] !text-[14px] text-white mt-5`}
                          onClick={() => setPopUp1Open(false)}
                         >
                         Cancle
                        </button>
                      </form>
            </div>
            
          </div>
        )}
             
        </div>
       </div>
       </div>
    )}
    </>
  );
};

export default Hero;
