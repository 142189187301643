import React, { useState } from 'react'
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import styles from "../../styles/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TestClip from "../../Assests/Audio/testclip.mp3"


const AudioTranscriptionTestPage = () => {
   
  return (
    <div>
        <Header activeHeading={1} />
        <AudioTranscriptionTests />
        
        <Footer />
    </div>
  )
}

const AudioTranscriptionTests = () => {
    const { user } = useSelector((state) => state.user);
    const [active, setActive] = useState(1);

    const testMessage = () => {
        toast.error("Only Workers can access this!")
    }


    return (
        <>
    <div className="mt-1 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>

      
        <h1 className={`text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}>
          Transcription<span className="text-[#c89b2a]">Tests</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Find all the instruction and guide needed to begin your Audio Transcription journey on Totlesoft.
        </p>
        <br />

        <div className="w-full flex justify-between pt-2 pb-2">
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(1)}
            >
              Overview
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(2)}
            >
              Guideline
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(3)}
            >
              Example 
            </h5>
            {active === 3 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
        

        <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(4)}
            >
              Practice 
            </h5>
            {active === 4 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>

          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(5)}
            >
              Test 
            </h5>
            {active === 5 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
        </div>
        
        {active === 1 ? (
        <div className="w-full bg-white px-4 py-2">
            <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
            Totlesoft offers a great opportunity for transcribers to augment their income by transcribing audio/video files. The work involves listening to the audio/video files and typing it as accurately as possible. You just need to go through a test process and get certified as a transcriber. Once certified you will be paid for the files you transcribe on an audio.video hour basis. For each submission, your earning balance will be credited with the appropriate dollar amount. Your earnings will accumulate and you can withdraw it anytime. 
            </p>
            <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
            If you are an experienced transcriber and have spare time, then Totlesoft can help you to fill that up. If you are starting your career as a transcriptionist then you can gain valuable experience and hone your skills on the Totlesoft Freelance Platform.
            </p>
            <br />

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">NOTE:</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* This is an Home-based job, you work at your convenience</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Hour pay rate, $4 to $24/audio</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Short files, 10 minutes or less</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* 2 hours TAT for each file</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Utilize our Free automated transcript, to save around 65% of typing effort</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* You will be promoted for good performance; (grow from a Reviewer - Proofreader - Quality Checker)</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* You will get $5 Monthly Bonus for every 5 hours completed</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* No obligations and no monthly commitments</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Totlesoft don't assign files, you get to preview and choose the files to work on</p>
            <br />

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">REQUIREMENTS:</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Must have a good comprehension of English Language</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Ability to interpret conversations</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Ability to apply context and identify mistakes</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Laptop/desktop computer and internet connection</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Headphone/headset</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Latest version of Firefox, Chrome or Safari web browser</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Ability to correct manual/automated transcripts.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Passion to work</p>
            <br />

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">TRANSCRIPTION PROCESS:</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">File Splitting - Raw Transcription - Review - Proofreading - Quality Check</p>
            <br />
            <p className="text-[16px] font-[Poppins] text-[#000000ba]"><span className='font-[600]'>File Splitting:</span> Firstly, the audio file is split at 6 minute intervals.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]"><span className='font-[600]'>Raw Transcription:</span> Secondly,  the audio for each part file is played back and transcribed as accurately and cleanly as possible. The incomprehensible parts are marked with blanks. We provide a free automated transcript which you can correct and submit as per our guidelines.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]"><span className='font-[600]'>Review:</span> Thirdly, the Raw Transcript is checked for correctness and quality. The audio file is played back again and each transcribed word is checked against the audio. The blanks are filled, if possible. Timestamps and speaker tracking are also added at this stage.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]"><span className='font-[600]'>Proofreading: </span> Fourtly, all part files are collated together and the combined transcript is checked against the audio once more.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]"><span className='font-[600]'>Quality Check: </span> Lastly, the transcript is quality checked and either delivered to the customer or re-proofread.</p>
            <br />

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">AUTOMATED TRANSCRIPT:</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">Totlesoft also provide an automated transcript with each file. The automated transcript is machine generated and the accuracy varies from 60% to 90% depending upon the audio quality, accents, and other things. Therefore it has to be corrected before submitting. However, this can still save around 50% of the typing effort. The following has to be corrected:</p>
            <br />
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Missing words</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Mistakes</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Utterances for strict verbatim files</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Missing or incorrect paragraph breaks</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Incorrect capitalization of words</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* and finaly, missing punctuations, especially "</p><br />
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* You will find the Auto Transcribe button in the editor, and by clicking the button it will insert the automated transcript at the current cursor position. This feature is provided free of charge by Totlesoft so as to improve your efficiency and productivity.</p>

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">PERFORMANCE TRACKING:</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">
            Totlesoft employ a grading system to track performance. The grades range from A+ (5/Excellent) to D (1/Poor) and are based on major mistakes found in the submissions. The average of the grades of the last 100 submissions, called the Overall Grade, is the metric used to track performance. Reviews will be enabled after 1 audio hour of raw transcript submissions have been credited and your Overall Grade is above 3. Similarly, Proofreading will be enabled after 5 audio hour of Review submissions have been credited and your Overall Grade is above 3.5. QC will be enabled after 10 audio hour of Proofreading submissions have been credited and your Overall Grade is above 4.
            </p><br />
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">
            Also note that a minimum Overall Grade of 2.5 has to be maintained for you to keep your access to transcription jobs on Totlesoft. You will not be able to work on any files if the OG falls below that mark. However, your account will remain active and you can still complete other tasks and keep getting Referral and Affiliate commissions, if any. 
            </p><br />
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">
            Totlesoft also provide a dispute resolution mechanism for incorrectly graded and/or reviewed files. The disputes are completely anonymous and are arbitrated by our administrators. Our final decision is binding.
            </p>
            <br />
            <br />
            

        </div>
        ) : null}
  
        {active === 2 ? (
        <div className="w-full bg-white px-4 py-2">
        <p className="pt-5 text-[16px] font-[600] font-[Poppins] text-[#000000ba]">
        The Totlesoft transcription guideline is a set of rules that should be always followed. Therefore please adhere to these as strictly as possible. 
        </p>
        <br />
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">I. The raw transcript should not contain text other than the spoken audio. No headers/footers, speaker tracking, time-codes, comments etcetera.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">II. No part of the audio should be omitted, unless specified otherwise by an instruction or another guideline.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">III. Inaudible parts should be omitted and marked with a blank ____.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">IV. Laughter should be omitted and marked with [laughter] or [chuckle].</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">V. Ellipsis ... should be used to indicate unfinished sentences or mid-sentence pauses.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">VI. Contractions, wanna, gonna, kinda, gotta, should not be expanded.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">VII. Numbers one to nine should be transcribed in letters and numerals otherwise, except for measurements.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">VIII. A new paragraph should be started at each change of speaker and paragraphs should be separated by an empty line.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">IX. Fillers (right, you know, I think, like, I mean, so, actually, basically), false starts, stutters and partial words should be omitted, except for strict verbatim files.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">X. Utterances should be omitted for non-strict verbatim files, unless necessary. For strict verbatim files, only the following utterances should be transcribed: Mm-hmm if in agreement, Uh-huh if in disagreement, Umm and Uh.</p>
        <br />

        <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">ADDITIONAL NOTES:</h5>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Strict verbatim files are indicated with an instruction. Files without the instruction should be considered as non-strict verbatim.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Blanks that are filled during the Review, Proofreading and QC steps are considered as major mistakes (except for names and acronyms).</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* For Guideline VII, measurements are considered as anything which has a unit (kg, ml, currency units, etcetera).</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* For Guideline IX, the filler words specified have to be frequently repeated by a speaker during the conversation to be considered a filler. In-frequent usage of those words should be transcribed.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* False starts are when the speaker starts a statement but immediately corrects it to something else.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* For Totlesoft Guideline IX, all files are non-strict verbatim by default. Strict verbatim files are indicated by an instruction on the file. Therefore if the instruction is not present, all utterances, fillers, false starts, stutters should be omitted. If the instruction is present, nothing should be omitted and the raw transcript should match the audio word for word.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* The only case when an utterance is required in a non-strict verbatim file is when it is a response to a direct question. The unless necessary part of Guideline X specifies that.</p>
        <br />

        <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">STYLE SUGGESTIONS:</h5>
        <p className="text-[16px] font-[600] font-[Poppins] text-[#000000ba]">The following are some styles that we recommend. Please note that this list is not exhaustive. If you require additional guidance, please contact the Totlesoft Support.</p>
        <br />
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Punctuations are free style. Please use your discretion on placement of commas, periods, semicolons, exclamations etc.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Spelling styles are different for each file and the styles suggested by the spell check should be followed.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* All proper nouns should be first letter capitalized, e.g., names of places, person, organizations, job titles, etcetera.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Spelled out names should be all capitalized and separated by hyphens. E.g. A-D-A-M.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Use etcetera instead of etc.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Use i.e., instead of ie.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Use e.g., instead of Eg.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Alright and All right are both acceptable.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* A statement can begin with an And.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Generic drug names should be all lowercase and branded drug names should be first letter capitalized. Google once to check if they are generic or branded.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Special marking schemes for phonetics and spelling should not be used. Blanks should be used instead.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Periods should not be used in acronyms. E.g. PhD instead of Ph.D.</p><br />
        <p className="text-[16px] font-[600] font-[Poppins] text-[#000000ba]">* The following examples further illustrates Transcription Guideline VII:</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Time: 10:00 AM</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Date: March 2, 2012</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Temperature: 5 degrees</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Money: $5, $500,000, $5 Billion</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Percentage: 5%</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Speed: 5 miles per hour</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Dimension: 6 x 10, 5 feet 2 inches</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Address: 213, 2nd Street</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Era: in your 20s, in the 90s</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">-Rank: ninth, 10th</p>
        
        <br />
        <p className="text-[16px] font-[600] font-[Poppins] text-[#000000ba]">The Totlesoft Transcription Guideline is been updated periodically, so it is wise that you check here periodically to stay on track.</p>

        <br />
        <br />
        

    </div>
        ) : null}

        {active === 3 ? (
        <div className="w-full bg-white">
        <div className="w-full bg-white px-4 py-2">
        
        <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">SAMPLE TRANSCRIPT:</h5>
        <p className="text-[16px] font-[Poppins] font-[600] text-[#000000ba]">The following is a sample transcript which illustrates the formatting required for raw transcripts. Please note the following:</p>
        <br />
        <h5 className="text-[20px] font-[600] mb-2">NOTE:</h5>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* There is no speaker tracking, eg. interviewer/interviewee, person 1/person 2 etc. There are also no time codes. Both of these are not required for raw transcript.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* The transcript is broken into paragraphs on each change of speaker. Each speakers continuous diction is on one single paragraph and paragraphs are separated by empty lines.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Inaudible parts are marked with a blank, ____ to make it stand out from the rest of the transcript. These blanks may or may not get filled during review, but are always checked.</p>       
        
        <br />

            <h5 className="text-[20px] font-[600] mb-2">AUDIO:</h5>
            <audio controls src={TestClip} type="audio/mp3" />
            <br />
            <br />


            <h5 className="text-[20px] font-[600] mb-2">TRANSCRIPTION:</h5>
            <h5 className="text-[15px] text-[#000000ba] h-[50vh] w-full px-4 py-4 overflow-x-hidden overflow-y-auto">
Hello.<br /><br />

Hello.<br /><br />

Yes. Rajiv can you hear me?<br /><br />

Yeah, I can hear you.<br /><br />

Hi. This is Austin Taun from Taipei, Taiwan.<br /><br />

Hi Austin. How are you?<br /><br />

I'm fine. Okay... So today, Rajiv could you first introduce yourself a little bit?<br /><br />

Sure. Well I'm a Telecom ____ here. I graduated sometime in 1999. And after that I worked for some various companies out here. My last company that I worked for was Lucent. And then after that I started off on my own. And for the past two years I've been trying to do this Sedna Wireless thing. As part of Sedna Wireless we released Call Graph.<br /><br />

Okay. The reason that I know your company, Sedna Wireless is because this product Call Graph, yeah, I think that's, if I think correctly it's kind of like Skype recorder. It's like a plugin for Skype, right.<br /><br />

It is a call recording application for Skype. It works in the background and it records all your calls into an mp3 file. And then you can play it back, you can search through your call records. It's basically meant for... It's a productivity application. Somebody who would like to have records of his calls, he can use our software.<br /><br />

Yes. And the reason that I found it interesting is that, I'm not sure if you aware of this fact, your software Call Graph seems to be the only one that can be used for free! And with the functionality of Stereo. What I mean is that your software Call Graph can record the conversation into separate audio tracks. So that will be perfect choice for Podcasts. Because most of the ___ come into the situation that the two people have different volumes. So it will be very very hard to do the modification, to do the post production.<br /><br />

Right. That's true. We record it in separate tracks which makes it very easy to edit the files. You can use any external audio editor. For example, Audacity is a very good audio editor. And you can export the tracks separately. Plus there is also a feature in which you can record the calls in a wav file. So if you do record the calls in a wav file then you don't lose any quality. The quality is maintained, of the call.<br /><br />

Yes. So I think, frankly there are other software in the market that provide the same functionality of separate tracks. But they all require, a little bit registration fee. So it's a very very outstanding point, from my point of view.<br /><br />

Yes. Great that you like Call Graph.<br /><br />

So I think you should emphasize this point. I actually spent several days to search through the Internet about Skype recording solutions. And I was very surprised that Call Graph was not mentioned very much. It only has 50,000 Google hits, compared to other software, maybe several million hits.<br /><br />

Well one of the reason is that we released it recently, it's around just two months old. And it got covered by a few popular weblogs, few popular Blogs. For example LifeHacker covered us. And DownloadSquad covered us. But I guess it takes some time to spread through the Google's network.<br /><br />
            </h5>
                          
              
              
              <br />
              <br />
        </div>
        </div>
        ) : null}

        {active === 4 ? (
        <div className="w-full bg-white px-6 py-6">
            <h1 className="text-[25px] font-[600]">Practice</h1>
            <br />
            
            <img
            src="https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"
            alt="Totlesoft"
           className="object-cover w-[350px] h-[350px]"
          />

        <br />
        <p>No practice script provide yet, kindly revert to the example section.</p>
        </div>
        ) : null}

        {active === 5 ? (
        <div className="w-full bg-white px-4 py-2">
        
        <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">INSTRUCTIONS:</h5>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Click on the play button to preview the audio quality, accents etcetera and select the one you think is the easiest.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Once selected, the raw transcript has to be submitted within 2 hours.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Spell check will be started automatically on submit and has to be completed in order to finish.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* The maximum number of attempts is 5 and cannot be increased. Selecting a file is considered an attempt. Therefore please select carefully.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* You can also wait for new files to be added for more choices of test files.</p>
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">* If you have test-related questions, please read through the FAQ for all you need to know about test files.</p>
        <p className="text-[16px] font-[Poppins] font-[600] text-[#000000ba]">NOTE: Do not violate the transcription guidelines rules</p>
        
        <br />

            <h5 className="text-[20px] font-[600] mb-2">AVAILABLE TESTS:</h5>
            <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 1</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer"><Link to="#">Start</Link></h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>
              
              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 2</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer"><Link to="#">Start</Link></h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>
             
              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 3</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer"><Link to="#">Start</Link></h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>
              
              <br />
              <br />
        </div>
        ) : null}

       <br />
       

                     
        
        </div>
        </div>
        </div>
        </>
    )
}

export default AudioTranscriptionTestPage
