import React, { useState, useEffect } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
  AiOutlineMoneyCollect,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { MdEmail, MdOutlineGppBad, MdOutlinePhonelinkRing } from "react-icons/md";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { BiIdCard } from "react-icons/bi";
import { TbAddressBook } from "react-icons/tb";

import { FaUserFriends, FaCopy } from "react-icons/fa";
import { GiThreeFriends } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import styles from "../../styles/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdTrackChanges, MdOutlineFolderCopy } from "react-icons/md";
import { RxCross1, RxPerson } from "react-icons/rx";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { getAllUsedCodesOfUser } from "../../redux/actions/usedcode";
import { getAllNewUCodesOfUser } from "../../redux/actions/newcode";
import { getAllUsersOpen } from "../../redux/actions/user";
import { getAllArticlesUser } from "../../redux/actions/article";

import Spinner from "../Spinner/Spinner";
import HistoryInfo from "../Profile/HistoryInfo";
import EhistoryInfo from "../Profile/EhistoryInfo";
import worker from "../../Assests/images/worker.gif";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import TalentCard from "../../components/Route/TalentCard/TalentCard";

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const ProfileContent = ({ active }) => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

   
  const { user, users, error, successMessage } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [userIsNotActivated, setUserIsNotActivated] = useState(true);
  const [openMyGigs, setOpenMyGigs] = useState(false);
  const [talentData, setTalentData] = useState([]);

  const [isIDVerified, setIsIDVerified] = useState(false);
         
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usedcodes } = useSelector((state) => state.usedcode);
  const { newcodes } = useSelector((state) => state.newcode);

  const detectCodeId = newcodes && newcodes.find((ittm) => ittm.code === code);
  console.log(detectCodeId);
  console.log("above is the item");

  const gigCreator = user?._id;

  const thecodeCreatorId = detectCodeId && detectCodeId.codeCreatorId;
  
  const userRefferer = user?.referrer; 

  const isEmailVerified = true;
  const isPhoneVerified = false;
  const isAddressVerified = false;
     
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    dispatch(getAllUsedCodesOfUser());
    dispatch(getAllNewUCodesOfUser());
    dispatch(getAllUsersOpen());
    
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  
  useEffect(() => {
    if (user && user?.role === "Worker" || user && user?.role === "codeVendor" || user && user?.role === "Admin"){
      setUserIsNotActivated(false);
    }
  },[]);

  useEffect(() => {
    if (!user) return; // Wait for user data to be available
    
    const idisv = user?.idNumber;
    const idnisv = user?.idType;
  
    // If both idNumber and idType are valid, set ID verification to true
    if (idisv && idnisv && idisv.trim() !== "" && idnisv.trim() !== "") {
      setIsIDVerified(true);
    } else {
      setIsIDVerified(false);
    }
  }, [user]); 

  useEffect(() => {
   axios
      .get(`${server}/talent/get-all-talents`, {
        withCredentials: true,
      })
      .then((res) => {
        setTalentData(res.data.talents);
        })
      .catch((error) => {
        console.log(error.response.data.message);
        });
  }, []);



  const refToCredit = users && users.find((i) => i.username === userRefferer);

  const myGigs = talentData && talentData.filter((iit) => iit.userId === gigCreator)
  

  console.log(myGigs);
  console.log("above is my gigs");

  
  const codeInFocus = code; 
  
  const codeisInUsedFolder = usedcodes && usedcodes.find((i) => i.code === codeInFocus);

  const codeisInNewFolder = newcodes && newcodes.find((itm) => itm.code === codeInFocus);
     
   

  const accountActivationHandler = async () => {

    if (codeisInUsedFolder){
      return toast.error("Code already used!");
    } else if (codeisInNewFolder) {
      setIsLoading(true);

    const userId = user?._id;

    const newRole = "Worker";

    await axios

      .put(
        `${server}/user/upgrade-user/${userId}`,
        {
          role: newRole,
          activationCode: code,
        },
        { withCredentials: true }
      )
      .then((res) => {
          setIsLoading(false);
          toast.success("Activation Successful!");
          usedCodeCreatorHandler();
          refBonusCreditingHandler();
          codeVendorCreditingHandler();
          navigate('/profile');
          window.location.reload();
          })
       .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });
    }
    else {
      return toast.error("Fake Code!");
    }
}

const usedCodeCreatorHandler = async () => {

  const codeToUse = code;
  const codeUser = user?.username;

  await axios

    .post(
      `${server}/usedcode/sign-usedcode`,
      {
        code: codeToUse,
        codeUser: codeUser,
      },
      { withCredentials: true }
    )
    .catch((error) => {
      toast.error("usedcode posting failed");
    });
}


const refBonusCreditingHandler = async () => {
  const codePrice = 5;
  const refBonusToBeCredited = codePrice * 30 / 100;
  const refToBeCreditedId = refToCredit?._id;

  await axios
  
        .put(
          `${server}/user/ref/bonus/${refToBeCreditedId}`,
          {
            refBonusToBeCredited: refBonusToBeCredited,
          },
          { withCredentials: true }
        )
        .catch((error) => {
          toast.error(error.response.data.message);
        });    
  }


    const codeVendorCreditingHandler = async () => {

      const thecodePrice = 5;
      const codevBonusToBeCredited = thecodePrice * 20 / 100;
      const codevToBeCreditedId = thecodeCreatorId;
    
      await axios
      
            .put(
              `${server}/user/codev/earning/${codevToBeCreditedId}`,
              {
                codevBonusToBeCredited: codevBonusToBeCredited,
              },
              { withCredentials: true }
            )
            .catch((error) => {
              toast.error(error.response.data.message);
            });
      }

  const refLink = user?.refLink;

  const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
  };

  const tax = false;

  return (
    <>
    {isLoading ? 
    <Spinner /> : (
      <div className="w-full px-4">
      {/* profile */}
      {active === 1 && (
        <>
        {user && user?.role === "Admin" && (
            <div className="flex font-[500] text-[25px] text-[#000]">
            <RxPerson size={25} color="#000" />
              <span className="pl-3">{t('Admin')}</span>
              </div>
            )}
          
          {user && user?.role === "Worker" && (
            <div className="font-[500] text-[25px] text-[#000]">
            <img
            src={worker}
            width='80px'
            alt=""
            className="ml-2 mr-2"
          />
              <span>{t('Worker')}</span>
              </div>
            )}

          {user && user?.role === "codeVendor" && (
            <div className="font-[500] text-[25px] text-[#000]">
            <RxPerson size={20} color="#000" />
              <span>{t('Code_Vendor')}</span>
              </div>
            )}

            <br />

          <div className="flex w-full 800px:w-[300px] justify-between">
           <div className="mr-3 block items-center justify-center">
            <div className="flex">
              {isEmailVerified === true ? <IoShieldCheckmarkSharp size={20} color="green" /> : <MdOutlineGppBad size={20} color="red" />} {isEmailVerified === true ? <span className="text-[green] text-[12px] font-[500]">email</span> : <span className="text-[red] text-[12px] font-[500]">email</span>}
              </div>
            </div>

            <div className="mr-3 block items-center justify-center">
            <div className="flex">
              {isPhoneVerified === true ? <IoShieldCheckmarkSharp size={20} color="green" /> : <MdOutlineGppBad size={20} color="red" />} {isPhoneVerified === true ? <span className="text-[green] text-[12px] font-[500]">phone</span> : <span className="text-[red] text-[12px] font-[500]">phone</span>}
              </div>
            </div>

            <div className="mr-3 block items-center justify-center">
            <div className="flex">
              {isIDVerified === true ? <IoShieldCheckmarkSharp size={20} color="green" /> : <MdOutlineGppBad size={20} color="red" />} {isIDVerified === true ? <span className="text-[green] text-[12px] font-[500]">ID</span> : <span className="text-[red] text-[12px] font-[500]">ID</span>}
              </div>
            </div>

            <div className="mr-3 block items-center justify-center">
            <div className="flex">
              {isAddressVerified === true ? <IoShieldCheckmarkSharp size={20} color="green" /> : <MdOutlineGppBad size={20} color="red" />} {isAddressVerified === true ? <span className="text-[green] text-[12px] font-[500]">Address</span> : <span className="text-[red] text-[12px] font-[500]">Address</span>}
              </div>
            </div>

          </div>
         
          {user?.taxInfo.status === "Completed" && (
                <div className="bg-[green] text-[#fff] w-full 800px:w-[300px] mt-6 px-2 py-2 rounded-xl">
                <p className="text-[16px] font-[400] mb-2">{t('Tax_Records')} <span className="text-sm bg-[#fff] text-[green] w-[100px] px-2 py-1 rounded-xl">{t('Tax_Completed')}</span></p>
                <h5>Great! Tax Record is completed.</h5>
                    <Link to="/tax-info" target="_blank" className="inline-block">
                        <div className={`${styles.buttonGold} mt-5`}>
                             <span className="text-[#fff] font-[Poppins] !w-[100px] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                             {t('Update')}
                             </span>
                        </div>
                    </Link>
                    <br />
                </div>
              )}

              {user?.taxInfo.status === "Not Completed" && (
                <div className="bg-[red] text-[#fff] w-full 800px:w-[300px] mt-6 px-2 py-2 rounded-xl">
                <p className="text-[16px] font-[400] mb-2">{t('Tax_Records')} <span className="text-sm bg-[#fff] text-[red] w-[100px] px-2 py-1 rounded-xl">{t('Tax_Incomplete')}</span></p>
                <h5>Update to avoid payment delay</h5>
                    <Link to="/tax-info" target="_blank" className="inline-block">
                        <div className={`${styles.buttonGold} mt-5`}>
                             <span className="text-[#fff] font-[Poppins] !w-[100px] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                             {t('Update')}
                             </span>
                        </div>
                    </Link>
                    <br />
                </div>
              )}
            
            <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[300px] mt-6 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Country</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl">{user?.country}</h5>
              </div>

              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Username</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] max-h-[32px] w-[100px] px-2 py-1 rounded-xl overflow-x-hidden overflow-y-auto">{user?.username}</h5>
              </div>

              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Ref Link</h5>
              <span  onClick={writeTextToClipboard} className="bg-[#c89b2a] text-[16px] text-[#fff] px-2 py-1 rounded-xl cursor-pointer">copy</span>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] max-h-[32px] w-[100px] px-2 py-1 rounded-xl overflow-x-hidden overflow-y-auto">{refLink}</h5>
              </div>

              {user && user?.staffRole === "noneStaff" ? null : (
                <div className="flex justify-between mb-2">
                <h5 className="text-[16px] font-[400] mb-2">Staff Role</h5>
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl">{user?.staffRole}</h5>
                </div>
              )}

              {user && user?.specialization === undefined ? null : (
                <div className="flex justify-between mb-2">
                <h5 className="text-[16px] text-[#c89b2a] font-[400] mb-2">{user?.specialization}</h5>
                </div>
              )}
              </div>
                <br />

           <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[300px] px-2 py-2 rounded-xl">
             <div className="flex justify-between mb-2">
                <h5 className="text-[16px] font-[400] mb-2">Create Gig</h5>
                <Link to="/profile/create-gig"><h5 className="bg-[#c89b2a] text-[16px] text-[#fff] px-2 py-1 rounded-xl cursor-pointer">Create</h5></Link>
                </div>

                <div className="flex justify-between mb-2">
                <h5 className="text-[16px] font-[400] mb-2">My Gigs</h5>
                <h5 className="bg-[teal] text-[16px] text-[#fff] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenMyGigs(true)}>View Gigs</h5>
                </div>
              </div>
              <br />

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[300px] px-2 py-2 rounded-xl">
                <div className="flex justify-between mb-2">
                <h5 className="text-[16px] font-[400] mb-2">Take a test</h5>
                <Link to="/tests"><h5 className="bg-[#c89b2a] text-[16px] text-[#fff] px-2 py-1 rounded-xl cursor-pointer">Tests</h5></Link>
                </div>
              </div>            

                <br />

          {openMyGigs &&  (
          
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div className={`w-[90%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}>
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenMyGigs(false)}
                className="cursor-pointer"
              />
            </div>

        <div className={`${styles.section}`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {myGigs && myGigs.map((i, index) => <TalentCard data={i} key={index} />)}
        </div>
        {myGigs && myGigs.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
           You haven't created any gig yet!
          </h1>
        ) : null}
      </div>          
  
            
          <div>
          <br />
          </div>       
         </div>
         </div>
        )}

          <div className="w-full px-5">

             {userIsNotActivated ? (
              <>
              <br />
              <div>
              <h2 className="text-[25px] text-[600]">{t('Upgrade_to_Worker')}</h2>
              <h5 className="text-[16px] text-[600]"> {t('Get_Access')}</h5>
              <br />
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Enter_Activation_Code')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              </div>
              <button 
              className={`${styles.button} text-[#fff]`}
              onClick={accountActivationHandler}
              >
                {t('Activate')}
              </button><br />
              <h5 className="text-[16px]">{t('Dont_Have_Code')}</h5>

             <Link to="#">
              <button className={`${styles.buttonGold} mb-5 text-[#fff]`}>
                {t('Buy_Code')}
              </button>
              </Link>
              </div>
              <br />
              <hr />
              <br />
              </>) : null}          
           
              
            <form>
            <h2 className="text-[25px] font-[600]">{t('Spotlight')}</h2>
            <h5 className="text-[18px] text-[blue]">Sell your talent: Summarise your experience and skill professionally.</h5>
            <br />
            <h4 className="bg-[#fff] y-2 text-[18px] leading-8 px-8 py-8 whitespace-pre-line">
          {user?.spotLight}
          </h4>
        
        <br />
        <br />

              <h2 className="text-[25px] font-[600]">{t('Personal_Info')}</h2><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('UWID')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?._id}</h5>
                </div>
              </div>

              {user && user.googleSignup === true ? (
                <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">PIN:</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.pin}</h5>
                </div>
              </div>
              ) : null}
              
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Name')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.name}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Email_Address')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.email}</h5>
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Gender')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.gender}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Marital_Status')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.maritalStatus}</h5>
                </div>
              </div>
              

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Language')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.language}</h5>
                </div>
                
              </div>
              
              
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Mobile')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.phoneNumber}</h5>
                </div>
               </div>
                  

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Number_of_Children')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.numbersOfChildren}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Highest_Level_of_Education')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.educationLevel}</h5>
                </div>
              </div>
              <br />


          <h2 className="text-[25px] font-[600]">{t('About_Me')}</h2><br />
          <h4 className="bg-[#fff] y-2 text-[18px] leading-8 px-8 py-8 whitespace-pre-line">
          {user?.aboutMe}
          </h4>
        
        <br />
        <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Portfolio_Link')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.potfolioURL}</h5>
                </div>
              </div>
              <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('GitHub_Profile_Link')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.githubURL}</h5>
                </div>
              </div>
              <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Linkedin_Profile_Link')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user?.linkedinURL}</h5>
                </div>
              </div>
              <br />


              <h2 className="text-[25px] font-[600]">{t('Social_Media_Info')}</h2><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Facebook_Username')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.facebookUserName}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Instagram_Username')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.instagramUserName}</h5>
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Twitter_Username')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.twitterUserName}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Tiktok_Username')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.tiktokUserName}</h5>
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Youtube_Username')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.youtubeUserName}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Telegram_Username')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.telegramUserName}</h5>
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Trustpilot Username</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.trustpilotUserName}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Google Username</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.googleUserName}</h5>
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Boomplay Username</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.boomplayUserName}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Spotify Username</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.spotifyUserName}</h5>
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Soundcloud Username</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.soundcloudUserName}</h5>
                </div>
              </div>
              <br />

              <h2 className="text-[25px] font-[600]">{t('Tester_Info')}</h2><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.devices}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device_Operating_Sytem')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.operatingSystem}</h5>
                </div>
              </div>
              <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device_Brand')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.deviceBrand}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device_RAM_Size')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.deviceRAMSize}</h5>
                </div>
              </div>
              <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Smartphone_Brand')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.smartphoneBrand}</h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Smartphone_Operating_System')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.smartphoneOperatingSystem}</h5>
                </div>
              </div>
              <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Smartphone_Operating_System_Version')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] bg-[#fff]`}>{user.smartphoneOperatingSystemVersion}</h5>
                </div>
               
              </div>
              <br />

            </form>
            <br />
            <br />
          </div>
        </>
      )}

      {/* order */}
      {active === 2 && (
       <div>
        <br />
        <br />
        <AllOrders />
        </div>
      )}

       {/* tasks */}
       {active === 3 && (
        <div>
          <br />
          <br />
          <AllTasks />
        </div>
      )}

      {/* Projects */}
      {active === 4 && (
        <div>
          <br />
          <br />
          <Projects />
        </div>
      )}

      {/* Track order */}
      {active === 6 && (
        <div>
          <br />
          <br />
          <TrackOrder />
        </div>
      )}

      {/* Change Password */}
      {active === 7 && (
        <div>
          <br />
          <br />
          <ChangePassword />
        </div>
      )}

      {/* Change Pin */}
      {active === 70 && (
        <div>
          <br />
          <br />
          <ChangePin />
        </div>
      )}

      {/*  user Address */}
      {active === 8 && (
        <div>
          <br />
          <br />
          <Address />
        </div>
      )}

      {/*  user Earnings */}
      {active === 9 && (
        <div>
          <br />
          <br />
          <Earnings />
        </div>
      )}

      {/*  APLinks */}
      {active === 12 && (
        <div>
          <br />
          <br />
          <APLinks />
        </div>
      )}

      {/*  Sales */}
      {active === 13 && (
        <div>
          <br />
          <br />
          <Sales />
        </div>
      )}

      {/*  Edit Profile */}
      {active === 14 && (
        <div>
          <br />
          <br />
          <EditProfile />
        </div>
      )}

      {/*  Articles */}
      {active === 15 && (
        <div>
          <br />
          <br />
          <Articles />
        </div>
      )}


      {/*  Graphics Jobs */}
      {active === 16 && (
        <div>
          <br />
          <br />
          <DJobs />
        </div>
      )}


      {/*  Investment */}
      {active === 17 && (
        <div>
          <br />
          <br />
          <Investment />
        </div>
      )}

      {/*  Subscriptions */}
      {active === 18 && (
        <div>
          <br />
          <br />
          <Subscription />
        </div>
      )}
      

    </div>
    )}
    </>
    
  );
};


const Earnings = () => {

  const { user } = useSelector((state) => state.user);
  const [isvisible, setIsVisible] = useState(false);
  const [withdrawUserUserData, setWithdrawUserUserData] = useState([]);
  const [transferData, setTransferData] = useState([]);
  const [convertData, setConvertData] = useState([]);
  const [eHistoryData, setEhistoryData] = useState([]);

  const [mycontrolData, setMycontrolData] = useState([]);

  const withUserUserId = user?._id;
  const transferUserId = user?._id;
  const converterUserId = user?._id;

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

    
    useEffect(() => {
        axios
          .get(`${server}/withdrawuser/get-all-withdraw-request-user/${withUserUserId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setWithdrawUserUserData(res.data.withdrawusers);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }, []);

      useEffect(() => {
        axios
          .get(`${server}/transfer/get-all-transfer-user/${transferUserId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setTransferData(res.data.transfers);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }, []);

      useEffect(() => {
        axios
          .get(`${server}/convert/get-all-convert-user/${converterUserId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setConvertData(res.data.converts);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }, []);

      useEffect(() => {
        const dataforEarnH = user && user.earningHistories;
        setEhistoryData(dataforEarnH);
      }, []);

      
  const availableBalance = user?.availableBalance.toFixed(4);
  const bonusBalance = user?.bonusBalance.toFixed(4);
  const commissionBalance = user?.commissionBalance.toFixed(4);
  const totalReferrers = user?.totalReferrers;
  const refLink = user?.refLink;


  const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
  };

  const mcDataToUse = mycontrolData && mycontrolData.find((iiti) => iiti.controlPin === "114455chris");
  console.log(mcDataToUse);

  
  
  return (
    <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>

    <div className="w-full block 800px:flex items-center justify-between px-5">
      <div className="w-full mb-4 800px:w-[22%] h-[40vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              {t('Earnings_Balance')}{" "}
              <span className="text-[16px]">({t('w5sc')})</span>
            </h3>
          </div>
          <h5 className="text-center pt-2 pl-[36px] text-[22px] font-[500]">${availableBalance}</h5>
          {!mcDataToUse || mcDataToUse.isPaymentDay === "false" ? (
            <>
            <h5 className="text-center pt-4 pl-[2] text-[#000]">{t('Withdraw_Money')}</h5>
            <h5 className="text-center pt-4 pl-[2] text-[#077f9c]">Open on 28th</h5>
            </>
          ) : (
            <Link to="/dashboard-withdraw-money-user">
            <h5 className="text-center pt-4 pl-[2] text-[#077f9c]">{t('Withdraw_Money')}</h5>
          </Link>
            
          )}
          
        </div>

        {user && user.role === "Worker" ? (
          <div className="w-full mb-4 800px:w-[22%] h-[40vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <GiThreeFriends
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              {t('Commission_Points')}
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[22px] font-[500]">{commissionBalance}</h5>
          <Link to="/dashboard-convert-point-user">
            <h5 className="text-center pt-4 pl-[2] text-[#077f9c]">{t('Convert')}</h5>
          </Link>
        </div>
        ) : null}

        {user && user.role === "Worker" ? (
          <div className="w-full mb-4 800px:w-[22%] h-[40vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <TbMoneybag
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              Tasks Earnings
              </h3>
          </div>
          <h5 className="text-center pt-2 text-[22px] font-[500]">TST {bonusBalance}</h5>
          <Link to="/dashboard-transfer-money-user">
            <h5 className="text-center pt-4 pl-[2] text-[#077f9c]">P2P</h5>
          </Link>
          <Link to="/dashboard-exchange-bonus-user">
            <h5 className="text-center pt-2 pl-[2] text-[#077f9c]">{t('Exchange')}</h5>
          </Link>
          <Link to="/dashboard-invest">
            <h5 className="text-center pt-4 pl-[2] text-[#077f9c]"></h5>
          </Link>
        </div>
        ) : null}

        
        <div className="w-full mb-4 800px:w-[22%] h-[40vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <FaUserFriends
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              {t('Invite_Friends')}{" "}
              <span className="text-[16px]">({t('r10c')})</span>
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[22px] font-[500]">{totalReferrers}</h5>
          <div onClick={() => setIsVisible(true)} 
          className="text-center items-center justify-center"
          >
            <h5 className="cursor-pointer text-center pt-4 pl-[2] pr-[2] text-[#077f9c]">{t('Generate_Link')}</h5>
            {isvisible ? <h5 className="pt-2 pl-[5px] pr-[5px] text-[12px] font-[600]">{refLink}</h5> : null}
           </div>
           {isvisible ? <h5 onClick={writeTextToClipboard} className="cursor-pointer text-[12px] text-center pl-[2] pr-[2] text-[#077f9c]">{t('Copy')}</h5> : null}
           </div>
        </div>
        <br />

        <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">{t('Transaction_History')}</h1>
        </div>
        

        <div className="w-full">
        <HistoryInfo
            transferData={transferData}
            convertData={convertData}
            withdrawUserUserData={withdrawUserUserData}
        />
        </div>
            
          <br />
          <br />


          <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">{t('Earning_History')}</h1>
        </div>
        

        <div className="w-full">
        <EhistoryInfo
            eHistoryData={eHistoryData}
           />
        </div>
            
          <br />
          <br />

    </div>
  )
}


const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 1 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: "greenColor" 
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.4,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },

    {
      field: "date",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },

    {
      field: " ",
      flex: 0.5,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "$ " + item.totalPrice,
        status: item.status,
        date: item.createdAt.slice(0, 10),
      });
    });

  return (
    <div>
      <h1 className="ml-4 mt-6 mb-4 text-[25px] font-[600]">{t('My_Personal_Orders')}</h1>
      <div className="py-5 px-5 bg-[#fff]">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
    </div>
    
    
  );
};



const APLinks = () => {
  const { user } = useSelector((state) => state.user);
  const [aplinkData, setAplinkData] = useState([]);

   
  const aplCreatorId = user?._id;


  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    axios
      .get(`${server}/aplink/get-all-apls/${aplCreatorId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setAplinkData(res.data.aplinks);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

    const columns = [
    { field: "id", headerName: "Link ID", minWidth: 150, flex: 1 },

    {
      field: "apl",
      headerName: "AP Link",
      minWidth: 130,
      flex: 1.8,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: " ",
      flex: 0.6,
      minWidth: 150,
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
           <Button onClick={() => navigator.clipboard.writeText(params.apl) && toast.success("Link Copied!")}>
                <MdOutlineFolderCopy size={15} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  aplinkData &&
  aplinkData.forEach((item) => {
      row.push({
        id: item._id,
        apl: item.apl,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <div>
       <h1 className="ml-4 mt-6 mb-4 text-[25px] font-[600]">{t('My_Affiliate_Product_Links')}</h1>
       <div className="py-5 px-5 bg-[#fff]">
        <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
    </div>
  );
};


const Sales = () => {
  const { user } = useSelector((state) => state.user);
  const [obaData, setObaData] = useState([]);
  
  const username = user?.username;

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    axios
      .get(`${server}/ordersbyaffiliate/get-all-oba/${username}`, {
        withCredentials: true,
      })
      .then((res) => {
        setObaData(res.data.ordersbyaffiliates);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const columns = [
    { field: "id", headerName: "Sales ID", minWidth: 150, flex: 1 },

    {
      field: "affilator",
      headerName: "Affiliator",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "commision",
      headerName: "Commision",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "buyerId",
      headerName: "Purchased By",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "soldAt",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
  ];

  const row = [];

  obaData &&
  obaData.forEach((item) => {
      row.push({
        id: item._id,
        affilator: item.affilator,
        commision: item.affCommision,
        buyerId: item.buyerId,
        soldAt: item.soldAt.slice(0, 10),
      });
    });

  return (
    <div>
    <h1 className="ml-4 mt-6 mb-4 text-[25px] font-[600]">{t('Affiliate_Sales_Invoice')}</h1>
       <div className="py-5 px-5 bg-[#fff]">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
    </div>
    
  );
};


const AllTasks = () => {

  const [tData, setTData] = useState([]);
  const [tData2, setTData2] = useState([]);
  const [taskTU, setTaskTU] = useState(null);
  const { user } = useSelector((state) => state.user);

  const userId = user?._id;

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  useEffect(() => {
    axios
      .get(`${server}/task/get-user-taskdone/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTaskTU(res.data.tasks);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    axios.get(`${server}/task/open-all-tasks`, {withCredentials: true}).then((res) => {
      setTData(res.data.tasks);
       })
  }, []);

  useEffect(() => {
    axios.get(`${server}/videotask/get-all-videotasks`, {withCredentials: true}).then((res) => {
      setTData2(res.data.videotasks);
     
    })
  }, []);

  const taskByCountryTotal = tData && tData.filter((it) => it.targetedCountry === user?.country || it.targetedCountry === "Worldwide")

  const taskByCountryTotal2 = tData2 && tData2.filter((itu) => itu.targetedCountry === user?.country || itu.targetedCountry === "Worldwide")

  const totalTask1 = taskByCountryTotal && taskByCountryTotal.length;

  const totalTask2 = taskByCountryTotal2 && taskByCountryTotal2.length;

  const totalTask = Number(totalTask1) + Number(totalTask2);

     
  const columns = [
    { field: "id", headerName: "Task ID", minWidth: 150, flex: 0.7 },

    {
      field: "title",
      headerName: "Title",
      minWidth: 130,
      flex: 0.7,
      },
      {
      field: "payment",
      headerName: "Payment",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 130,
      flex: 0.7,
    },     
  ];

  const row = [];

  taskTU &&
  taskTU.forEach((item) => {
      row.push({
        id: item._id,
        title: item.name,
        payment: "TST " + item.taskPayment,
        date: item.updatedAt.slice(0, 10),
      });
    });

  
  return (
    <>
    <h1 className="text-[25px] font-[500]">{t('All_Tasks')}</h1>
    <p>{t('Total_unskilled')}</p>
    <h1 className="text-[20px] font-[500]">{totalTask}</h1>
    <p className="text-[15px] text-[blue]">{t('Note')}</p>

    <>   
    {
      totalTask && totalTask > 0 ? (
        <Link to="/tasks">
      <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
        {t('Begin_Work')}
      </button>
    </Link>
      ) : (
        <div>
        <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
          {t('Finished')}
        </button>
        <h5 className="text-[15px]">{t('no_a_t')}</h5>
        </div>
      )
    }
    </> 
   
    

    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">{t('Tasks_Payment_Invoice')}</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
    </>
  );
};

const Projects = () => {
    
  const [staskTU, setSTaskTU] = useState(null);
  const { user } = useSelector((state) => state.user);

  const userId = user?._id;


  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  useEffect(() => {
    axios
      .get(`${server}/stask/user-current-projects/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setSTaskTU(res.data.stasks);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

         
  const columns = [
    { field: "id", headerName: "Project ID", minWidth: 150, flex: 0.7 },

    {
      field: "title",
      headerName: "Title",
      minWidth: 130,
      flex: 0.7,
      },
      {
      field: "taskPayment",
      headerName: "Payment",
      minWidth: 130,
      flex: 0.4,
    },
    {
      field: "paymentMode",
      headerName: "Per",
      minWidth: 130,
      flex: 0.4,
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 130,
      flex: 0.4,
    },
    {
      field: "dMode",
      headerName: "",
      minWidth: 130,
      flex: 0.4,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/project-access/projectId/${params.id}`}>
              <h5 className="!text-[13px] text-[blue] flex items-center py-[10px] px-[10px] text-xl/4">{t('Begin_Work2')}</h5>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  staskTU &&
  staskTU.forEach((item) => {
      row.push({
        id: item._id,
        title: item.name,
        taskPayment: "$ " + item.taskPayment,
        paymentMode: item.paymentMode,
        duration: item.taskDuration,
        dMode: item.durationMode,
      });
    });

  
  return (
    <>
    <h1 className="text-[25px] font-[500]">{t('All_Projects')}</h1>
    <p>{t('c_bb')}</p>
    <p className="text-[15px] text-[blue]">{t('Note2')}</p>
    <Link to="/s-tasks">
      <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
        {t('Apply_p')}
      </button>
    </Link>
   
    

    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">{t('m_prs')}</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
    </>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: "greenColor"
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/track/order/${params.id}`}>
              <Button>
                <MdTrackChanges size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div>
      <h1 className="ml-4 mt-6 mb-4 text-[25px] font-[600]">{t('otl')}</h1>
       <div className="py-5 px-5 bg-[#fff]">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
        {t('cpw')}
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">{t('eopw')}</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">{t('eynp')}</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">{t('eycp')}</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className={`w-[95%] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};



const ChangePin = () => {
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  const pinChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-pin`,
        { oldPin, newPin, confirmPin },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPin("");
        setNewPin("");
        setConfirmPin("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
        Change Pin
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={pinChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Enter Old Pin</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPin}
              onChange={(e) => setOldPin(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter New Pin</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPin}
              onChange={(e) => setNewPin(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Confirm Pin</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPin}
              onChange={(e) => setConfirmPin(e.target.value)}
            />
            <input
              className={`w-[95%] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};



const Address = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [statee, setStatee] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState();
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();


  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  const addressTypeData = [
    {
      name: "Default",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || statee === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(
        updatUserAddress(
          country,
          statee,
          city,
          address1,
          address2,
          zipCode,
          addressType
        )
      );
      setOpen(false);
      setCountry("");
      setStatee("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode(null);
      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };

  return (
    <div className="w-full px-5">
      {open && (
        <div className="fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center ">
          <div className="w-[35%] h-[80vh] bg-white rounded shadow relative overflow-y-scroll">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className="text-center text-[25px] font-Poppins">
              {t('ana')}
            </h1>
            <div className="w-full">
              <form aria-required onSubmit={handleSubmit} className="w-full">
                <div className="w-full block p-4">
                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('pCty')}</label>
                    <select
                      name=""
                      id=""
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                       {t('cycty')}
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('cstate')}</label>
                    <select
                      name=""
                      id=""
                      value={statee}
                      onChange={(e) => setStatee(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                        {t('cystate')}
                      </option>
                      {State &&
                        State.getStatesOfCountry(country).map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('cty')}</label>
                    <select
                      name=""
                      id=""
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                        {t('cyc')}
                      </option>
                      {City &&
                        City.getCitiesOfState(country, statee).map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('Address_1')}</label>
                    <input
                      type="address"
                      className={`${styles.input}`}
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('Address_2')}</label>
                    <input
                      type="address"
                      className={`${styles.input}`}
                      required
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('Zip_Code')}</label>
                    <input
                      type="number"
                      className={`${styles.input}`}
                      required
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">{t('Address_Type')}</label>
                    <select
                      name=""
                      id=""
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                        {t('Choose_your_Address_Type')}
                      </option>
                      {addressTypeData &&
                        addressTypeData.map((item) => (
                          <option
                            className="block pb-2"
                            key={item.name}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className=" w-full pb-2">
                    <input
                      type="submit"
                      className={`${styles.input} mt-5 cursor-pointer`}
                      required
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="flex w-full items-center justify-between">
        <h1 className="text-[25px] font-[600] text-[#000000ba] pb-2">
          {t('My_Addresses')}
        </h1>
        <div
          className={`${styles.button} !rounded-md`}
          onClick={() => setOpen(true)}
        >
          <span className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">{t('Add_New')}</span>
        </div>
      </div>
      <br />
      {user &&
        user.addresses.map((item, index) => (
          <div
            className="w-full bg-white h-min 800px:h-[70px] rounded-[4px] flex items-center px-3 shadow justify-between pr-10 mb-5"
            key={index}
          >
            <div className="flex items-center">
              <h5 className="pl-5 font-[600]">{item.addressType}</h5>
            </div>
            <div className="pl-8 flex items-center">
              <h6 className="text-[12px] 800px:text-[unset]">
                {item.address1} {item.address2}
              </h6>
            </div>
            <div className="pl-8 flex items-center">
              <h6 className="text-[12px] 800px:text-[unset]">
                {user && user.phoneNumber}
              </h6>
            </div>
            <div className="min-w-[10%] flex items-center justify-between pl-8">
              <AiOutlineDelete
                size={25}
                className="cursor-pointer"
                onClick={() => handleDelete(item)}
              />
            </div>
          </div>
        ))}

      {user && user.addresses.length === 0 && (
        <h5 className="text-center pt-8 text-[18px]">
          {t('ydhave')}
        </h5>
      )}
    </div>
  );
};



const EditProfile = () => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);

  const email = user?.email;
  
   
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [aboutMe, setAboutMe] = useState(user && user.aboutMe);
  const [spotLight, setSpotLight] = useState(user && user.spotLight);

  const [potfolioURL, setPotfolioURL] = useState(user && user.potfolioURL);
  const [githubURL, setGithubURL] = useState(user && user.githubURL);
  const [linkedinURL, setLinkedinURL] = useState(user && user.linkedinURL);

  const [facebookUserName, setFacebookUserName] = useState(user && user.facebookUserName);
  const [instagramUserName, setInstagramUserName] = useState(user && user.instagramUserName);
  const [twitterUserName, setTwitterUserName] = useState(user && user.twitterUserName);
  const [tiktokUserName, setTiktokUserName] = useState(user && user.tiktokUserName);
  const [youtubeUserName, setYoutubeUserName] = useState(user && user.youtubeUserName);
  const [telegramUserName, setTelegramUserName] = useState(user && user.telegramUserName);

  const [trustpilotUserName, setTrustpilotUserName] = useState(user && user.trustpilotUserName);
  const [googleUserName, setGoogleUserName] = useState(user && user.googleUserName);
  const [boomplayUserName, setBoomplayUserName] = useState(user && user.boomplayUserName);
  const [spotifyUserName, setSpotifyUserName] = useState(user && user.spotifyUserName);
  const [soundcloudUserName, setSoundcloudUserName] = useState(user && user.soundcloudUserName);

  const [gender, setGender] = useState(user && user.gender);
  const [maritalStatus, setMaritalStatus] = useState(user && user.maritalStatus);
  const [educationLevel, setEducationLevel] = useState(user && user.educationLevel);
  const [numbersOfChildren, setNumbersOfChildren] = useState(user && user.numbersOfChildren);
  const [language, setLanguage] = useState(user && user.language);
  const [idType, setIdType] = useState(user && user.idType);
  const [idNumber, setIdNumber] = useState(user && user.idNumber);

  const [wmPaypal, setWmPaypal] = useState(user && user.wmPaypal);
  const [wmCrypto, setWmCrypto] = useState(user && user.wmCrypto);
  const [wmBankTransfer, setWmBankTransfer] = useState(user && user.wmBankTransfer);
  const [wmPayoneer, setWmPayoneer] = useState(user && user.wmPayoneer);
  const [wmGiftCard, setWmGiftCard] = useState(user && user.wmGiftCard);

  const [devices, setDevices] = useState(user && user.devices);
  const [operatingSystem, setOperatingSystem] = useState(user && user.operatingSystem);
  const [deviceBrand, setDeviceBrand] = useState(user && user.deviceBrand);
  const [deviceRAMSize, setDeviceRAMSize] = useState(user && user.deviceRAMSize);
  const [smartphoneBrand, setSmartphoneBrand] = useState(user && user.smartphoneBrand);
  const [smartphoneOperatingSystem, setSmartphoneOperatingSystem] = useState(user && user.smartphoneOperatingSystem);
  const [smartphoneOperatingSystemVersion, setSmartphoneOperatingSystemVersion] = useState(user && user.smartphoneOperatingSystemVersion);
  const [idIsNotAdded, setIdIsNotAdded] = useState(true);
  const [dobIsNotEntered, setDobIsNotEntered] = useState(true);
  

  const [visible, setVisible] = useState(false);

  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])
  
  
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  
  useEffect(() => {
    const idIsUploaded = user?.idType;
    const idNumberIsEntered = user?.idNumber;

    const dobIsEntered = user?.dateOfBirth;

    if(idIsUploaded !== undefined && idNumberIsEntered !== undefined){
      setIdIsNotAdded(false);
    }

    if(dobIsEntered !== undefined){
      setDobIsNotEntered(false);
    }

    },[]);

 
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(
      name, 
      email, 
      phoneNumber, 
      password, 
      aboutMe,
      spotLight,
      potfolioURL,
      githubURL,
      linkedinURL,
      facebookUserName, 
      instagramUserName, 
      twitterUserName, 
      tiktokUserName, 
      youtubeUserName, 
      telegramUserName,
      trustpilotUserName,
      googleUserName,
      boomplayUserName,
      spotifyUserName,
      soundcloudUserName,
      dateOfBirth,
      gender,
      maritalStatus,
      educationLevel,
      numbersOfChildren,
      language,
      devices,
      operatingSystem,
      deviceBrand,
      deviceRAMSize,
      smartphoneBrand,
      smartphoneOperatingSystem,
      smartphoneOperatingSystemVersion,
      idType,
      idNumber,
      wmPaypal,
      wmCrypto,
      wmBankTransfer,
      wmPayoneer,
      wmGiftCard,
      ));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        // Retrieve the JWT token from localStorage
    const token = localStorage.getItem('user_Token'); 
    
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              headers: {
                Authorization: `Bearer ${token}`, // Attach token in the Authorization header
              },
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
           
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };


    
  return (
    <>
    <div className="flex justify-center w-full">
            <div className="relative">
            {user?.avatar?.url === undefined ? <CgProfile size={120} color="green" /> : 
                    <img
                    src={`${user?.avatar?.url}`}
                    className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
                    alt=""
                  />}
              
              <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <input
                  type="file"
                  id="image"
                  className="hidden"
                  onChange={handleImage}
                />
                <label htmlFor="image">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="w-full px-5">
          
          <form onSubmit={handleSubmit} aria-required={true}>
          <h2 className="text-[25px] text-[600]">Spotlight</h2><br />
         <div>
          <label className="pb-2 text-[blue]">
            Write brief summary of your profession.
          </label>
          <textarea
            cols="30"
            rows="6"
            type="text"
            name="spotLight"
            value={spotLight}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpotLight(e.target.value)}
            placeholder="Enter profile summary..."
            maxLength={300}
          ></textarea>
        </div>
        <br />
        <br />

              <h2 className="text-[25px] text-[600]">{t('Personal_Info')}</h2><br />
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Name')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
               </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Gender')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.gender}</h5>
                    <div className="mt-1">
                    <select
                    name="gender"
                    onChange={(e) => setGender(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                  </div>
                </div><br />
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Marital_Status')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.maritalStatus}</h5>
                    <div className="mt-1">
                    <select
                    name="maritalStatus"
                    onChange={(e) => setMaritalStatus(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widow">Widow</option>
                    <option value="Widower">Widower</option>
                    <option value="It's Complicated">It's Complicated</option>
                    <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                  </div>
                </div>               
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Language')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.language}</h5>
                    <div className="mt-1">
                    <select
                    name="language"
                    onChange={(e) => setLanguage(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="English">English</option>
                    <option value="French">French</option>
                    <option value="Spanish">Spanish</option>
                    <option value="German">German</option>
                    <option value="Portuguese">Portuguese</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Italian">Italian</option>
                    <option value="Korran">Korran</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Vietnamese">Vietnamese</option>
                    <option value="Japanese">Japanese</option>
                    <option value="Turkish">Turkish</option>
                    <option value="Indonesian">Indonesian</option>
                    <option value="Dutch">Dutch</option>
                    <option value="Urdu">Urdu</option>
                    </select>
                  </div>
                </div>
                              
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Mobile')}</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

               {dobIsNotEntered && 
               <div className=" w-[100%] 800px:w-[50%]">
               <label className="block pb-2">{t('Date_of_Birth')}</label>
              <input
                 type="date"
                 className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                 value={dateOfBirth}
                 onChange={(e) => setDateOfBirth(e.target.value)}
               />
             </div>
               }
                
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Number_of_Children')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.numbersOfChildren}</h5>
                    <div className="mt-1">
                    <select
                    name="numbersOfChildren"
                    onChange={(e) => setNumbersOfChildren(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="None">None</option>
                    <option value="1 - 4">1 - 4</option>
                    <option value="5 - 9">5 - 9</option>
                    <option value="10+">10+</option>
                    <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                  </div>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Highest_Level_of_Education')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.educationLevel}</h5>
                    <div className="mt-1">
                    <select
                    name="educationLevel"
                    onChange={(e) => setEducationLevel(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Primary">Primary</option>
                    <option value="Secondary">Secondary</option>
                    <option value="High School">High School</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Degree">Degree</option>
                    <option value="Master">Master</option>
                    <option value="Phd">Phd</option>
                    <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                  </div>
                </div>               
              </div><br />


          <h2 className="text-[25px] text-[600]">{t('About_Me')}</h2><br />
         <div>
          <label className="pb-2 text-[blue]">
            {t('raye')} 
          </label>
          <textarea
            cols="30"
            rows="8"
            type="text"
            name="aboutMe"
            value={aboutMe}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAboutMe(e.target.value)}
            placeholder="Enter experience and skills..."
            maxLength={5000}
          ></textarea>
        </div>
        <br />
        <br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Portfolio Link:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={potfolioURL}
                    onChange={(e) => setPotfolioURL(e.target.value)}
                  />
                </div>
               </div>
               <br />

               <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Github Profile Link:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={githubURL}
                    onChange={(e) => setGithubURL(e.target.value)}
                  />
                </div>
               </div>
               <br />

               <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Linkedin Profile Link:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={linkedinURL}
                    onChange={(e) => setLinkedinURL(e.target.value)}
                  />
                </div>
               </div>
               <br />
               <br />


              <h2 className="text-[25px] text-[600]">{t('Social_Media_Info')}</h2><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Facebook_Username')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={facebookUserName}
                    onChange={(e) => setFacebookUserName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Instagram_Username')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    value={instagramUserName}
                    onChange={(e) => setInstagramUserName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Twitter_Username')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={twitterUserName}
                    onChange={(e) => setTwitterUserName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Tiktok_Username')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    value={tiktokUserName}
                    onChange={(e) => setTiktokUserName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Youtube_Username')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={youtubeUserName}
                    onChange={(e) => setYoutubeUserName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Telegram_Username')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    value={telegramUserName}
                    onChange={(e) => setTelegramUserName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Trustpilot Username</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={trustpilotUserName}
                    onChange={(e) => setTrustpilotUserName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Google Username (i.e Name on the gmail account you used to signed in)</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    value={googleUserName}
                    onChange={(e) => setGoogleUserName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Boomplay Username</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={boomplayUserName}
                    onChange={(e) => setBoomplayUserName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Spotify Username</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    value={spotifyUserName}
                    onChange={(e) => setSpotifyUserName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
               <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Soundcloud Username</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    value={soundcloudUserName}
                    onChange={(e) => setSoundcloudUserName(e.target.value)}
                  />
                </div>
              </div>
              <br />

              <h2 className="text-[25px] text-[600]">{t('Tester_Info')}</h2><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.devices}</h5>
                    <div className="mt-1">
                    <select
                    name="devices"
                    onChange={(e) => setDevices(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="None">None</option>
                    <option value="Smartphone">Smartphone</option>
                    <option value="Laptop">Laptop</option>
                    <option value="Desktop">Desktop</option>
                    <option value="Smart Tv">Smart-Tv</option>
                    <option value="Smartphone, Laptop">Smartphone & Laptop</option>
                    <option value="Smartphone, Desktop">Smartphone & Desktop</option>
                    <option value="Laptop, Desktop">Laptop & Desktop</option>
                    <option value="Smartphone, Laptop, Desktop, Smart-Tv">All</option>
                    </select>
                  </div>
                </div>

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device_Operating_Sytem')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.operatingSystem}</h5>
                    <div className="mt-1">
                    <select
                    name="operatingSystem"
                    onChange={(e) => setOperatingSystem(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Windows">Windows</option>
                    <option value="Linux">Linux</option>
                    <option value="MacOS">MacOS</option>
                    <option value="iOS">iOS</option>
                    <option value="Android">Android</option>
                    <option value="Ubuntu">Ubuntu</option>
                    <option value="Cent">Cent</option>
                    <option value="FreeBSD">FreeBSD</option>
                    <option value="Solaris">Solaris</option>
                    <option value="Debian">Debian</option>
                    <option value="Fedora">Fedora</option>
                    </select>
                  </div>
                </div>               
              </div><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device_Brand')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.deviceBrand}</h5>
                    <div className="mt-1">
                    <select
                    name="deviceBrand"
                    onChange={(e) => setDeviceBrand(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Hp">Hp</option>
                    <option value="Dell">Dell</option>
                    <option value="Lenovo">Lenovo</option>
                    <option value="Acer">Acer</option>
                    <option value="ASUS">ASUS</option>
                    <option value="Razer">Razer</option>
                    <option value="Apple">Apple</option>
                    <option value="MSI">MSI</option>
                    <option value="Microsoft">Microsoft</option>
                    <option value="Samsung">Samsung</option>
                    <option value="Toshiba">Toshiba</option>
                    <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Device_RAM_Size')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.deviceRAMSize}</h5>
                    <div className="mt-1">
                    <select
                    name="deviceRAMSize"
                    onChange={(e) => setDeviceRAMSize(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="1GB">1GB</option>
                    <option value="2GB">2GB</option>
                    <option value="4GB">4GB</option>
                    <option value="8GB">8GB</option>
                    <option value="16GB and above">16GB and above</option>
                    </select>
                  </div>
                </div>               
              </div><br />

              <div className="w-full 800px:flex block pb-3">
              <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Smartphone_Brand')}</label>
                  <input
                    type="text"
                    name="smartphoneBrand"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={smartphoneBrand}
                    onChange={(e) => setSmartphoneBrand(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Smartphone_Operating_System')}</label>
                  <input
                    type="text"
                    name="smartphoneOperatingSystem"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={smartphoneOperatingSystem}
                    onChange={(e) => setSmartphoneOperatingSystem(e.target.value)}
                  />
                </div>
              </div><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('Smartphone_Operating_System_Version')}</label>
                  <input
                    type="text"
                    name="smartphoneOperatingSystemVersion"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={smartphoneOperatingSystemVersion}
                    onChange={(e) => setSmartphoneOperatingSystemVersion(e.target.value)}
                  />
                </div>
              </div><br />

              {idIsNotAdded && 
              <>
              <h2 className="text-[25px] text-[600]">{t('Verification')}</h2>
              <h5 className="text-[16px] text-[600] text-[blue]">{t('wwvi')}</h5><br />

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('ID_Type')}</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.idType}</h5>
                  <div className="mt-1">
                    <select
                    name="idType"
                    onChange={(e) => setIdType(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="nill">nill</option>
                    <option value="International Passport">International Passport</option>
                    <option value="Drivers Licence">Drivers Licence</option>
                    <option value="NIN">NIN</option>
                    <option value="Voters Card">Voters Card</option>
                    </select>
                  </div>
                </div>
                
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('ID Number')}</label>
                  <input
                    type="text"
                    name="idNumber"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                  />
                </div>
              </div>

              <br />
              </>
              }



<h2 className="text-[25px] text-[600]">Payment Methods/Details</h2><br />

<div className="w-full 800px:flex block pb-3">
  <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2">Paypal Email:</label>
    <input
      type="text"
      className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
      value={wmPaypal}
      onChange={(e) => setWmPaypal(e.target.value)}
    />
  </div>
  <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2">Crypto Type/Address: (e.g USDT (TRC20) TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t )</label>
    <input
      type="text"
      className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
      value={wmCrypto}
      onChange={(e) => setWmCrypto(e.target.value)}
    />
  </div>
</div>

<div className="w-full 800px:flex block pb-3">
  <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2">Bank Full Details: </label>
    <input
      type="text"
      className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
      value={wmBankTransfer}
      onChange={(e) => setWmBankTransfer(e.target.value)}
    />
  </div>
  <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2">Payoneer Email:</label>
    <input
      type="text"
      className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
      value={wmPayoneer}
      onChange={(e) => setWmPayoneer(e.target.value)}
    />
  </div>
</div>

<div className="w-full 800px:flex block pb-3">
  <div className=" w-[100%] 800px:w-[50%]">
    <label className="block pb-2">Gift Card (Choose Type):</label>
    <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{user?.wmGiftCard}</h5>
    <div className="mt-1">
                    <select
                    name="wmGiftCard"
                    onChange={(e) => setWmGiftCard(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="Choose">Choose</option>
                    <option value="Amazon">Amazon</option>
                    <option value="Spa">Spa</option>
                    <option value="Totlesoft">Totlesoft</option>
                    <option value="GameStop">GameStop</option>
                    <option value="Home Depot">Home Depot</option>
                    <option value="DoorDash">DoorDash</option>
                    <option value="Walmart">Walmart</option>
                    <option value="Netflix">Netflix</option>
                    <option value="iTunes">iTunes</option>
                    </select>
                  </div>
  </div>
  
  </div>

<br />



              
                    <div>
                    <label className="block text-sm font-medium text-gray-700">{t('eptu')}</label>
                    <div className="mt-1 relative">
                      <input
                        type={visible ? "text" : "password"}
                        placeholder="enter password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
                </div>
                </div>

              <input
                className={`w-[250px] h-[40px] border border-[#fff] text-center bg-[#000] text-[#fff] rounded-[3px] mt-8 cursor-pointer`}
                required
                value="Update"
                type="submit"
              />
            </form>
            <br />
            <br />
          </div>
        </>
    );
};


const Articles = () => {
  const { user } = useSelector((state) => state.user);
  const { articles, isLoading } = useSelector((state) => state.articles);
    
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllArticlesUser(user._id));
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "Article Id", minWidth: 150, flex: 1 },

    {
      field: "author",
      headerName: "Author",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "name",
      headerName: "Title",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "minReads",
      headerName: "Length",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "date",
      headerName: "Date",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
  ];

  const row = [];

  articles &&
  articles.forEach((item) => {
      row.push({
        id: item._id,
        author: item.user.name,
        name: item.name,
        minReads: item.minReads,
        date: item.date,
      });
    });

  return (
    <div>

      <Link to="/article/postnew" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Create New
               </span>
          </div>
      </Link>

    <h1 className="ml-4 mt-6 mb-4 text-[25px] font-[600]">My Articles</h1>
       <div className="py-5 px-5 bg-[#fff]">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
    </div>
    
  );
};


const DJobs = () => {
    
  const [djobTU, setDjobTU] = useState(null);
  const { user } = useSelector((state) => state.user);
  

  const userId = user?._id;
  
  
  useEffect(() => {
    axios
      .get(`${server}/djob/user-current-djobs/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setDjobTU(res.data.djobs);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);    

         
  const columns = [
    { field: "id", headerName: "Job Id", minWidth: 150, flex: 1 },
    {
      field: "name",
      headerName: "Title",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "taskPayment",
      headerName: "Payment",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "acceptedBy",
      headerName: "Worker",
      minWidth: 80,
      flex: 0.5,
    },
    {
        field: "workStatus",
        headerName: "Job Status",
        minWidth: 80,
        flex: 0.3,
      },
      {
        field: "deadLine",
        headerName: "Deadline",
        minWidth: 80,
        flex: 0.3,
      },
    {
      field: "date",
      headerName: "Listed On",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "samples",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
           <Link to={`/djob/send-samples-worker/${params.id}`}>
            <h5 className="cursor-pointer !text-[13px] text-[blue] flex items-center py-[10px] px-[10px] text-xl/4"
            >send samples
           </h5>
           </Link>
          </>
        );
      },
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/djob/view-samples/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    
  ];

  const row = [];

  djobTU &&
  djobTU.forEach((item) => {
      row.push({
        id: item?._id,
        name: item?.name,
        taskPayment: "$ " + item.taskPayment,
        acceptedBy: item?.acceptedBy,
        workStatus: item?.workStatus,
        deadLine: item?.deadLine,
        date: item?.createdAt.slice(0, 10),
      });
    });

  
  return (
    <>
    <h1 className="text-[25px] font-[500]">All Direct Jobs</h1>
    <p>Click button bellow to search and accept current open jobs</p>
    <p className="text-[15px] text-[blue]">NOTE: Only accept jobs related to your roles and also jobs you know you can be able to meet its deadline. Too many poorly done jobs that are rejected will lead to your termination from this platform. Quality jobs submissions will increase your ratings and access to bigger paying jobs.</p>
    <Link to="/jobs">
      <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
        Begin
      </button>
    </Link>      

    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">Accepted Jobs</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
    </>
  );
};


const Investment = () => {

  const { user } = useSelector((state) => state.user);
  const investorID = user?._id;
  const [isvisible, setIsVisible] = useState(false);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [iData, setIdata] = useState([]);

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  useEffect(() => {
    axios
      .get(`${server}/investment/get-all-investments/${investorID}`, {
        withCredentials: true,
      })
      .then((res) => {
        setIdata(res.data.investments);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  const refLink = user?.refLink;


  const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    {
      field: "amountOfTST",
      headerName: "Amount",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "duration",
      headerName: "Days",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "investmentStatus",
      headerName: "Status",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: " ",
      flex: 0.5,
      minWidth: 150,
      headerName: "View",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/i/details/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
        );
      },
    },

  ];

  const row = [];
  
  iData &&
    iData.forEach((item) => {
      row.push({
        id: item._id,
        amountOfTST: "TST " + item.amountOfTST,
        duration: item.duration,
        investmentStatus: item.investmentStatus,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  
  
  return (
    <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>

    <h1 className="ml-2 text-[35px] font-[500]">Totlesoft Token (TST) Copy Trading</h1>
    <h5 className="ml-2 pb-4">Put your money to work! Let our expert crypto trading team help you grow your funds with any of the bellow plan to earn passive income daily.</h5>
    <Link to="/dashboard-invest" className="inline-block">
          <div className={`${styles.buttonRed} !bg-[#014D4E] !ml-2 mb-3`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Invest
               </span>
          </div>
      </Link>
    <div className="mt-4 w-full block 800px:flex items-center justify-between px-5">
    <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-[#1c1c1c] shadow rounded px-5 py-5">
    <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              Junor Investment{" "}
              <span className="text-[#c89b2a] text-[16px]"> (TST)</span>
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-2 pl-2 text-[22px] font-[300]">7 Days</h5>
        
          <h5 className="text-center text-[#fff] pl-2 text-[#000]">0.1% daily</h5>
          <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 pl-2">Invest</h5></Link>
          </div>

          <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-[#1c1c1c] shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] leading-5 text-center !font-[400] text-[#fff]`}
            >
              Decor Investment{" "}
              <span className="text-[#c89b2a] text-[16px]"> (TST)</span>
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-2 pl-2 text-[22px] font-[300]">14 Days</h5>
        
          <h5 className="text-center text-[#fff] pl-2 text-[#000]">0.15% daily</h5>
          <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 pl-2">Invest</h5></Link>
          </div>

          <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-[#1c1c1c] shadow rounded px-5 py-5">
    <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] leading-5 text-center !font-[400] text-[#fff]`}
            >
              Arion Investment{" "}
              <span className="text-[#c89b2a] text-[16px]"> (TST)</span>
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-2 pl-2 text-[22px] font-[300]">21 Days</h5>
        
          <h5 className="text-center text-[#fff] pl-2 text-[#000]">0.18% daily</h5>
          <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 pl-2">Invest</h5></Link>
          </div>

          <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-[#1c1c1c] shadow rounded px-5 py-5">
    <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] leading-5 text-center !font-[400] text-[#fff]`}
            >
              Danor Investment{" "}
              <span className="text-[#c89b2a] text-[16px]"> (TST)</span>
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-2 pl-2 text-[22px] font-[300]">28 Days</h5>
        
          <h5 className="text-center text-[#fff] pl-2 text-[#000]">0.2% daily</h5>
          <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 pl-2">Invest</h5></Link>
          </div>     

      </div>

    <div className="w-full block 800px:flex items-center justify-between px-5">
      <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-[#014D4E] shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] leading-5 !font-[400] text-[#fff] text-center`}
            >
              Active Investment{" "}
              <span className="text-[#c89b2a] text-[16px]">(TST)</span>
            </h3>
          </div>
          <h5 className="text-center text-[#fff] pt-2 pl-2 text-[30px] font-[400]">{user?.activeInvestmentBalance === undefined ? 0 : user?.activeInvestmentBalance}</h5>
          </div> 

        <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400] text-[#00000085]`}
            >
              Total Investment Deposit
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[22px] font-[400]">{user?.totalInvestmentDeposit === undefined ? 0 : user?.totalInvestmentDeposit}</h5>
          <h5 className="text-center pt-4 pl-[2] text-[#014D4E]">TST</h5>
          </div>

        <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400]  text-[#00000085]`}
            >
              Total Investment Withdrawal
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[22px] font-[400]">{user?.totalInvestmentWithdrawal === undefined ? 0 : user?.totalInvestmentWithdrawal}</h5>
          <h5 className="text-center pt-4 pl-[2] text-[#014D4E]">TST</h5>
          </div>

        <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
            <FaUserFriends
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400]  text-[#00000085]`}
            >
              Invite Friends{" "}
              <span className="text-[16px]">(Receive 10% commission)</span>
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[22px] font-[400]">{user?.totalInvestmentReferrer === undefined ? 0 : user?.totalInvestmentReferrer}</h5>
          <div onClick={() => setIsVisible(true)} 
          className="text-center items-center justify-center"
          >
            <h5 className="cursor-pointer text-center pt-4 pl-[2] pr-[2] text-[#014D4E]">{t('Generate_Link')}</h5>
            {isvisible ? <h5 className="pt-2 pl-[5px] pr-[5px] text-[12px] font-[400]">{refLink}</h5> : null}
           </div>
           {isvisible ? <h5 onClick={writeTextToClipboard} className="cursor-pointer text-[12px] text-center pl-[2] pr-[2] text-[#077f9c]">{t('Copy')}</h5> : null}
           </div>
        </div>
        <br />

        <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">Investment Tracking</h1>
        </div>
        

        <div className="w-full bg-white rounded pb-6 mb-4">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
            
          <br />
          <br />

    </div>
  )
}


const Subscription = () => {

  const [taskTU, setTaskTU] = useState(null);
  const [taskTU2, setTaskTU2] = useState(null);
  const { user } = useSelector((state) => state.user);

  const userId = user?._id;

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])


  useEffect(() => {
    axios
      .get(`${server}/talent/get-user-subscription-packages/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTaskTU(res.data.talents);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  useEffect(() => {
    axios
      .get(`${server}/talent/get-user-full-packages/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTaskTU2(res.data.talents);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  console.log("d to use");
  console.log(taskTU);
  console.log("d to use 2");
  console.log(taskTU2);

         
  const columns = [
    { field: "id", headerName: "ID", minWidth: 150, flex: 0.7 },

    {
      field: "title",
      headerName: "Title",
      minWidth: 130,
      flex: 0.7,
      },
      {
      field: "payment",
      headerName: "Monthly",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 130,
      flex: 0.7,
    }, 
    {
      field: "Access",
      flex: 0.8,
      minWidth: 100,
      headerName: "Access",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/package-access/${userId}/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },    
  ];

  const row = [];

  taskTU &&
  taskTU.forEach((item) => {
      row.push({
        id: item._id,
        title: item.name,
        payment: "$ " + item.subscriptionAmount,
        date: item.createdAt.slice(0, 10),
      });
    });


    const columns2 = [
      { field: "id", headerName: "ID", minWidth: 150, flex: 0.7 },
  
      {
        field: "title",
        headerName: "Title",
        minWidth: 130,
        flex: 0.7,
        },
        {
        field: "payment",
        headerName: "Amount",
        minWidth: 130,
        flex: 0.5,
      },
      {
        field: "date",
        headerName: "Date",
        minWidth: 130,
        flex: 0.7,
      }, 
      {
        field: "Access",
        flex: 0.8,
        minWidth: 100,
        headerName: "Access",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Link to={`/package-access/${userId}/${params.id}`}>
                <Button>
                  <AiOutlineEye size={20} />
                </Button>
              </Link>
            </>
          );
        },
      },    
    ];
  
    const row2 = [];
  
    taskTU2 &&
    taskTU2.forEach((item) => {
        row2.push({
          id: item._id,
          title: item.name,
          payment: "$ " + item.charge,
          date: item.createdAt.slice(0, 10),
        });
      });

  
  return (
    <>
    <h1 className="text-[25px] font-[500]">My Subscriptions/Pack Purchases</h1>
    <p>Note: Ensure you have enough money on your payment balance before the date your subscription will be renewed to avoid negative impact on your balance</p>
    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">Subscriptions</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>

    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">Packages</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row2}
        columns={columns2}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
    </>
  );
};


export default ProfileContent;

