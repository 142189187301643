import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TalentCard from "../components/Route/TalentCard/TalentCard";
import styles from "../styles/styles";
import SpinnerFull from "../components/Spinner/SpinnerFull";
import axios from "axios";
import { server } from "../server";
import { freelanceCategoriesData, categoriesData } from "../static/data";
import { Country } from "country-state-city";
import ProductPageUpperButtonBar from "../components/ProductPageUpperButtonBar";


const TalentPage = () => {

  const [data, setData] = useState([]);
  const [categorry, setCategorry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [specialization, setSpecialization] = useState("");
  const [targetedCountry, setTargetedCountry] = useState("");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/talent/get-all-talents`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.talents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setIsLoading(false);
      });
  }, []);


  const searchbySkillHandler = () => {
    const sbyS = data && data.filter((it) => it.user.specialization === specialization);
    setData(sbyS);
  }

  const searchbyCategoryHandler = () => {
    const sbyC = data && data.filter((i) => i.category === categorry);
    setData(sbyC);
  }

  const searchbyCountryHandler = () => {
    const sbyCt = data && data.filter((itt) => itt.user.country === targetedCountry);
    setData(sbyCt);
  }

  

  
   
  return (
    <>
    <div>
      <Header activeHeading={3} />
      <br />
      <div className="w-full sticky 800px:mt-0 mt-[18%]">
              <ProductPageUpperButtonBar />
            </div>
      <br />

      {isLoading ? (
        <div className="w-full py-5 px-10">
        <h1 className="text-[20px] text-[500] mb-3">Loading Gigs...!</h1>
        <SpinnerFull />
        </div>
      ) : (
    <>
     <div className={`${styles.section}`}>
          <div>
            <h1 className="font-[500]">Search/Filter by:</h1>
          </div>

          <div className="mb-4 block 800px:flex">
          <div className="flex">
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          >
            <option value="Select">Skill</option>
            {freelanceCategoriesData &&
              freelanceCategoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>

          <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
          onClick={searchbySkillHandler}
          >
          <span className="text-[#fff] font-[Poppins] text-[14px]">
            Search
          </span>
        </div>
        </div>

        <div className="flex 800px:ml-4">
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={categorry}
            onChange={(e) => setCategorry(e.target.value)}
          >
            <option value="Select">Category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>

          <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
          onClick={searchbyCategoryHandler}
          >
          <span className="text-[#fff] font-[Poppins] text-[14px]">
            Search
          </span>
        </div>
        </div>
        

        <div className="flex 800px:ml-4">
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={targetedCountry}
            onChange={(e) => setTargetedCountry(e.target.value)}
          >
            <option value="Select">Country</option>
            {Country &&
              Country.getAllCountries().map((item) => (
                <option value={item.isoCode} key={item.isoCode}>
                  {item.name}
                </option>
              ))}
              <option value="Worldwide">Worldwide</option>
          </select>

          <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
          onClick={searchbyCountryHandler}
          >
          <span className="text-[#fff] font-[Poppins] text-[14px]">
            Search
          </span>
        </div>
        </div>
          </div>
            
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <TalentCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No Gig Found!
          </h1>
        ) : null}
      </div>
      </>
  )}
     
      <br />
        <Footer />
    </div>
    
  </>
    )
};

export default TalentPage;
