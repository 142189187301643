import React from "react";

const AMLPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-center mb-6">
        AML/CFT POLICY FOR TOTLESOFT
      </h1>
      <p className="text-center mb-8">
        <strong>Website:</strong>{" "}
        <a
          href="https://totlesoft.com/aml-policy"
          className="text-blue-600 hover:underline"
        >
          https://totlesoft.com/aml-policy
        </a>
        <br />
        <strong>Effective Date:</strong> June 1st, 2024
      </p>

      <div className="space-y-6">
        {/* Section 1: Introduction */}
        <section>
          <h2 className="text-xl font-bold mb-2">1. Introduction</h2>
          <p>
            Totlesoft is committed to complying with all applicable laws and
            regulations regarding <strong>Anti-Money Laundering (AML)</strong> and{" "}
            <strong>Countering the Financing of Terrorism (CFT)</strong>. We
            strictly prohibit any form of money laundering, terrorist financing,
            or illegal financial activities in connection with our business
            operations.
          </p>
        </section>

        {/* Section 2: Purpose */}
        <section>
          <h2 className="text-xl font-bold mb-2">2. Purpose</h2>
          <p>
            This policy outlines Totlesoft’s measures to:
          </p>
          <ul className="list-disc pl-6 space-y-1 mt-2">
            <li>
              Prevent the use of our services for money laundering or terrorism
              financing.
            </li>
            <li>Comply with legal and regulatory requirements.</li>
            <li>
              Implement risk-based controls to detect and report suspicious
              activities.
            </li>
          </ul>
        </section>

        {/* Section 3: Risk Assessment */}
        <section>
          <h2 className="text-xl font-bold mb-2">3. Risk Assessment</h2>
          <p>
            Totlesoft conducts a risk assessment to identify potential AML/CFT
            risks associated with our business. Given our operations (describe
            briefly, e.g., software development, digital services, etc.), we
            assess our exposure to financial crimes as <strong>low</strong>.
            However, we remain vigilant in monitoring transactions and customer
            interactions.
          </p>
        </section>

        {/* Section 4: Customer Due Diligence (CDD) */}
        <section>
          <h2 className="text-xl font-bold mb-2">
            4. Customer Due Diligence (CDD)
          </h2>
          <p>
            To mitigate risks, Totlesoft implements the following measures:
          </p>
          <ul className="list-disc pl-6 space-y-1 mt-2">
            <li>
              <strong>Identification & Verification:</strong> We verify the
              identity of customers/business partners when necessary (e.g., for
              large transactions or contractual agreements).
            </li>
            <li>
              <strong>Ongoing Monitoring:</strong> We monitor transactions for
              unusual or suspicious activity.
            </li>
            <li>
              <strong>Enhanced Due Diligence (EDD):</strong> Applied in high-risk
              scenarios (if applicable).
            </li>
          </ul>
        </section>

        {/* Section 5: Reporting Suspicious Activity */}
        <section>
          <h2 className="text-xl font-bold mb-2">
            5. Reporting Suspicious Activity
          </h2>
          <ul className="list-disc pl-6 space-y-1">
            <li>
              Employees and associates must report any suspicious transactions to
              the <strong>Compliance Officer</strong>.
            </li>
            <li>
              If necessary, reports will be filed with the relevant{" "}
              <strong>Financial Intelligence Unit (FIU)</strong> or regulatory
              authority.
            </li>
          </ul>
        </section>

        {/* Section 6: Record Keeping */}
        <section>
          <h2 className="text-xl font-bold mb-2">6. Record Keeping</h2>
          <ul className="list-disc pl-6">
            <li>
              Financial and customer identification records will be maintained for
              at least <strong>five (5) years</strong> (or as required by law).
            </li>
          </ul>
        </section>

        {/* Section 7: Employee Training */}
        <section>
          <h2 className="text-xl font-bold mb-2">7. Employee Training</h2>
          <ul className="list-disc pl-6">
            <li>
              Staff will receive periodic training on AML/CFT regulations and
              their responsibilities under this policy.
            </li>
          </ul>
        </section>

        {/* Section 8: Compliance Officer */}
        <section>
          <h2 className="text-xl font-bold mb-2">8. Compliance Officer</h2>
          <p>
            Totlesoft designates <strong>[Name/Position]</strong> as the{" "}
            <strong>AML/CFT Compliance Officer</strong>, responsible for enforcing
            this policy and liaising with authorities.
          </p>
        </section>

        {/* Section 9: Review & Updates */}
        <section>
          <h2 className="text-xl font-bold mb-2">9. Review & Updates</h2>
          <p>
            This policy will be reviewed <strong>annually</strong> and updated as
            needed to reflect regulatory changes.
          </p>
        </section>

        {/* Section 10: Declaration */}
        <section>
          <h2 className="text-xl font-bold mb-2">10. Declaration</h2>
          <p>
            Totlesoft confirms that we do not engage in money laundering,
            terrorist financing, or any illegal financial activities. We adhere to
            all applicable AML/CFT laws and cooperate fully with financial
            institutions and regulatory bodies.
          </p>
          <div className="mt-6">
            <p className="mb-2">
              <strong>Signed:</strong>
            </p>
            <div className="border-t-2 border-gray-300 pt-4">
              <p>
                <strong>Christotle Agholor</strong>
              </p>
              <p>
                <strong>CTO</strong>
              </p>
              <p>
                <strong>Totlesoft</strong>
              </p>
              <p>
                <strong>
                  Website:{" "}
                  <a
                    href="https://totlesoft.com"
                    className="text-blue-600 hover:underline"
                  >
                    https://totlesoft.com
                  </a>
                </strong>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const AMLPolicyPage = () => {
    return (
        <AMLPolicy />
    )
}

export default AMLPolicyPage;