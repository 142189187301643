import React from "react";
import { FaCheckCircle, FaTasks, FaBriefcase, FaUsers, FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const EarningSection = () => {
    const { user } = useSelector((state) => state.user);

  const earningMethods = [
    {
      text: "Complete CPA Offers and earn",
      icon: <FaCheckCircle className="text-black text-xl" />,
      link: "/cpaoffers"
    },
    {
      text: "Complete Simple Tasks and Earn",
      icon: <FaTasks className="text-black text-xl" />,
      link: "/tasks"
    },
    {
      text: "Complete Direct Jobs and Earn",
      icon: <FaBriefcase className="text-black text-xl" />,
      link: "/jobs"
    },
    {
      text: "Join Projects and Participate to Earn",
      icon: <FaUsers className="text-black text-xl" />,
      link: "/s-tasks"
    },
    {
      text: "Generate Affiliate Link on Product and promote it to Earn Commission",
      icon: <FaLink className="text-black text-xl" />,
      link: "/products"
    }
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-[50vh] bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md md:max-w-2xl lg:max-w-4xl text-center">
        {/* Big Get Started Button */}
        {user ? <Link to="/tasks">
        <button className="bg-black hover:bg-gray-800 text-white font-bold py-4 px-8 rounded-lg text-lg md:text-xl shadow-lg transform transition duration-150 ease-in-out hover:scale-105 mb-12">
          Start Task
        </button>
        </Link> : <Link to="/sign-up">
        <button className="bg-black hover:bg-gray-800 text-white font-bold py-4 px-8 rounded-lg text-lg md:text-xl shadow-lg transform transition duration-150 ease-in-out hover:scale-105 mb-12">
          Get Started
        </button>
        </Link>}

        {/* Earning Methods List */}
        <div className="space-y-6">
          {earningMethods.map((method, index) => (
            <Link to={method.link}>
            <div 
              key={index}
              className="flex items-center justify-center space-x-4 bg-gray-50 p-4 rounded-lg border border-gray-200 hover:shadow-md transition-shadow duration-200"
            >
              <div className="flex-shrink-0">
                {method.icon}
              </div>
              <p className="text-black text-lg md:text-xl font-medium">
                {method.text}
              </p>
            </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EarningSection;