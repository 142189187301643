import React from 'react';
import suveooNG from '../Assests/images/surveoong.png';
import "./flippingImage2.css";

const FlippingImage2 = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="animate-flip">
        <img 
          src={suveooNG} 
          width={300} 
          height={180}
          alt="" 
          className="object-cover"
        />
      </div>
    </div>
  );
};

export default FlippingImage2;