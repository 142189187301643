import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SurveyCard from "../components/Route/SurveyCard/SurveyCard";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../server";

const SurveyPage = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [allSurveys, setAllSurveys] = useState([]);
  const { user } = useSelector((state) => state.user);
  

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/survey/get-all-surveys`, {withCredentials: true}).then((res) => {
      setAllSurveys(res.data.surveys);
        setIsLoading(false);
    })
  }, []);

  const userDoingTask = user?._id;
  
  const taskByCountry = allSurveys && allSurveys.filter((itmee) => itmee.eligibleCountry === user?.country || itmee.eligibleCountry === "Worldwide") || [];

  console.log("worker country", user?.country);
  
     
  return (
    <>
  {
    isLoading ? (
      <Spinner />
    ) : (
      <div>
      <Header />
      <br />
      <br />
      {
        user && user?.role === "Worker" ? (
          <div className={`${styles.section}`}>
             <h2 className="ml-2 text-[30px] font-[600]">View other tasks</h2>

             <di className="flex 800px:w-[500px] w-full">
             <Link to="/tasks" className="inline-block 800px:mr-4 mr-2">
          <div className={`${styles.button} ! bg-[#E34234] mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Like/ Follow
               </span>
          </div>
      </Link>

      <Link to="/videotasks" className="inline-block">
          <div className={`${styles.button} !bg-[teal] 800px:ml-0 mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Watch Videos
               </span>
          </div>
      </Link>
             </di>

             <br />
             <hr />
             <br />

        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {taskByCountry && taskByCountry.map((i, index) => <SurveyCard data={i} key={index} userDoingTask={userDoingTask} />)}
        </div>
        {taskByCountry && taskByCountry.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No survey found!
          </h1>
        ) : null}
      </div>
        ) : (
          <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
            Access Denied! Only Workers can perform task.
          </h1>
          )
      }
      <Footer />
    </div>
    )
  }
  </>
  )
};

export default SurveyPage;
