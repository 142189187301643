export const server = "https://totlesoftpay-backend.vercel.app/api/v2";

//  http://localhost:8000/api/v2
// https://totlesoftpay-backend.vercel.app/api/v2

//grey #F6F6F5
// default youtube video ID kGKQF5krIdM

// totlesoft yellow color code :  bg-[#c89b2a]

// Personal Paypal test
// email: sb-ol76x39344872@personal.example.com
// p/w: 8B+%Fw1.

//VISA
//4032034148155750
//04/2030
//123

//Billing address
//1 Main St
//San Jose, CA 95131

// business test 
// email: sb-voqoq39402821@business.example.com
// p/w: F+6g05N.

// https://console.cloud.google.com/

// 388943 for freelancersu1978@gmail.com
// 455119 totlesoft@gmail.com
// 123456 freelancersu1970b@gmail.com
// cpagrip aff link: https://www.cpagrip.com/signup.php?ref=2316937\
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFZF4-No5PTrJEfnxYBMPF1tWu1AC8HBTDGdEU2KJNVx9O_mHvB5vVO2wGNvi1k5Z9RKw&usqp=CAU


/*

There are several hot-selling digital products that you can promote as an affiliate to potentially earn daily commissions. Here's a list of the top digital products with broad global appeal:

### 1. **Online Courses and E-Learning Platforms**
   - **Udemy**: A global marketplace for online learning. You can promote courses in various niches like tech, design, business, etc. Udemy offers affiliate commissions for every sale made through your referral.
   - **Teachable**: A platform where creators can sell their own courses. You can promote courses or even affiliate with Teachable itself.
   - **Skillshare**: Offers an affiliate program where you can promote their membership and earn commissions for referrals.

### 2. **E-books & Digital Books**
   - **Amazon Kindle Direct Publishing (KDP)**: Promote Kindle e-books or self-published books through Amazon's affiliate program.
   - **BookBooster**: A platform with digital books. You can promote e-books related to popular topics like self-help, business, or productivity.

### 3. **Software and SaaS (Software as a Service) Tools**
   - **ClickFunnels**: A leading funnel-building tool for marketers. Its affiliate program offers generous commissions and recurring income.
   - **Shopify**: Promote the world-renowned e-commerce platform and earn commissions from merchants who sign up through your referral.
   - **SEMrush**: A popular SEO tool that affiliates can promote. It has an affiliate program that pays recurring commissions.
   - **Grammarly**: A writing assistant tool with a strong affiliate program. You can promote it to writers, students, and professionals worldwide.

### 4. **Web Hosting and Domain Services**
   - **Bluehost**: One of the most popular web hosting services. Affiliates can earn significant commissions for referring new customers.
   - **HostGator**: Another hosting platform with a great affiliate program.
   - **SiteGround**: Known for its excellent customer service and hosting plans, this platform also offers competitive affiliate commissions.

### 5. **VPN Services**
   - **ExpressVPN**: With increasing concerns about online privacy, VPNs are very popular. ExpressVPN offers a strong affiliate program with great commissions.
   - **NordVPN**: A well-known VPN provider, offering high commissions and attractive recurring payments.

### 6. **Stock Photos, Videos, and Graphics**
   - **Envato Market**: A marketplace for digital assets like stock photos, videos, graphics, themes, etc. Their affiliate program pays a percentage of each sale.
   - **Shutterstock**: A widely recognized platform for stock images and videos. They offer an affiliate program where you can earn commissions by referring customers.
   
### 7. **Health and Fitness Products**
   - **MyFitnessPal**: A popular health app for tracking food and exercise. They offer an affiliate program where you can promote their premium subscriptions.
   - **Beachbody**: Promote fitness programs and supplements. This platform has an affiliate program with strong earning potential.

### 8. **Digital Marketing Tools**
   - **ConvertKit**: A tool for email marketing that provides an affiliate program. You can earn recurring commissions for referring users.
   - **AWeber**: Another email marketing platform with a robust affiliate program offering recurring income.
   - **Leadpages**: A landing page builder with a great affiliate program, paying high commissions for each sale.

### 9. **Subscription Boxes**
   - **Cratejoy**: An affiliate program for subscription boxes. You can promote various digital and physical subscription boxes in niches like beauty, books, fitness, and more.

### 10. **Video and Music Streaming Platforms**
   - **Spotify**: Promote premium memberships or gift cards. As an affiliate, you can earn commissions on new sign-ups.
   - **Apple Music**: Promote music subscriptions and digital albums to global audiences.

### 11. **Photography Equipment & Software**
   - **Adobe Creative Cloud**: Promote popular Adobe tools like Photoshop, Illustrator, and Premiere Pro through Adobe's affiliate program.
   - **Canva**: A design tool that's growing rapidly. The affiliate program offers good commissions for promoting premium subscriptions.

### 12. **Online Gambling and Casinos**
   - **Casino Affiliates**: If you're in a region where online gambling is legal, you can promote online casino affiliate programs. These typically offer high payouts.
   - **Sports Betting**: Affiliates for sports betting platforms can earn high commissions for new sign-ups and wagers placed.

### 13. **Music Production Software**
   - **Splice**: A platform offering music production software and samples. They have an affiliate program for creators and musicians.
   - **Ableton**: A popular music production software with an affiliate program for those in the music industry.

### 14. **Mobile Apps**
   - **AppSumo**: A platform for software deals, often featuring mobile apps. They offer high affiliate payouts for digital product sales.
   - **Google Play Store & Apple App Store**: Promote mobile apps like productivity tools, games, or utility apps and earn commissions through their affiliate programs.

### Tips to Increase Affiliate Earnings:
1. **Targeting the Right Audience**: Focus on specific niches where demand is high.
2. **Create Quality Content**: Blog posts, videos, and reviews that promote these digital products can attract more clicks and conversions.
3. **Use Social Media**: Platforms like Instagram, YouTube, and TikTok are great for promoting digital products to a global audience.
4. **Email Marketing**: Build an email list and promote affiliate products to your subscribers.
5. **Track Conversions**: Use analytics to find which products or platforms perform the best and focus your efforts on those.

If you’re consistent with your promotions and focus on the products that resonate with your target audience, you could make a good income from affiliate marketing. Just be sure to join affiliate networks like **ShareASale**, **CJ Affiliate**, and **Rakuten Marketing** to find even more affiliate programs to join!

*/