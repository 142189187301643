import axios from "axios";
import { server } from "../../server";

// load seller
export const loadSeller = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSellerRequest",
    });

    // Retrieve the JWT token from localStorage
    const sellerToken = localStorage.getItem('seller_Token');

    // If no token is found, dispatch failure and return
    if (!sellerToken) {
      dispatch({
        type: "LoadSellerFail",
        payload: "No token found. Please log in.",
      });
      return;
    }

    // Make a request to the backend to get seller info, attaching the token to Authorization header
    const { data } = await axios.get(`${server}/shop/getSeller`, {
      headers: {
        Authorization: `Bearer ${sellerToken}`, // Attach token in the Authorization header
      },
    });

    // Dispatch success action with seller data
    dispatch({
      type: "LoadSellerSuccess",
      payload: data.seller,
    });
  } catch (error) {
    dispatch({
      type: "LoadSellerFail",
      payload: error.response ? error.response.data.message : "Error loading client",
    });
  }
};

// get all sellers --- admin
export const getAllSellers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllSellersRequest",
    });

    const { data } = await axios.get(`${server}/shop/admin-all-sellers`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllSellersSuccess",
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: "getAllSellerFailed",
      payload: error.response.data.message,
    });
  }
};

// get all sellers --- Open
export const getAllSellersOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllSellersOpenRequest",
    });

    const { data } = await axios.get(`${server}/shop/open-all-sellers`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllSellersOpenSuccess",
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: "getAllSellerOpenFailed",
      payload: error.response.data.message,
    });
  }
};
