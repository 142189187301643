import React from 'react';
import kCard from '../Assests/images/kohlcard.png';
import "./flippingImage.css";

const FlippingImage = () => {
  return (
    <div className="flex justify-center items-center w-full px-4 py-2">
      <div className="animate-flip">
        <img 
          src={kCard} 
          width={200} 
          height={140}
          alt="" 
          className="object-cover"
        />
      </div>
    </div>
  );
};

export default FlippingImage;