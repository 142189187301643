import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../components/Layout/Header";
//import Footer from "../../components/Layout/Footer";
import { useSelector } from "react-redux";
import { server } from "../../server";
import { Link } from "react-router-dom";
import TestButton from "./TestButton";

const USEnglishTest = () => {
  const { user } = useSelector((state) => state.user);
  const [questions, setQuestions] = useState([]);
  const [tests, setTests] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(300); // 15 minutes in seconds
  const [score, setScore] = useState(0);
  const [status, setStatus] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(true);  // Add a loading state to manage loading UI
  const [canTakeTest, setCanTakeTest] = useState(false);

  const userId = user?._id;
  const email = user?.email;
  const ntestId = "67ec21018f8079d6cb27a11e";  // Replace with actual test ID if necessary

  useEffect(() => {
    // Fetch all tests
    axios
      .get(`${server}/test/get-all-tests`, {
        withCredentials: true,
      })
      .then((res) => {
        setTests(res.data.tests);
        console.log("Tests fetched:", res.data.tests);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []); // Run only once when the component mounts

  useEffect(() => {
    if (tests.length > 0) {
      const test = tests.find((test) => test._id === ntestId);
      if (test) {
        setQuestions(test.questions);
        setLoading(false); // Data is loaded, stop the loading spinner/message
      }
    }
  }, [tests, ntestId]); // Re-run when tests or testId changes



  // Timer logic
  useEffect(() => {
    if (questions.length === 0 || loading) return; // Wait for the questions to be loaded

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          handleSubmit(); // Automatically submit if time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on unmount or when the effect runs again
  }, [questions, loading]); // Ensure this effect runs when questions are loaded

  const handleAnswerChange = (selectedAnswer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = selectedAnswer;
    setAnswers(updatedAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const ntest = tests.find((tst) => tst._id === ntestId);

  const testId = ntest?._id;
  
  const handleSubmit = async () => {
    if (submitted) return;
    setSubmitted(true);

    // Calculate score
    let correctAnswers = 0;
    questions.forEach((question, index) => {
      if (answers[index] === question.correctAnswer) {
        correctAnswers += 1;
      }
    });
    const calculatedScore = (correctAnswers / questions.length) * 100;
    setScore(calculatedScore);

    // Calculate the status message
    const calculatedStatus = calculatedScore >= 80 ? 'Congratulations! You Passed.' : 'Sorry! You Failed';
    setStatus(calculatedStatus); // Update state with status

    // Submit the result to the server
    try {
        console.log('Submitting Test');
        console.log('Test ID:', testId);
        console.log('User:', userId, email);
        console.log('Score:', calculatedScore);
        console.log('Status:', calculatedStatus);  // Directly log the calculated status here
      
        const response = await axios.post(`${server}/test/submit`, {
          userId,
          email,
          score: calculatedScore,
          status: calculatedStatus,  // Use calculatedStatus here
          testId: testId
        });
        console.log('Test submitted successfully:', response.data);
    } catch (error) {
        console.error('Error submitting test:', error);
    }
};

        const tuserId = userId;
        const temail = email;
        const ttestId = testId;

  return (

    <>

    {canTakeTest ? (
        <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
        <h1 className="text-3xl font-bold text-center text-teal-600 mb-6">English-US Test</h1>
  
        {/* Timer */}
        <p className="text-xl text-center mb-6">
          <span className="font-medium">Time Left:</span> 
          {Math.floor(timeLeft / 60)}:{timeLeft % 60}
        </p>
  
        {/* Questions */}
        {loading ? (
          <p className="text-center text-gray-500">Loading questions... Please wait.</p>
        ) : (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-gray-800">{questions[currentQuestionIndex].text}</h3>
  
            {/* Options */}
            <div className="space-y-4">
              {questions[currentQuestionIndex].options.map((option, i) => (
                <div key={i} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id={`question-${currentQuestionIndex}-option-${i}`}
                    name={`question-${currentQuestionIndex}`}
                    value={option}
                    checked={answers[currentQuestionIndex] === option}
                    onChange={() => handleAnswerChange(option)}
                    className="h-5 w-5 text-teal-600 focus:ring-teal-500"
                  />
                  <label
                    htmlFor={`question-${currentQuestionIndex}-option-${i}`}
                    className="text-lg text-gray-700 cursor-pointer"
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
  
            {/* Next Question / Submit Button */}
            <div className="text-center mt-4">
              {currentQuestionIndex < questions.length - 1 ? (
                <button
                  onClick={handleNext}
                  className="px-6 py-3 bg-teal-500 text-white font-semibold rounded-lg hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 transition duration-300"
                >
                  Next Question
                </button>
              ) : (
                  <>
                <button
                  onClick={handleSubmit}
                  className="px-6 py-3 bg-teal-600 text-white font-semibold rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-400 transition duration-300"
                >
                  Submit Test
                </button>
  
                  <Link to="/profile">
                  <button 
                  className="ml-3 px-6 py-3 bg-teal-600 text-white font-semibold rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-400 transition duration-300"
                  >
                  Return
                  </button>
                  </Link>
                  </>
              )}
            </div>
          </div>
        )}
  
        {/* Result / Status */}
        {submitted && (
          <div className="mt-8 text-center">
            <h2 className="text-2xl font-semibold text-gray-800">{status}</h2>
            <p className="text-xl text-teal-500">Score: {score}</p>
          </div>
        )}              
        
      </div>
    ) : (
        <>
        <Header />
        <br />
        <TestButton 
        userId={tuserId}
        email={temail}
        testId={ttestId}
        setOpenn={setCanTakeTest}
        />
        <br />
       
        </>
    )}

    </>

  );
};

export default USEnglishTest;