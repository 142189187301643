

// navigation Data
export const navItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Marketplace",
      url: "/products",
    },
    {
      title: "gigs",
      url: "/talents/gigs",
    },
    {
      title: "Academy",
      url: "/academy",
    },
    
    {
      title: "Events",
      url: "/ads/events",
    },
    {
      title: "CPA-Offers",
      url: "/cpaoffers",
    },
    {
      title: "Games",
      url: "/games",
    },
    ];


  // Our Service Categories data
  export const serviceCategoriesData = [
    {
      id: 1,
      title: "Art/Graphics",
      subTitle: "",
      href: "/graphics",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 2,
      title: "Branding",
      subTitle: "",
      href: "/branding",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 3,
      title: "Social Media Marketing",
      subTitle: "",
      href: "/social-media-marketing",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 4,
      title: "Web/Mobile App Dev.",
      subTitle: "",
      href: "/web-and-mobile-app-dev",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 5,
      title: "Product Testing",
      subTitle: "",
      href: "/product-testing",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 6,
      title: "Transcription",
      subTitle: "",
      href: "#",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 7,
      title: "Data Analytics",
      subTitle: "",
      href: "#",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 8,
      title: "Freelance Services",
      subTitle: "",
      href: "#",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
  ];


  // Freelance Categories data
  export const freelanceCategoriesData = [
    {
      id: 1,
      title: "Art/Painter",
      },
      {
        id: 2,
        title: "UI/UX Design",
      },
      {
        id: 3,
        title: "Copywriter",
      },
      {
        id: 4,
        title: "SEO Expert",
      },
      {
        id: 5,
        title: "Photographer",
      },
      {
        id: 6,
        title: "Accountant",
      },
      {
        id: 7,
        title: "Human Resource Manager",
      },
      {
        id: 8,
        title: "Public Relations Executive",
      },
      {
        id: 9,
        title: "Videographer",
      },
      {
        id: 10,
        title: "Programmer",
      },
      {
        id: 11,
        title: "Social Media Specialist",
      },
      {
        id: 12,
        title: "Typist/Data Entry",
      },
      {
        id: 13,
        title: "Transcriber/Transcriptionist",
      },
      {
        id: 14,
        title: "Event Manager",
      },
      {
        id: 15,
        title: "Cyber Security Expert",
      },
      {
        id: 16,
        title: "Business Consultant",
      },
      {
        id: 17,
        title: "Graphic Designer",
      },
      {
        id: 18,
        title: "Instrumentalist",
      },
      {
        id: 19,
        title: "Business Developer",
      },
      {
        id: 20,
        title: "Engineer",
      },
      {
        id: 21,
        title: "Nurse",
      },
      {
        id: 22,
        title: "Doctor",
      },
      {
        id: 23,
        title: "Others",
      },
    
  ];
  
    
  // General categories data
  export const categoriesData = [
    {
      id: 1,
      title: "Graphics Design",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 2,
      title: "Branding",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 3,
      title: "Social Media Marketing",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 4,
      title: "Web/Mobile App Dev.",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 5,
      title: "Product Testing",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 5,
      title: "Social Media Evaluation",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 6,
      title: "Academic Help",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 7,
      title: "Proofreading",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 8,
      title: "Translation",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 9,
      title: "Video Editing",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 10,
      title: "Purchase Assistance",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 11,
      title: "Tech Assistance",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Manual Labour",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Payments",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Transcription",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Freelance Services",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 13,
      title: "Video Promo",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 14,
      title: "Survey",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 14,
      title: "Audio Promo",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 15,
      title: "Data Entry",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 16,
      title: "App Developement",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 17,
      title: "Web Developement",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 18,
      title: "Software",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 19,
      title: "Course",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 20,
      title: "Free",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 21,
      title: "Electronics",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 22,
      title: "Fashion",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 23,
      title: "Tools",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 24,
      title: "Cusmetics",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 25,
      title: "Beauty",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 26,
      title: "Costumes",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 27,
      title: "Bags",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 28,
      title: "Shoes",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 29,
      title: "Pest Control",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 30,
      title: "Herbal Drugs",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 31,
      title: "Eye Glasses",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 32,
      title: "Clothes",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 33,
      title: "Tickets",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 34,
      title: "Art/Paintings",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
  ];
   
  
  export const footerProductLinks = [
    {
      name: "About us",
      link: "/"
    },
    {
      name: "Academy",
      link: "academy"
    },
    {
      name: "Careers",
      link: "/career"
    },
    {
      name: "Graphics",
      link: "/graphics"
    },
    {
      name: "Branding",
      link: "/branding"
    },
    {
      name: "Social Media Marketing",
      link: "/social-media-marketing"
    },
    {
      name: "Transcription",
      link: "/transcription"
    },
    {
      name: "App/Web Development",
      link: "/web-and-mobile-app-dev"
    },
    {
      name: "Product Testing",
      link: "/product-testing"
    },
    {
      name: "Totlesoft Foundation",
      link: "/totlesoft-foundation"
    },
    {
      name: "Marketplace",
      link: '/products'
    },
    {
      name: "mine eTST",
      link: "https://www.etstcoin.lol"
    }
    ];
  
  export const footercompanyLinks = [
    {
      name: "Events",
      link: '/ads/events'
    },
    {
      name: "Cheap Airtime/Data",
      link: 'https://sabuss.com/totlesoftvtu3'
    },
    {
      name: "Rewards",
      link: "/rewards-earning-structure"
    },
    
    
  ];
  
  export const footerSupportLinks = [
    {
      name: "Help",
      link: "/faq"
    },
    {
      name: "Resource Center",
      link: "/resource-center"
    },
    {
      name: "Contact Us",
      link: "/contact"
    },
    {
      name: "Terms of Service",
      link: "/terms-of-service"
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy"
    },
    {
      name: "Data Deletion",
      link: "/data-deletion"
    },
    {
      name: "AML/CFT Policy",
      link: "/aml-policy"
    },
    {
      name: "API",
      link: "#"
    },
  ];
  
