import React, { useState, useEffect } from "react";
import styles from "../styles/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { server } from "../server";
import axios from "axios";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";


const RemovingWorkerPage = () => {
    const { allSTasks } = useSelector((state) => state.stasks);
   const { id, workaId } = useParams();
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const wID = workaId;
           
       
        useEffect(() => {
          const data = allSTasks && allSTasks.find((i) => i._id === id);
        setData(data);
        
        }, [allSTasks, id]);

        let workerData = [];
        
        workerData = data && data?.workers.find((i) => i.workerId === workaId);

        let appliData = [];
        
        appliData = data && data?.applicants.find((it) => it.applicantId === wID);
                 
        
        const removeHandler = async () => {
          const workerEmail = workerData?.workerEmail;
          const workaID = workerData?.workerId;

          console.log("projectId: ", id, workerEmail, workaID);
        
          if (!workaID || !workerEmail) {
            toast.error("Missing worker information!");
            return;
          }
        
            await axios
            .put(
              `${server}/stask/remove-worker-from-project/${id}`,  // Ensure this URL matches the backend route
              {
                workerId: workaID,
                workerEmail: workerEmail
              },
              { withCredentials: true }
            )
            .then((res) => {
              toast.success("Worker removed from project successfully!");
              navigate('/dashboard-s-tasks');
              window.location.reload();
            })
            .catch((error) => {
              console.error("Error removing worker:", error.response?.data || error);
              toast.error(error.response?.data?.message || "An error occurred while removing the worker");
            });
          };
        
        
      return (
        <div>
          <Header />
         {data ? 
          <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500] text-[red]">Removing Worker From Project</h1><br />
  
          <img
            src={`${appliData?.applicantAvatar?.url}`}
            className="w-[70px] h-[70px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
            alt=""
                />
            <h4 className="pb-3 font-[400] text-[15px]">
            Name: {appliData.applicantName}
            </h4>
  
            <h4 className="pb-3 font-[400] text-[15px]">
            Location: {appliData.applicantLocation}
            </h4>
            
            <h4 className="pb-3 font-[400]">
            E-mail: {appliData.applicantEmail}
            </h4>
            <br />
            <hr />
            <br />
  
          <p>By Clicking the "Remove" button you will be removing {appliData?.applicantName} from the project: <br /> 
          <span className="text-[25px] font-[600]">{data?.name}</span>
          </p>
          <div className={`${styles.button} !bg-[red] mt-5 cursor-pointer`}
            onClick={removeHandler}
            >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                 Remove
                 </span>
            </div>
          </div>
         : 
          <div className="w-full py-5 px-10">
            <h1 className="text-[30px] text-[500]">Ops! Data Loading failed!</h1>
            <p>Please try again later.</p>
          </div>
       }
       <Footer />
        </div>
      );

     }

export default RemovingWorkerPage;
