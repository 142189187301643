import React, { useState } from 'react'
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import styles from "../../styles/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
//import TestClip from "../../Assests/Audio/testclip.mp3"
import TestImg from "../../Assests/images/designImg1.jpg"


const GraphicTestPage = () => {
   
  return (
    <div>
        <Header />
        <GraphicTests />
        
        <Footer />
    </div>
  )
}

const GraphicTests = () => {
    const { user } = useSelector((state) => state.user);
    const [active, setActive] = useState(1);
    const [openTest1, setOpenTest1] = useState(false);
    const [openTest2, setOpenTest2] = useState(false);
    const [openTest3, setOpenTest3] = useState(false);
    const [openTest4, setOpenTest4] = useState(false);
    const [openTest5, setOpenTest5] = useState(false);
    const [openTest6, setOpenTest6] = useState(false);
    const [openTest7, setOpenTest7] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const testMessage = () => {
        toast.error("Only Workers can access this!")
    }


    return (
        <>
    <div className="mt-1 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>

      
        <h1 className={`text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}>
          Graphic De<span className="text-[#c89b2a]">sign</span> Tests
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Find all the instruction and guide needed to begin your journey on Totlesoft as a Graphic Designer.
        </p>
        <br />

        <div className="w-full flex justify-between pt-2 pb-2">
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(1)}
            >
              Overview
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(2)}
            >
              Requirements
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>        


          <div className="relative">
            <h5
              className={
                "text-[#000] text-[15px] px-1 leading-5 font-[500] cursor-pointer 800px:text-[16px]"
              }
              onClick={() => setActive(3)}
            >
              Test 
            </h5>
            {active === 3 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
        </div>
        
        {active === 1 ? (
        <div className="w-full bg-white px-4 py-2">
          <h5 className="mt-3 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          Welcome to Totlesoft: Empowering Graphic Designers to Thrive in the Freelance World
          </h5>

            <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
            Totlesoft is a platform where your creative skills meet opportunity. We’re thrilled to have you here, as we bring together talented graphic designers like you with exciting freelance projects. Whether you’re an expert in visual design, web graphics, branding, or illustration, Totlesoft is here to connect you with clients who appreciate your unique talents.
            </p>
            <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
            At Totlesoft, we believe in empowering designers to take control of their careers and work from the comfort of their own home. Our platform offers you the flexibility to choose projects that align with your skills, interests, and schedule—giving you the opportunity to grow your portfolio, work with diverse clients, and earn a competitive income.
            </p>
            <br />

            <h5 className="mt-3 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
            Why Join Totlesoft?
          </h5>

          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          When you join Totlesoft, you're not just signing up for freelance work—you're joining a community that supports your growth and success. Here’s what you’ll benefit from by being part of our platform:
          </p><br />

          <h5 className="text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          1. Access to a Wide Range of Projects
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          Totlesoft connects you with a diverse set of clients—from startups to established brands—offering a variety of projects that will keep your work fresh and challenging. Whether you specialize in logo design, branding, UI/UX, or digital illustrations, we’ve got opportunities that suit your skillset.
          </p>

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          2. Flexible Work-from-Home Opportunities
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          Enjoy the freedom to work from anywhere. Totlesoft is a fully remote platform, allowing you to set your own hours and work in a way that fits your lifestyle. This flexibility is ideal for creative professionals who value both independence and variety in their work.
          </p>

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          3. Competitive Pay & Timely Payments
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          We understand the value of your work. Totlesoft ensures competitive pay rates, and we make sure that payments are processed in a timely manner. No delays, no hassle—just the compensation you deserve for your skills and creativity.
          </p>

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          4. Skill Development and Growth
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          As a member of Totlesoft, you will have access to resources and a community of fellow designers. Learn from others, share ideas, and continue honing your skills to stay ahead of trends in the ever-evolving design world.
          </p>

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          5. A Collaborative, Supportive Community
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          At Totlesoft, you’re not alone. Join a community of passionate professionals who share your love for design. Whether it’s sharing tips, seeking feedback, or collaborating on larger projects, you’ll find a supportive network that helps you thrive.
          </p>
          <br />

          <h5 className="text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          The Graphic Design Test: Your First Step to Success
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          To ensure that you’re matched with the best opportunities and that clients receive the highest quality work, we require all graphic designers to complete a brief Graphic Design Test. This test is designed to assess your skills, creativity, and problem-solving abilities. But don’t worry—it’s not just about evaluating your technical proficiency. We want to understand how you approach design challenges, communicate your ideas, and deliver results.
          </p>
          <br />

          <h5 className="mb-2 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          Here’s what to expect from the test:
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          * Creative Challenges: You will be asked to complete one or more design tasks that mimic real-world project requirements. This may include creating logos, digital illustrations, or mockups for web pages or marketing materials.
          </p><br />

          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          * Time Management: The test will be time-bound, giving us a sense of how efficiently you can work under deadlines, a critical aspect of freelancing.
          </p><br />

          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          * Design Process: We value your creative process. You will be asked to submit not only the final design but also any preliminary sketches, wireframes, or mood boards that demonstrate how you arrived at your solution.
          </p><br />

          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          * Technical Skills: Expect to showcase your proficiency in the tools and software commonly used in graphic design (e.g., Adobe Creative Suite, Sketch, Figma). You’ll need to display your ability to create high-quality, professional designs with attention to detail.
          </p><br />

          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          * Attention to Briefs: Understanding and adhering to client briefs is key. The test will assess how well you follow instructions and align your work with the requirements provided.
          </p><br />

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">NOTE:</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* This is an Home-based job, you work at your convenience</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Pay rate, $1 to $25 depending on the job</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Job can be rejected by client and it will count against you.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Up-voting: Client may up-vote you base on your good job, expossing you to more higher paying jobs</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Utilize our Free graphic resourse on demand, to save around 65% of creative time</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* You will be promoted for good performance; (grow from a New-B to Pro Designer)</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* You may get upto $30 bonus for completing 500+ jobs within a time frame. Visit <Link to="https://totlesoft.com/rewards-earning-structure" target='_blank' className='text-[blue] cursor-pointer'>Rewards</Link> to learn more about Totlesoft Rewards.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* No obligations and no monthly commitments</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Totlesoft don't assign jobs, unless on a special occasion, you get to pick job to work on base on your capacity.</p>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">* Sometimes there may be no job on your Market (base on your country/locaton). In such occasion you just have to wait or refresh the page or come back after a while.</p>
            <br />

            <p className="text-[16px] font-[Poppins] text-[#000000ba]">
            Once you complete the test, our team will review your submission, and based on your performance, you will be either approved to start accepting tasks or receive feedback on areas for improvement. 
            </p><br />

            <h5 className="text-[16px] font-[500] font-[Poppins] text-[#000000ba]">Ready to Get Started?</h5>
            <p className="text-[16px] font-[Poppins] text-[#000000ba]">
            By completing the test and joining Totlesoft, you’re opening the door to a world of freelance design opportunities. We look forward to seeing your creativity and expertise in action and are excited to support you as you take your graphic design career to the next level.
            </p>
            <br />

            <p className="text-[16px] font-[Poppins] text-[#000000ba]">
            Welcome to Totlesoft, where your design journey begins!
            </p>
            <br />
            <br />
            <br />
            <br />
            

        </div>
        ) : null}
  
        {active === 2 ? (
        <div className="w-full bg-white px-4 py-2">
        <p className="text-[16px] font-[Poppins] text-[#000000ba]">
        These requirements will help us assess both the technical skills and creativity of candidates, ensuring they are ready to take on real-world freelance projects.  
        </p>
        <br />

        <h5 className="text-[16px] font-[600] font-[Poppins] text-[#000000ba]">Possible Requirements for the Graphic Design Test</h5>

        <p className="mt-2 text-[16px] font-[600] text-[#000000ba]">0. Know how to store file on google drive and sharing the link to the file.</p>
        <br />

        <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          1.  Portfolio Submission
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>A link to or upload of your design portfolio showcasing at least 5 to 10 of your best works.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Provides an overview of the candidate’s style, versatility, and design skills. The portfolio should include various types of work (e.g., logos, web design, branding, print materials).
          </p>
          <br />


          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          2. Design Brief Response
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Complete a design task based on a fictional or real-world client brief. The brief will include a description of the project, target audience, goals, and any other relevant information (e.g., logo design, branding, web page design).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Tests the candidate's ability to interpret a brief and deliver a design solution that aligns with the client’s needs.
          </p>
          <br />



          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          3. Creative Process Documentation
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Submit the design process, including sketches, mood boards, wireframes, or concept explorations, in addition to the final design.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Shows the candidate’s approach to design, critical thinking, and problem-solving. It also demonstrates the candidate's ability to refine and evolve their ideas.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          4. Software Proficiency
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Proficiency in industry-standard design tools (e.g., Adobe Illustrator, Photoshop, InDesign, Figma, Sketch, etc.). Candidates may need to demonstrate their skills by submitting editable files (e.g., .AI, .PSD, .INDD).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Ensures that the candidate can deliver files in formats that clients can work with, and that they are proficient in tools commonly used in freelance design work.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          5. Typography and Color Theory
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Create a design that demonstrates proper use of typography and color theory (e.g., a logo, poster, or web page design).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Shows that the designer understands basic principles of typography (e.g., font pairing, readability) and color theory (e.g., color harmony, contrast, branding colors).
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          6. Logo Design Task
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Create a logo based on a brief with specific design guidelines (e.g., minimalistic, modern, vintage, or abstract).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Logo design is a critical skill for graphic designers, and this test evaluates the candidate's ability to create memorable, scalable, and versatile logos.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          7. Web Design / UI Mockup
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Design a basic web page or user interface (UI) based on a given brief. Candidates should demonstrate layout, typography, visual hierarchy, and usability.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Web design is an essential skill for many graphic designers. This requirement tests the candidate’s ability to create user-friendly and visually appealing designs for digital platforms.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          8. Responsive Design
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Create a responsive design mockup for a website, showing how it would appear on desktop, tablet, and mobile devices.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Understanding responsive design is crucial for designers working in today’s multi-device landscape. This requirement tests the candidate’s ability to design adaptable layouts.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          9. Branding / Visual Identity
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Develop a complete branding package for a hypothetical company, including a logo, color palette, business card, and letterhead design.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Evaluates a designer’s ability to build a cohesive visual identity that represents a brand effectively across different mediums.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          10.  Social Media Graphics
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Design a set of social media graphics for a brand, including banners, profile images, and post templates (e.g., for Facebook, Instagram, LinkedIn).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Social media design is a key area for freelance graphic designers, and this task assesses the candidate’s ability to design for different platforms and formats.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          11. Print Design Task
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Design a print material, such as a flyer, brochure, or poster, following a brief with specific dimensions and print-ready requirements.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Ensures that candidates understand print production standards, such as bleed areas, color modes (CMYK), and resolution (300 DPI).
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          12. Creativity and Originality
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Demonstrate creativity by submitting a unique, original design (e.g., a conceptual illustration or a rebranding proposal for an existing brand).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>This tests a designer’s originality and innovation—two essential qualities in the competitive world of freelance design.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          13. Attention to Detail
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Submit a design with a specific focus on attention to detail, such as alignment, consistency, spacing, and file organization.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Freelancers are often expected to produce work that is polished and professional, with attention to even the smallest details. This tests their ability to create high-quality designs.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          14. Time Management
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Complete a set number of tasks within a specified time frame (e.g., designing a logo, creating a social media post, and adjusting a layout in 2-3 hours).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Freelancers often work on tight deadlines. This requirement tests the candidate’s ability to work efficiently without sacrificing quality.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          15. File Organization & Delivery
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Submit files in an organized manner, including properly named layers, folders, and any necessary supporting documents (e.g., fonts, color codes, references).
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Clients expect clear, organized file submissions, especially for large projects. This checks how well the candidate follows best practices for file management.
          </p>
          <br />

          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          16. Client Communication Simulation
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Simulate a client feedback scenario by adjusting a design based on constructive feedback or revisions.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>In the freelance world, designers need to be able to accept and act on client feedback, refining their designs as necessary to meet client expectations.
          </p>
          <br />


          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          17. Adherence to Brand Guidelines
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Requirement: </span>Design something (e.g., a flyer, social media post, or banner) using an existing brand's guidelines, ensuring consistency with the brand’s voice, colors, and typography.
          </p>

          <p className="mt-2 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Why: </span>Many freelance projects require the designer to work within established brand guidelines. This tests the candidate’s ability to follow specific instructions and maintain brand integrity.
          </p>
          <br />


          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          Additional Optional Requirements (Optional for Further Assessment)
          </h5>
          <p className="text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Speed Test: </span>A timed design challenge to evaluate how quickly a graphic designer can produce quality work under pressure.
          </p>

          <p className="mt-4 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Design Feedback: </span>In some occasion, Totlesoft may ask the designer to critique a piece of their own work or a given design. This helps us to assess their ability to evaluate design quality.
          </p>

          <p className="mt-4 text-[16px] font-[Poppins] text-[#000000ba]">
          <span className='font-[600]'>Problem-Solving Task: </span>This is a complex, open-ended design task where the designer must come up with creative solutions to a challenging problem.
          </p>
          <br />

        
        <br />
        <p className="text-[16px] font-[600] font-[Poppins] text-[#000000ba]">The Totlesoft Tech Team.</p>

        <br />
        <br />
        

    </div>
        ) : null}

        
        {active === 3 ? (
        <div className="w-full bg-white px-4 py-2">

        <p className="text-[16px] font-[600] font-[Poppins] text-[#000000ba]">Totlesoft Graphic Design Test</p>
        <br />
        <p className="text-[16px] text-[#000000ba]">Welcome to the Totlesoft Graphic Design Test! This test is designed to assess your skills, creativity, and approach to design. Please read each section carefully and complete the tasks within the specified guidelines..</p>
        <br />

        <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
        Submission Guidelines:
          </h5>
          <p className="text-[16px] text-[#000000ba]">
          <span className='font-[600]'>Deadline: </span>The test should be completed within 5 days from the time you clicked 'Start'
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>File Formats: </span> Submit files in editable formats (AI, PSD, INDD) where applicable, and PNG/JPG/PDF for presentation files.
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>Total Number of Submissions: </span>Max submisions 3. Ensure that you submit each task as a single, cohesive folder or zip file to make it easy for us to review.
          </p>
          <br />


          <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
          Evaluation Criteria:
          </h5>
          <p className="text-[16px] text-[#000000ba]">
          <span className='font-[600]'>* Creativity & Originality: </span>How unique and innovative are your design concepts?
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>* Adherence to Brief: </span> Did you meet the requirements outlined in the design brief (e.g., brand values, target audience, style)?
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>* Design Process: </span>How well did you document and explain your design process and decisions?
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>* Technical Proficiency: </span>Are your files correctly formatted, high-quality, and technically sound?
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>* Attention to Detail: </span>Did you follow best practices for typography, layout, and file organization?
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          <span className='font-[600]'>* Time Management: </span>Did you complete the tasks within the specified time frames?
          </p>
          <br />       
        
        <br />



        <h5 className="mt-4 text-[18px] font-[600] font-[Poppins] text-[#000000ba]">
        File Organization and Delivery:
          </h5>
          <p className="text-[16px] text-[#000000ba]">
          Ensure that you follow best practices for file management.
          </p>

          <p className="mt-4 text-[16px] text-[#000000ba]">
          Submit all your design files from the test in an organized manner. This includes:
          </p>

          <p className="mt-4 text-[16px] font-[600] text-[#000000ba]">
          * Properly named layers in your files.
          </p>

          <p className="mt-4 text-[16px] font-[600] text-[#000000ba]">
          * Grouped elements where applicable (e.g., logo elements, text, images).
          </p>

          <p className="mt-4 text-[16px] font-[600] text-[#000000ba]">
          * A folder with separate subfolders for different design assets (e.g., logo, social media graphics, print materials).
          </p>

          <p className="mt-4 text-[16px] font-[600] text-[#000000ba]">
          * Deliverables: Submit your final files in editable formats (.PSD, .AI, .INDD) and any rasterized versions (.JPG, .PNG). Ensure the files are well-organized and easy to navigate.
          </p>
        <br />       
        
        <br />

            <h5 className="text-[20px] font-[600] mb-2">AVAILABLE TESTS:</h5>
            <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 1 - Portfolio Submission</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest1(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 2 - Logo Design</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest2(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 3 - Poster Design</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest3(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 4 - Branding Package</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest4(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 5 - Social Media Graphics</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest5(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>

              <div className="bg-[black] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 6 - Design Challenge</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest6(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Test 7 - Design Feedback Simulation</h5>
              {user && user?.role === "Worker" ? (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenTest7(true)}>Start</h5>
              ) : (
                <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>Start</h5>
              )}
              </div></div>
              
                           
              
              <br />
              <br />
        </div>
        ) : null}

       <br />



       {openTest1 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest1(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Showcase your best design work.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
        Showcase your best design work (Direct Recommendation).
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Please provide a link to your portfolio. It should include at least 5 to 10 pieces of work that demonstrate a variety of skills, including logo design, branding, print materials, or digital assets.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Guidelines: </span>Ensure that your portfolio reflects your personal style, creativity, and attention to detail. The projects should be clear and easy to understand.
      </p>
      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)} 




{openTest2 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest2(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Demonstrate your ability to interpret a design brief and create a solution.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
      Creative Process Documentation
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Create a logo design for a new café called "Bean & Brew". The café focuses on organic coffee, cozy atmosphere, and eco-friendly practices. The target audience is young professionals and coffee enthusiasts who appreciate quality and sustainability.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Guideline: </span>
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Logo should be simple yet memorable, scalable (works on both business cards and billboards).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Use earthy tones and minimalistic design elements.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Submit at least two concepts with the final design.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Provide a short design rationale explaining the concept, colors, and typography choices.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        *  Showcase how you approach design problems and refine your ideas by submiting your design process for the logo design you did. This include:
      </p>

      <p className="mt-4 text-[15px] font-[600] text-[#fff] ml-2">
        a. Initial sketches (hand-drawn or digital).
      </p>

      <p className="mt-4 text-[15px] font-[600] text-[#fff] ml-2">
        b. A mood board with color inspirations and reference images.
      </p>

      <p className="mt-4 text-[15px] font-[600] text-[#fff] ml-2">
        c. Wireframes or layout explorations (if applicable).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
      <span className='font-[600]'>Why?: </span>We want to understand how you develop your ideas from concept to final design. Document any revisions or iterations based on your thought process.
      </p>
      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)} 





{openTest3 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest3(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Test your understanding of typography and color usage.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
      Typography & Color Theory
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Design a promotional poster for an event called "Summer Music Fest". The event will feature local bands, food trucks, and a relaxed atmosphere.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Guideline: </span>
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Use bold typography for the event name and additional text.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * The poster should have a vibrant, fun color scheme that reflects the summer and festival theme.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Include essential details: Date, Time, Location, and Website (use dummy text if necessary).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * The design should be print-ready (consider bleed, resolution, and CMYK colors).
      </p>

      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)} 







{openTest4 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest4(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Assess your ability to create a cohesive brand identity.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
      Branding Package
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Develop a branding package for a fictional eco-friendly clothing brand named "Green Threads". The brand focuses on sustainable fashion and targets environmentally-conscious millennials.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Guideline: </span>
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Logo design (based on the brand’s values).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Color palette that represents eco-friendliness (earthy, neutral tones).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Typography choices for headings, subheadings, and body text.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Business card design (front and back).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Letterhead design (use a simple layout with logo and contact information).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Deliverables: Provide a PDF presentation showcasing your logo, color palette, typography, and business card/letterhead design.
      </p>

      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)} 
       




       {openTest5 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest5(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Show your ability to design for digital platforms.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
      Social Media Graphics
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Design a set of social media graphics for the brand you created in Section 5, “Green Threads,” to promote a new product launch: an eco-friendly t-shirt made from organic cotton.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Guideline: </span>
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Design 3 different graphics for Instagram, Facebook, and LinkedIn (e.g., post, banner, and ad).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Graphics should include the product image, the brand logo, and a catchy call-to-action (e.g., “Shop Now”, “Limited Edition”).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        * Keep the designs consistent with the branding package you created earlier.
      </p>

      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)} 





{openTest6 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest6(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Assess how efficiently you can work under deadlines.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
      Time Management
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Complete a quick design challenge. You have 1 hour to design a simple flyer for a local event (a yoga class). You must include the event name, date, time, location, and contact information. Feel free to use any resources at your disposal.
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Deliverables: </span>
      </p>

      <p className="mt-3 text-[15px] text-[#fff] ml-2">
        * Submit the flyer in a print-ready format (.PDF or .JPG).
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        Your time started counting from the time you clicked the 'Start' button. We will use the started time to judge against the time your email was recieved.
      </p>

      <p className="mt-4 font-[600] text-[15px] text-[#fff] ml-2">
        Goodluck!
      </p>

      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)}






{openTest7 && (
  <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
    <div className={`w-[90%] 800px:w-[50%] bg-[#012] text-white shadow rounded max-h-[80vh] min-h-[50vh] px-6 py-4 overflow-y-auto`}>
      <div className="w-full flex justify-end">
        <RxCross1
          size={25}
          onClick={() => setOpenTest7(false)}
          className="cursor-pointer"
          color="#fff"
        />
      </div>

      <div className="block">
        <h5 className="text-[15px] text-[yellow] font-[600]">Objective:</h5>
        <h5 className="text-[15px] text-[#fff]">Test your ability to handle client feedback and make revisions.</h5>
      </div>
      <br />

      <h5 className="mt-4 mb-2 text-[18px] font-[600] font-[Poppins] text-[#fff]">
      Design Feedback Simulation
      </h5>
      <p className="mt-2 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Task: </span>Asume you received a feedback on the logo design you submitted. The feedback read as follows:
      </p>

      <p className="mt-4 text-[15px] text-[#fff] ml-2">
        <span className='font-[600]'>Client Feedback: </span>"We love the design, but we need something a bit more modern. Can you try a sleeker font? Also, the color palette feels too earthy. We’d like to see something with a brighter, more vibrant look."
      </p>

      <p className="mt-3 text-[15px] text-[#fff] ml-2">
      <span className='font-[600]'>Requirement: </span> Revise your logo based on the client’s feedback and submit the new version along with a brief explanation of the changes you made.
      </p>

      <p className="mt-4 font-[600] text-[15px] text-[#fff] ml-2">
        Goodluck!
      </p>

      <br />

      <div className="flex w-full">
        <div className="relative">
          <img
            src={TestImg}
            className="w-[250px] h-[250px] rounded-lg object-cover border-[3px] border-[#fff] mt-5"
            alt=""
          />
        </div>
      </div>
      <br />  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Username:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.username}</h5>
      </div>  

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">Email:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user.email}</h5>
      </div> 

      <div className="flex">
        <h5 className="text-[15px] text-[yellow]">UWID:</h5>
        <h5 className="text-[15px] text-[#fff] ml-2">{user && user._id}</h5>
      </div>    

      <br />

      <div className={`${styles.button} !bg-[#c89b2a] !w-[80px] !h-[40px] mb-2`} onClick={() => setIsSubmit(true)}>
        <span className="text-[#fff] font-[Poppins] text-[14px]">Submit</span>
      </div>

      {isSubmit && (
        <h5 className="text-[16px] text-[#fff] ml-2 mb-3">
          Send link and other submissions to totlesoft.hr@gmail.com with 
          <br />(a) Username 
          <br />(b) Email 
          <br />(c) UWID 
          <br />using the subject "My Graphic Design Test Link"
        </h5>
      )}
      
      <h5 className="text-[13px] text-[yellow] ml-2">
      Good luck, and we look forward to seeing your work!
      </h5>

      <div><br /></div>       
    </div>
  </div>
)}
                     
        
        </div>
        </div>
        </div>
        </>
    )
}

export default GraphicTestPage
