import React from 'react'
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import BackgroundAnimate from '../BackgroundAnimate';
import coin from '../Assests/images/usflag.png';

import FlippingImage from "../components/FlippingImage";


const KohlsCardOfferPage = () => {

  
  return (
    <div>
      <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium">

          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white overflow-y-auto h-screen">
          
          <div className="mt-4 text-4xl font-bold flex items-center text-center">
          <h5>
          Free KOHL'S $500 Gift Card
          </h5>
            </div>
             <div className="text-base mt-1 flex items-center">
            <h5 className="font-[500] text-[20px]">Answer question 1 of 3</h5>
            </div>
            <br />
            
           

                             

          <div className="relative mt-5">
            <FlippingImage />
            </div>
            <br />
           

          

          <div className="text-base mt-3 flex items-center">
          <h5 className="font-[600] text-[25px] text-[#000] ml-2 text-center">Do You Shop Online?</h5>
          </div>



          <div className="mt-3 text-3xl font-bold flex items-center">
          <Link to="https://singingfiles.com/show.php?l=0&u=2316937&id=67671">
          <div className={`${styles.buttonRed} !bg-[green]`}>
               <span className="text-[#fff] font-[Poppins] font-[400] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Yes
               </span>
          </div>
          </Link>
          </div>

          <div className="mt-1 text-3xl font-bold flex items-center">
          <Link to="https://singingfiles.com/show.php?l=0&u=2316937&id=67671">
          <div className={`${styles.buttonRed}`}>
               <span className="text-[#fff] font-[Poppins] font-[400] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               No
               </span>
          </div>
          </Link>
          </div>

          
          <div className="text-base mt-2 flex items-center">
          <img src={coin} width={30} height={30} />
          </div>
        </div>



        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-center items-center mt-4 text-[16px] text-[#000]">
           <h5>US Residence Only!</h5>
          </div>
        </div>

        </div>


        </div>
       <BackgroundAnimate />
    </div>
  )
}

export default KohlsCardOfferPage;
