import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from "../../server";

const TestButton3 = ({ userId, email, testId, setOpenn }) => {
    const [hasTakenTest, setHasTakenTest] = useState(null); // null means we're still loading the data
    const [score, setScore] = useState(null);
    const [status, setStatus] = useState('');
     
    useEffect(() => {
        // Fetch participation status when component mounts
        const checkParticipation = async () => {
          try {
            const response = await axios.get(`${server}/testprog/check-participation-prog`, {
              params: { userId, email, testId },
            });
      
            if (response.data.taken) {
              setHasTakenTest(true);
              setScore(response.data.score);
              setStatus(response.data.status);
            } else {
              setHasTakenTest(false);
            }
          } catch (error) {
            console.error('Error checking participation:', error);
          }
        };
      
        if (testId) {
          checkParticipation();
        }
      
      }, [userId, email, testId]);  // Make sure testId is included in the dependency array

     
      
      // Render loading state or button based on participation check
      if (hasTakenTest === null) {
        return (
          <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
            <button className="w-48 h-12 bg-yellow-500 text-white font-bold rounded-full cursor-not-allowed sm:w-40">
              Loading test...
            </button>
          </div>
        );
      }
      
    return (
      <div className="flex flex-col items-center mt-8">
        <button
          className={`w-48 h-12 ${hasTakenTest ? 'bg-green-500' : 'bg-blue-500'} text-white font-bold rounded-full shadow-md hover:opacity-80 focus:outline-none`}
          disabled={hasTakenTest}
          onClick={() => setOpenn(true)}
        >
          {hasTakenTest ? 'Taken' : 'Take Test'}
        </button>
  
        {hasTakenTest && (
          <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-lg max-w-xs w-full text-center">
            <p className="text-sm text-gray-700">Your score:</p>
            <p className="text-xl font-bold text-blue-600">{score}</p>
            <p className="text-sm text-gray-700 mt-2">Status:</p>
            {score < 80 ? <p className="text-sm font-semibold text-red-600">{status}</p> : <p className="text-sm font-semibold text-green-600">{status}</p>}
          </div>
        )}
      </div>
    );
  };
  
  export default TestButton3;