import React from 'react';

const games = [
  {
    id: 1,
    title: "PUBG",
    description: "PUBG's intense battle royale gameplay, realistic mechanics, and competitive edge have made it a defining title in the genre. Whether playing solo or with a team, its high-stakes matches and strategic depth appeal to players worldwide, securing its place as a pioneer in online multiplayer gaming",
    cover: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202010/pubg_game_660_141020121948.jpg?size=1200:675",
    device: "Android, iOS",
    downloadLink: "https://www.pubg.com",
    liveSectionLink: "#",
  },
  {
    id: 2,
    title: "Fortnite",
    description: "Fortnite's accessible gameplay, innovative mechanics, and constant updates have made it one of the most influential modern games. It appeals to both casual and competitive players, solidifying its place as a staple in online gaming",
    cover: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQvQmktu3QSbhfcGUmhemiKg3EX34yeA_Pag&s",
    device: "Android, iOS, Desktop",
    downloadLink: "https://www.fortnite.com",
    liveSectionLink: "#",
  },
  {
    id: 3,
    title: "Minecraft",
    description: "Minecraft's open-ended gameplay, creative freedom, and endless possibilities have made it one of the most iconic and influential games of all time. Whether building, exploring, or surviving, it offers something for everyone, cementing its status as a timeless favorite in gaming",
    cover: "https://static0.gamerantimages.com/wordpress/wp-content/uploads/2023/05/minecraft-new-spin-off-genres.jpg",
    device: "Android, iOS, Desktop",
    downloadLink: "https://www.minecraft.net",
    liveSectionLink: "#",
  },
  {
    id: 4,
    title: "Grand Theft Auto V",
    description: "Grand Theft Auto V's immersive open world, gripping storytelling, and diverse gameplay options have solidified it as one of the most successful and influential games of all time. Whether exploring, completing missions, or engaging in multiplayer mayhem, it offers something for every player, making it a cornerstone of modern gaming",
    cover: "https://image.api.playstation.com/vulcan/ap/rnd/202203/0911/VIB0SeEj9vT6DTv7P4thJLZi.jpg?w=440",
    device: "Android, iOS, Desktop",
    downloadLink: "https://www.rockstargames.com/gta-v",
    liveSectionLink: "#",
  },
  {
    id: 5,
    title: "FREE FIRE",
    description: "Free Fire's fast-paced battle royale action, compact matches, and mobile-friendly design have made it a global hit. With its accessible gameplay, diverse characters, and regular updates, it appeals to casual and competitive players alike, cementing its status as a top mobile gaming experience",
    cover: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1muAnpvrrfUjBUMYj4_IG1ICGp4uD0NEMxA&s",
    device: "Android, iOS",
    downloadLink: "https://ff.garena.com",
    liveSectionLink: "#",
  },
  {
    id: 6,
    title: "ROBLOX",
    description: "Roblox's vast user-generated worlds, creative freedom, and endless variety of games have made it a global phenomenon. Whether building, exploring, or socializing, it offers something for players of all ages, solidifying its place as a unique and influential platform in gaming.",
    cover: "https://images.indianexpress.com/2024/02/Roblox.jpg",
    device: "Android, iOS, Desktop",
    downloadLink: "https://www.roblox.com",
    liveSectionLink: "#",
  },
];

const GameCard = ({ game }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all hover:scale-105 hover:shadow-xl">
    <img src={game.cover} alt={game.title} className="w-full h-48 object-fill" />
    <div className="p-6">
      <h2 className="text-xl font-bold mb-2">{game.title}</h2>
      <p className="text-gray-600 mb-4">{game.description}</p>
      <p className="text-sm text-gray-500 mb-4">Available on: {game.device}</p>
      <div className="flex space-x-4">
        <a
          href={game.downloadLink}
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 bg-blue-500 text-white text-center py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Download Game
        </a>
        <a
          href={game.liveSectionLink}
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 bg-green-500 text-white text-center py-2 rounded-lg hover:bg-green-600 transition-colors"
        >
          Join Live Section
        </a>
      </div>
    </div>
  </div>
);

const GameNitchPage = () => (
  <div className="min-h-screen bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
    <h1 className="text-3xl font-bold text-center">Explore Our Games</h1>
    <p className="text-center mb-10">Download games or join live sections to learn the survival secret that will help aid your survival on the game world.</p>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
      {games.map((game) => (
        <GameCard key={game.id} game={game} />
      ))}
    </div>
  </div>
);

export default GameNitchPage;