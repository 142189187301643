import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";

const ViewApplicantsCard = ({ data, id, workers }) => {
  const [open, setOpen] = useState(false);

    
  // Debug: Log the workers array to ensure it contains the right data
  console.log("Workers Array:", workers);
  console.log("Applicant ID:", data.applicantId);

  // Check if the worker's ID exists in the workers array
  const workerExists = workers.some((worker) => {
    console.log("Comparing workerId:", worker.workerId, "with applicantId:", data.applicantId);
    return worker.workerId === data.applicantId;
  });

  console.log("Worker Exists:", workerExists);

  return (
    <>
      <div className="w-full h-[400px] bg-[#F6F6F5] rounded-lg shadow-sm p-3 relative cursor-pointer">
        <img
          src={`${data?.applicantAvatar?.url}`}
          className="w-[70px] h-[70px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
          alt=""
        />
        <h4 className="pb-3 font-[400] text-[15px]">
          Name: {data.applicantName}
        </h4>

        <h4 className="pb-3 font-[400] text-[15px]">
          Location: {data.applicantLocation}
        </h4>

        <h4 className="pb-3 font-[400] text-[blue] text-[13px]">
          ProjectID: {data.staskId}
        </h4>

        <div
          className={`${styles.button} mb-3 !w-[80px]`}
          onClick={() => setOpen(true)}
        >
          <span className="text-[#fff] font-[Poppins] text-[13px]">
            View
          </span>
        </div>

        <Link to={`/assign-worker/${data.applicantId}/to-project/${id}`}>
          <div className={`${styles.buttonGold} mb-3 !w-[80px]`}>
            <span className="text-[#fff] font-[Poppins] text-[13px]">
              Assign
            </span>
          </div>
        </Link>

        {/* Render the "Remove" button only if the worker exists in the workers array */}
        {workerExists && (
          <Link to={`/remove-worker/${data.applicantId}/to-project/${id}`}>
            <div className={`${styles.buttonGold} !bg-[red] mb-5 !w-[80px]`}>
              <span className="text-[#fff] font-[Poppins] text-[13px]">
                Remove
              </span>
            </div>
          </Link>
        )}

        {open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div
              className={`w-[90%] 800px:w-[70%] bg-white shadow rounded h-[80vh] overflow-y-scroll min-h-[50vh] px-8 py-6`}
            >
              <div className="w-full flex justify-end">
                <RxCross1
                  size={25}
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              </div>

              <h4 className="pb-3 font-[400] text-[15px]">
                E-mail: <span className="text-[17px] font-[600]">{data.applicantEmail}</span>
              </h4>

              <div>
                <h4 className="pb-3 font-[400] text-[15px]">
                  Education: <span className="text-[17px] font-[600]">{data.applicantEducationL}</span>
                </h4>

                <h4 className="pb-3 font-[400] text-[15px]">
                  Language: <span className="text-[17px] font-[600]">{data.applicantLanguage}</span>
                </h4>

                <h4 className="pb-3 font-[400] text-[15px]">
                  Location: <span className="text-[17px] font-[600]">{data.applicantLocation}</span>
                </h4>

                <h4 className="pb-3 font-[400] text-[15px]">
                  Portfolio: <span className="text-[17px] text-[blue] font-[600]">{data.potfolioURL}</span>
                </h4>

                <h4 className="pb-3 font-[400] text-[15px]">
                  Linkedin: <span className="text-[17px] text-[blue] font-[600]">{data.githubURL}</span>
                </h4>

                <h4 className="pb-3 font-[400] text-[15px]">
                  GitHub: <span className="text-[17px] text-[blue] font-[600]">{data.linkedinURL}</span>
                </h4>

                <h1 className="py-2 text-[20px]">Proposal:</h1>
                <hr />
                <hr />
                <h4 className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
                  {data.applicantAboutMe}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewApplicantsCard;