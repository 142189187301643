import React from 'react';
import { Link } from "react-router-dom";

const CPAOfferCard = ({ data }) => {
    return (
        
        <div className={`max-w-5xl md:block shadow-lg mx-auto rounded md:flex md:justify-between md:tems-center bg-white p-6 m-6 mb-16 sm:mb-0 ${data?.status === "Active" && 'border-l-4 border-[#c89b2a]'}`}>
            <div className='md:flex md:items-center'>
                <div>   
                    <span className='text-cyan font-bold text-sm m-2'>{data?.networkName}</span>
                    {data?.status === "Active" && <span className='bg-[#c89b2a] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl'>Active</span>}
                    {data?.status === "Closed" && <span className='bg-[black] text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl mx-2'>Closed</span>}

                    {data?.status === "Active" && <Link to={`/offer/${data._id}`}><span className='bg-[teal] ml-2 text-white px-2 py-1 font-medium text-xs uppercase rounded-2xl'>Details</span></Link>}
                    <h1 className='text-black font-medium md:text-xl m-2'>{data.offerName.length > 40 ? data.offerName.slice(0, 40) + "..." : data.offerName}</h1>
                    <div>
                        <span className='text-dark_gray_cyan text-sm m-2'>${data?.offerPayout}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{data?.countries}</span>
                        <span className='m-1 h-1 w-1 inline-block rounded'></span>
                        <span className='text-dark_gray_cyan text-sm m-2'>{data?.offerKeywords}</span>
                    </div>
                </div>
            </div>
            <div className='w-full border-t my-2 border-[gray] md:hidden'></div>
        <Link to={data.networkAffLink} target="_blank" className="inline-block cursor-pointer">
        <div className='my-auto'>
           <img
            src={data?.networkLogoURL}
            width='120px'
            alt="instagram"
          />
        </div>
        </Link>
        </div> 
       )
}

export default CPAOfferCard;

