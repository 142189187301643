import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import { Country } from "country-state-city";

const CreateSurvey = () => {
  const { seller } = useSelector((state) => state.seller);
  
  const navigate = useNavigate();
 
  const [name, setName] = useState("");
  const [eligibleCountry, setEligibleCountry] = useState("");
  const [eligibleDevice, setEligibleDevice] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [description, setDescription] = useState("");
  const [taskPayment, setTaskPayment] = useState("");
  const [taskRefURL, setTaskRefURL] = useState("");
  const [surveyTimer, setSurveyTimer] = useState(0);
  const [loading, setLoading] = useState(false);
    
  const selaE = seller?.email;
  const selaId = seller?._id;

  console.log(selaE, selaId);
  
 const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `${server}/survey/create-survey`,
        {
            name,
            eligibleCountry,
            eligibleDevice,
            imageurl,
            description,
            taskPayment,
            surveyTimer,
            taskRefURL,
            shopId : selaId,
            shopEmail: selaE,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("survey task created successfully!");
        setLoading(false);
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data.message);
      });    
};

    
  return (
    <>
    {seller && seller?.role === "ProSeller" ? (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Survey</h5>
      {/* create eventAd form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
           Survey Name: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter survey name..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Eligible Country: <span className="text-red-500">*</span>
          </label>
          <select
            type="text"
            name="eligibleCountry"
            value={eligibleCountry}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEligibleCountry(e.target.value)}
            >
            <option value="" className="block border pb-2">
                       Choose Country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                        <option value="Worldwide">Worldwide</option>
        </select>
        </div>
        <br />

        <div>
          <label className="pb-2 text-[blue]">
          Description: <span className="text-red-500">*</span> 
          </label>
          <textarea
            cols="30"
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description..."
            maxLength={5000}
          ></textarea>
        </div>
        <br />
        
        <div>
          <label className="pb-2">
            Image URL: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="imageurl"
            value={imageurl}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setImageurl(e.target.value)}
            placeholder="Enter image url..."
          />
        </div>
        <br />

         
        <div>
          <label className="pb-2">
            Survey Time (in seconds e.g: 1 = 1 sec, 60 = 1 min): <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="surveyTimer"
            value={surveyTimer}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSurveyTimer(e.target.value)}
            placeholder="Enter survey time..."
          />
        </div>
        <br />        
       

        <div>
          <label className="pb-2">
          Eligible Devices: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eligibleDevice"
            value={eligibleDevice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEligibleDevice(e.target.value)}
            placeholder="Enter eligible devices..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
         Task Payment: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="taskPayment"
            value={taskPayment}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskPayment(e.target.value)}
            placeholder="Enter task payout..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
         Button Link: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="taskRefURL"
            value={taskRefURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTaskRefURL(e.target.value)}
            placeholder="Enter button link..."
          />
        </div>
        <br />

        <div>
        <br />
        <div>
            <button 
            type="submit" 
            disabled={loading}
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700"
            >
            {loading ? "Sending..." : "Create Survey"}
            </button>
            </div>
        </div>
      </form>
    </div>
    ):(
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Only ProSellers Can Create Survey.
      </h1>
    )}
    </>
    
  );
};

export default CreateSurvey;
