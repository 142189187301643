import React from 'react'
import ResetPassword from "../components/Login/ResetPassword.jsx";

const ResetPasswordPage = () => {
  
  return (
    <div>
        <ResetPassword />
    </div>
  )
}

export default ResetPasswordPage;