import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import Spinner from "../../components/Spinner/Spinner";
import { MdOutlineFolderCopy } from "react-icons/md";

const Payment = () => {
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pDtoUse, setPDtoUse] = useState([]);
  const [createCPayInvoice, setCreateCPayInvoice] = useState(true);
  const [showPayCPButton, setShowPayCPButton] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceOrderId, setInvoiceOrderId] = useState("");
     
  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
    
  }, []);



  const sendGooglePurchaseEvent = (amount, email = null) => {
    if (window.gtag) {
      window.gtag("event", "purchase", {
        transaction_id: Date.now().toString(), // Unique ID for tracking
        value: amount,
        currency: "USD", // Change to your preferred currency
        items: [
          {
            name: "Order Purchase",
            category: "E-commerce",
          },
        ],
        ...(email && { user_email: email }), // Include email if available
      });
    }
  };




  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "USD",
              value: orderData?.totalPrice,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  
  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler(paymentInfo);
      }
    });
  };

  const paypalPaymentHandler = async (paymentInfo) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    order.paymentInfo = {
      id: paymentInfo.payer_id,
      status: "succeeded",
      type: "Paypal",
    };

    await axios
      .post(`${server}/order/create-order`, order, config)
      .then((res) => {
        sendGooglePurchaseEvent(order.totalPrice, order.user?.email);
        setOpen(false);
        navigate("/order/success");
        toast.success("Order successful!");
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        window.location.reload();
      });
  };

  //Cash On Delivery Payment Handler
  const cashOnDeliveryHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    order.paymentInfo = {
      type: "Cash On Delivery",
      status: "Completed",
    };

    await axios
    .post(`${server}/order/create-order`, order, config)
    .then((res) => {
      sendGooglePurchaseEvent(order.totalPrice, order.user?.email);
      setIsLoading(false);
      setOpen(false);
      navigate("/order/success");
      toast.success("Order successful!");
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
      window.location.reload();
    });
  };


  const invData = {
    amount: orderData?.totalPrice,
    currency: "USD",
    additional_data: user?.email,
}


const creatCPInvoice = async () => {
setIsLoading(true);

try {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { data } = await axios.post(
    `${server}/payment/crypto-checkout`,
    invData,
    config
  );

  const url = data.url;
  const order_id = data.order_id;
  
  if(url !== null && order_id !== null){
    setInvoiceUrl(url);
    setInvoiceOrderId(order_id);
    setCreateCPayInvoice(false);
    setShowPayCPButton(true);
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
};  


const confirmCPaymentInfo = async () => {
setIsLoading(true);

try {

const order_id = invoiceOrderId;

     
const { data } = await axios.get(
    `${server}/payment/crypto-payment-info/${order_id}`);
    setPDtoUse(data);

    if(!data){
      toast.error("Payment not found with this order Id")
    }

  const status = pDtoUse.cryptopayments[0].status;
  const is_final = pDtoUse.cryptopayments[0].is_final;

  if(status === "paid" && is_final === true){
           
      cryptoPaymentHandler();
     
  }else{
    toast.error("Payment is still processsing");
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
}; 


//Crypto Payment Handler
const cryptoPaymentHandler = async () => {
  setIsLoading(true);
 
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  order.paymentInfo = {
    type: "Crypto",
    status: "Completed",
  };

  await axios
  .post(`${server}/order/create-order`, order, config)
  .then((res) => {
    sendGooglePurchaseEvent(order.totalPrice, order.user?.email);
    setIsLoading(false);
    setOpen(false);
    navigate("/order/success");
    toast.success("Order successful!");
    localStorage.setItem("cartItems", JSON.stringify([]));
    localStorage.setItem("latestOrder", JSON.stringify([]));
    window.location.reload();
  });
};

const orderID = invoiceOrderId;

  const writeTextToClipboard = (text) => {
    text = orderID;
    navigator.clipboard.writeText(text);
    toast.success("Id Copied!");
  };

  
  
  return (
    <>
    {isLoading ? <Spinner /> : (
      <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
            onApprove={onApprove}
            createOrder={createOrder}
            cashOnDeliveryHandler={cashOnDeliveryHandler}
            writeTextToClipboard={writeTextToClipboard}
            confirmCPaymentInfo={confirmCPaymentInfo}
            creatCPInvoice={creatCPInvoice}
            createCPayInvoice={createCPayInvoice}
            setCreateCPayInvoice={setCreateCPayInvoice}
            showPayCPButton={showPayCPButton}
            setShowPayCPButton={setShowPayCPButton}
            invoiceUrl={invoiceUrl}
            setInvoiceUrl={setInvoiceUrl}
            invoiceOrderId={invoiceOrderId}
            setInvoiceOrderId={setInvoiceOrderId}
                               
          />
          
        </div>
         <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData orderData={orderData} />
        </div>
      </div>
    </div>
    ) }
    </>
    
  );
};


const PaymentInfo = ({
  open,
  setOpen,
  onApprove,
  createOrder,
  cashOnDeliveryHandler,
  writeTextToClipboard,
  confirmCPaymentInfo,
  creatCPInvoice,
  createCPayInvoice,
  showPayCPButton,
  invoiceUrl,
  invoiceOrderId,
    
}) => {
  const [select, setSelect] = useState(1);
    

  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
      {/* crypto */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(1)}
          >
            {select === 1 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Crypto
          </h4>
        </div>

        {/* crypto payment */}
        {select === 1 ? (
          <div className="w-full border-b">
            <div
              className={`${styles.button} !bg-[#051094] text-white h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              onClick={() => setOpen(true)}
            >
              Pay Now
            </div>

            {open && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
              <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                <div className="w-full flex justify-end p-3">
                  <RxCross1
                    size={30}
                    className="cursor-pointer absolute top-3 right-3"
                    onClick={() => setOpen(false)}
                  />
                </div>

                <h5 className="text-[red] text-[14px] font-[500]">Keep page open till the payment has been marked as "paid"</h5><br />
     {createCPayInvoice && 
     <>
      <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p>Click button bellow to generate crypto payment invoice</p> 

          <div>
             <br />
               <button
                 className={`${styles.button} !h-[42px] text-white`}
                 onClick={creatCPInvoice}
               >
                 Generate
               </button>
             </div>
          </div>
     </>
     }


     {showPayCPButton && 
     <>
 <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
 <p className="text-[green] font-[600]">Invoice generated successfully</p> 
 <h5 className="text-[16px] font-[600]">Order Id: {invoiceOrderId} 
 <MdOutlineFolderCopy 
 onClick={writeTextToClipboard}
 size={20}
 className="cursor-pointer mb-2"
 />
 </h5> 
 <h5 className="text-[red] text-[14px] font-[500]">Please save this order_id, it will be requested if there's an error with this order.</h5><br />

 <Link to={`${invoiceUrl}`} target="_blank">
 <h5 className={`${styles.button} text-white !h-[42px] !rounded`}>
  Pay 
 </h5>
 </Link>
 </div>
 <br />
 <h5>Wait atleast a minute after the payment has marked 'Payment is paid' before clicking the Completed button.</h5>
 <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
 onClick={confirmCPaymentInfo}
 >
  Completed 
 </h5>
 </>
     }                

                </div>
                </div>
            )}
        </div>
        ) : null}
      </div>
      

      <br />
      {/* paypal payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(2)}
          >
            {select === 2 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            USD/PayPal
          </h4>
        </div>

        {/* pay with paypal */}
        {select === 2 ? (
          <div className="w-full flex border-b">
            <div
              className={`${styles.button} !bg-[#051094] text-white h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              onClick={() => setOpen(true)}
            >
              Pay Now
            </div>
            {open && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                  <div className="w-full flex justify-end p-3">
                    <RxCross1
                      size={30}
                      className="cursor-pointer absolute top-3 right-3"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "AZPU59nC-kxp_wh44e0hNmUxsuwZ8RKjWZBDrSExEb4hkIOLN_ytybsq2ews9-qee2ftxcADLW4RlWwz",
                      }}
                    >
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        onApprove={onApprove}
                        createOrder={createOrder}
                      />
                    </PayPalScriptProvider>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <br />
      {/* cash on delivery */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(3)}
          >
            {select === 3 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Cash on Delivery
          </h4>
        </div>

        {/* cash on delivery */}
        {select === 3 ? (
          <div className="w-full flex">
            <form className="w-full" onSubmit={cashOnDeliveryHandler}>
              <input
                type="submit"
                value="Confirm"
                className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              />
            </form>
          </div>
        ) : null}
      </div>

      <br />
      

      {/* select buttons */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(4)}
          >
            {select === 4 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Bank 
          </h4>
        </div>

        {/* pay with bank */}
        {select === 4 ? (
          <div className="w-full border-b">
            <h2>(U.S./UK) residence only</h2>
            <Link to="https://wa.me/447418375846" target="_blank"><h5 className="text-[green] cursor-pointer">Get Details</h5></Link>
          </div>
        ) : null}
      </div>

      <br />
      {/* Korapay Option */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(5)}
          >
            {select === 5 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Naira
          </h4>
        </div>

       {/* Pay with Paystack */}
       {select === 5 ? (
          <div className="w-full flex">
            <form className="w-full">
              <Link to='#'>
              <input
                type="submit"
                value="Pay Now"
                className={`${styles.button} !bg-[#016064] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              />
              </Link>
            </form>
          </div>
        ) : null}
      </div>

    </div>
  );
};




const CartData = ({ orderData }) => {
  const shipping = orderData?.shipping?.toFixed(2);
  return (
    
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
        <h5 className="text-[18px] font-[600]">${orderData?.subTotalPrice}</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">shipping:</h3>
        <h5 className="text-[18px] font-[600]">${shipping}</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
        <h5 className="text-[18px] font-[600]">{orderData?.discountPrice? "$" + orderData.discountPrice : "-"}</h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">
        ${orderData?.totalPrice}
      </h5>
      <br />
     </div>
   
  );
};


export default Payment;

// Paypal Live Client ID: AWhLs3ILRhUxqvgpcKjd6rvoU47rg_l7Yyc_GW-InHPkuLbpCouEE29HniCePvK8L1QxbneMxJezAzUq

// sandbus Client ID: AX2IeKtBAIyhCTxjvJhO9T_I-z0jd_W-rY_OmVNXwHlHKbe8n-ShGB_BX2MuJGrjeKlms77WOcts1T78