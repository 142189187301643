import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import CPAOfferDetails from "../components/CPAOffer/CPAOfferDetails";
import { useParams } from "react-router-dom";
import SpinnerX from "../components/Spinner/SpinnerX";
import axios from "axios";
import { server } from "../server";


const CPAOfferDetailsPage = () => {
  const { id } = useParams();
  const [dataTU, setDataTU] = useState(null);
   

    useEffect(() => {
        axios.get(`${server}/cpaoffer/get-all-offers`, {withCredentials: true}).then((res) => {
            setDataTU(res.data.cpaoffers);
        })
      }, []);        

    

       const data = dataTU && dataTU.find((i) => i._id === id);
  
  return (
    <div>
      <Header />
      {data ? (
        <CPAOfferDetails data={data} />
      ) : 
        <div className="w-full py-5 px-10">
          <h1 className="text-[20px] text-[500] mb-3">Loading...</h1>
          <SpinnerX />
        </div>
     }
      <Footer />
    </div>
  );
};

export default CPAOfferDetailsPage;
