import React from 'react';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { Link } from "react-router-dom";



function ToolsPage() {

      
  
  return (
    <div>
      <div className="w-full bg-[#F6F6F5] min-h-screen">
        <Header />

        <h2 className="my-4 font-[600] text-[30px] px-9 text-center">Leveraging the Totlesoft Free AI/Tools</h2>

<div className="mt-8 w-[95%] 800px:w-[80%] mx-auto 800px:flex items-center justify-center gap-6 px-5">
        
  <Link to="#">
  <div className="w-full 800px:w-[30vh] mb-4 h-[30vh] sm:h-[30vh] md:h-[30vh] lg:h-[30vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/WWVhhXgT/facebook-downloader.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

<Link to="#">
  <div className="w-full 800px:w-[30vh] mb-4 h-[30vh] sm:h-[30vh] md:h-[30vh] lg:h-[30vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/WWb4GpQN/linkedin-downloader.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

<Link to="#">
  <div className="w-full 800px:w-[30vh] mb-4 h-[30vh] sm:h-[30vh] md:h-[30vh] lg:h-[30vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/HT2DPyzJ/text-to-image.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

<Link to="#">
  <div className="w-full 800px:w-[30vh] mb-4 h-[30vh] sm:h-[30vh] md:h-[30vh] lg:h-[30vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/nMnp3QyX/totlesoft-urlshortner.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

<Link to="/yvd">
  <div className="w-full 800px:w-[30vh] mb-4 h-[30vh] sm:h-[30vh] md:h-[30vh] lg:h-[30vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/G4HMjbSs/youtube-downloader.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>
</div>
<br />

<p className="px-9 py-2 mb-6 text-center">
In today's digital landscape, having the right tools at your disposal can make all the difference in how effectively you manage and share content. That's where Totlesoft's free AI tools come into play, offering a suite of solutions designed to enhance your video downloading experience across multiple platforms.<br /><br />

Imagine effortlessly saving videos from Facebook with the **Totlesoft Facebook Video Downloader**—no more struggling to find a reliable method to keep those precious moments or informative clips for later viewing. Similarly, the **Totlesoft LinkedIn Video Downloader** allows professionals to capture valuable insights and presentations that can be revisited or shared with colleagues, ensuring that no important information slips through the cracks.<br /><br />

For social media enthusiasts and marketers alike, the **Totlesoft Instagram Video Downloader** is a game-changer. It enables users to download captivating reels and stories that can inspire future content creation or simply be enjoyed offline. And let’s not forget about YouTube; with the **Totlesoft YouTube Video Downloader**, you can save tutorials, music videos, or any other content you love without worrying about internet connectivity.<br /><br />

Additionally, managing your URLs has never been easier thanks to the **Totlesoft URL Shortner**. This tool helps organize your links efficiently, making it simple to access and share them whenever needed.<br /><br />

By leveraging these powerful tools from Totlesoft, you're not just enhancing your productivity; you're also ensuring that you have access to valuable content whenever you need it. Don't miss out on this opportunity—explore Totlesoft's free AI tools today and take control of your video downloading needs!
</p>


      

       <br />
        <br />
        <Footer />
      </div>
    </div>
  );
}
       
export default ToolsPage;