import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill } from "react-icons/ci";
import { TiVendorAndroid } from "react-icons/ti";
import { Link } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsHandbag } from "react-icons/bs";
import { MdOutlineLocalOffer, MdQrCode2, MdQrCodeScanner } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { FcSerialTasks, FcMoneyTransfer } from "react-icons/fc";
import { BiTransfer } from "react-icons/bi";
import {GiTeamUpgrade} from "react-icons/gi";

const AdminSideBar = ({ active }) => {
  return (
    <div className="w-full h-[90vh] bg-[#1c1c1c] shadow-sm overflow-y-scroll sticky top-0 left-0 z-10 text-white">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/admin/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={20}
            color={`${active === 1 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[yellow]" : ""
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-orders" className="w-full flex items-center">
          <FiShoppingBag
            size={20}
            color={`${active === 2 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[yellow]" : ""
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-sellers" className="w-full flex items-center">
          <GiTeamUpgrade 
            size={20}
            color={`${active === 3 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[yellow]" : ""
            }`}
          >
            All Clients
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-users" className="w-full flex items-center">
          <HiOutlineUserGroup
            size={20}
            color={`${active === 4 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-[yellow]" : ""
            }`}
          >
            All Users
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-products" className="w-full flex items-center">
          <BsHandbag
            size={20}
            color={`${active === 5 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? "text-[yellow]" : ""
            }`}
          >
            All Products
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-events" className="w-full flex items-center">
          <MdOutlineLocalOffer
            size={20}
            color={`${active === 6 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[yellow]" : ""
            }`}
          >
            All Events
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-tasks"
          className="w-full flex items-center"
        >
          <FcSerialTasks
            size={20}
            color={`${active === 7 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 7 ? "text-[yellow]" : ""
            }`}
          >
            All Tasks
          </h5>
        </Link>
      </div>


      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-withdraw-request"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={20}
            color={`${active === 8 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 8 ? "text-[yellow]" : ""
            }`}
          >
            Clients Withdrawals
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-withdraw-request-workers"
          className="w-full flex items-center"
        >
          <FcMoneyTransfer
            size={20}
            color={`${active === 9 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 9 ? "text-[yellow]" : ""
            }`}
          >
            Workers Withdrawals
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-transfers"
          className="w-full flex items-center"
        >
          <BiTransfer
            size={20}
            color={`${active === 10 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 10 ? "text-[yellow]" : ""
            }`}
          >
            All Transfers
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-newcodes"
          className="w-full flex items-center"
        >
          <TiVendorAndroid
            size={20}
            color={`${active === 11 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 11 ? "text-[yellow]" : ""
            }`}
          >
            All New Codes
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-usedcodes"
          className="w-full flex items-center"
        >
          <MdQrCode2
            size={20}
            color={`${active === 12 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 12 ? "text-[yellow]" : ""
            }`}
          >
            All Used Codes
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-create-questions"
          className="w-full flex items-center"
        >
          <MdQrCodeScanner
            size={20}
            color={`${active === 13 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 13 ? "text-[yellow]" : ""
            }`}
          >
            Create Qiestions
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/profile"
          className="w-full flex items-center"
        >
          <AiOutlineSetting
            size={20}
            color={`${active === 14 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 14 ? "text-[yellow]" : ""
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>

    </div>
  );
};

export default AdminSideBar;
