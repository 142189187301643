import React, { useState } from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";



const TestsPage = () => {
   
  return (
    <div>
        <Header />
        <Tests />
        
        <Footer />
    </div>
  )
}

const Tests = () => {
    const { user } = useSelector((state) => state.user);
    const [openEnglishUkTest, setOpenEnglishUkTest] = useState(false);
    const [openEnglishUSTest, setOpenEnglishUSTest] = useState(false);
    const [openGraphicMultiTest, setOpenGraphicMultiTest] = useState(false);
    const [openMernTest, setOpenMernTest] = useState(false);


    const testMessage = () => {
        toast.error("Test List Empty!")
    }

    return (
        <>
    <div className="mt-1 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>

      
        <h1 className={`text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}>
          Test<span className="text-[#c89b2a]">Center</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Complete any of the test bellow to qualify for more jobs in that category. Some project will need you to have passed a particular test before you can apply for them. Take as many test as possible to increase your jobs availability.
        </p>
        <br />

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
                <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Audio/Video Transcription Tests</h5>
              <Link to="/tests/audio-transcription-tests"><h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer">View</h5></Link>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Graphics Design Tests</h5>
              <Link to="/tests/graphic-design-tests"><h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer">View</h5></Link>
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">English (UK) Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenEnglishUkTest(true)}>View</h5>
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">English (US) Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenEnglishUSTest(true)}>View</h5>
              </div></div>

              <div className="bg-[#014D4E] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Graphics Design (Multichoice) Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenGraphicMultiTest(true)}>View</h5>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Programming (MERN Stack) Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={() => setOpenMernTest(true)}>View</h5>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Typying Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>
                                         
              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Content Writing Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>         
                                         

              
              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Annotation Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Categorization Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Rating Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Tagging Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[teal] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Crypto Trading Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <div className="bg-[#1c1c1c] text-[#fff] w-full 800px:w-[90%] mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2">Translation Tests</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[100px] px-2 py-1 rounded-xl cursor-pointer" onClick={testMessage}>View</h5>
              </div></div>

              <br />
              <br />


              {openEnglishUkTest && (
                <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                <div className="max-w-[90%] md:w-1/2 p-4">
                      <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
                        {/* Image Section */}
                        <div className="w-full md:w-1/2 h-64 md:h-auto">
                          <img
                            src="https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"
                            alt="Totlesoft"
                            className="object-cover w-full h-full"
                          />
                        </div>
                
                        {/* Text Content Section */}
                        <div className="p-6 flex flex-col justify-between">
                          <h3 className="text-xl font-semibold text-gray-800 mb-2">English UK Test</h3>
                          <p className="text-gray-600 mb-4">{user?.name ? "Hello " + user?.name : null} You have <span className='text-[20px] font-[600] text-[green]'>1</span> atempt left</p>
                          <p className="text-gray-600 mb-4">You need to score <span className='text-[20px] font-[600] text-[green]'>80%</span> to pass</p>
                          <p className="text-gray-600 mb-4">This is a time based test and your time will start counting as soon as you click the 'Start Test" button. Do not leave the browser or minimize the screen after test have started so you don't lose your score. Goodluck!</p>
                          <Link
                            to="/tests/english-uk-test"
                            className="inline-block text-white bg-teal-600 hover:bg-teal-800 py-2 px-4 rounded-md text-center"
                          >
                            Start Test
                          </Link>
                
                          <RxCross1 size={20} className="cursor-pointer mt-2" onClick={() => setOpenEnglishUkTest(false)} />
                        </div>
                      </div>
                    </div>
                    </div>
                
              )}




              {openEnglishUSTest && (
                <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                <div className="max-w-[90%] md:w-1/2 p-4">
                      <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
                        {/* Image Section */}
                        <div className="w-full md:w-1/2 h-64 md:h-auto">
                          <img
                            src="https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"
                            alt="Totlesoft"
                            className="object-cover w-full h-full"
                          />
                        </div>
                
                        {/* Text Content Section */}
                        <div className="p-6 flex flex-col justify-between">
                          <h3 className="text-xl font-semibold text-gray-800 mb-2">English US Test</h3>
                          <p className="text-gray-600 mb-4">{user?.name ? "Hello " + user?.name : null} You have <span className='text-[20px] font-[600] text-[green]'>1</span> atempt left</p>
                          <p className="text-gray-600 mb-4">You need to score <span className='text-[20px] font-[600] text-[green]'>80%</span> to pass</p>
                          <p className="text-gray-600 mb-4">This is a time based test and your time will start counting as soon as you click the 'Start Test" button. Do not leave the browser or minimize the screen after test have started so you don't lose your score. Goodluck!</p>
                          <Link
                            to="/tests/english-us-test"
                            className="inline-block text-white bg-teal-600 hover:bg-teal-800 py-2 px-4 rounded-md text-center"
                          >
                            Start Test
                          </Link>
                
                          <RxCross1 size={20} className="cursor-pointer mt-2" onClick={() => setOpenEnglishUSTest(false)} />
                        </div>
                      </div>
                    </div>
                    </div>
                
              )}


              {openGraphicMultiTest && (
                <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                <div className="max-w-[90%] md:w-1/2 p-4">
                      <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
                        {/* Image Section */}
                        <div className="w-full md:w-1/2 h-64 md:h-auto">
                          <img
                            src="https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"
                            alt="Totlesoft"
                            className="object-cover w-full h-full"
                          />
                        </div>
                
                        {/* Text Content Section */}
                        <div className="p-6 flex flex-col justify-between">
                          <h3 className="text-xl font-semibold text-gray-800 mb-2">Graphic Design Multichoice Test</h3>
                          <p className="text-gray-600 mb-4">{user?.name ? "Hello " + user?.name : null} You have <span className='text-[20px] font-[600] text-[green]'>1</span> atempt left</p>
                          <p className="text-gray-600 mb-4">You need to score <span className='text-[20px] font-[600] text-[green]'>80%</span> to pass</p>
                          <p className="text-gray-600 mb-4">This is a time based test and your time will start counting as soon as you click the 'Start Test" button. Do not leave the browser or minimize the screen after test have started so you don't lose your score. Goodluck!</p>
                          <Link
                            to="/tests/graphics-multichoice-test"
                            className="inline-block text-white bg-teal-600 hover:bg-teal-800 py-2 px-4 rounded-md text-center"
                          >
                            Start Test
                          </Link>
                
                          <RxCross1 size={20} className="cursor-pointer mt-2" onClick={() => setOpenGraphicMultiTest(false)} />
                        </div>
                      </div>
                    </div>
                    </div>
                
              )}



                {openMernTest && (
                <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                <div className="max-w-[90%] md:w-1/2 p-4">
                      <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
                        {/* Image Section */}
                        <div className="w-full md:w-1/2 h-64 md:h-auto">
                          <img
                            src="https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"
                            alt="Totlesoft"
                            className="object-cover w-full h-full"
                          />
                        </div>
                
                        {/* Text Content Section */}
                        <div className="p-6 flex flex-col justify-between">
                          <h3 className="text-xl font-semibold text-gray-800 mb-2">Programming (MERN Stack) Test</h3>
                          <p className="text-gray-600 mb-4">{user?.name ? "Hello " + user?.name : null} You have <span className='text-[20px] font-[600] text-[green]'>1</span> atempt left</p>
                          <p className="text-gray-600 mb-4">You need to score <span className='text-[20px] font-[600] text-[green]'>80%</span> to pass</p>
                          <p className="text-gray-600 mb-4">This is a time based test and your time will start counting as soon as you click the 'Start Test" button. Do not leave the browser or minimize the screen after test have started so you don't lose your score. Goodluck!</p>
                          <Link
                            to="/tests/programming-mern-stack-test"
                            className="inline-block text-white bg-teal-600 hover:bg-teal-800 py-2 px-4 rounded-md text-center"
                          >
                            Start Test
                          </Link>
                
                          <RxCross1 size={20} className="cursor-pointer mt-2" onClick={() => setOpenMernTest(false)} />
                        </div>
                      </div>
                    </div>
                    </div>
                
              )}

                     
        
        </div>
        </div>
        </div>
        </>
    )
}

export default TestsPage
