import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";

const CreateCPAOffer = () => {
  const { seller } = useSelector((state) => state.seller);
  
  const navigate = useNavigate();
 
  const [networkName, setNetworkName] = useState("");
  const [networkAffLink, setNetworkAffLink] = useState("");
  const [aboutNetwork, setAboutNetwork] = useState("");
  const [offerName, setOfferName] = useState("");
  const [networkLogoURL, setNetworkLogoURL] = useState("");
  const [offerLandingPageURL, setOfferLandingPageURL] = useState("");
  const [offerPayout, setOfferPayout] = useState("");
  const [offerKeywords, setOfferKeywords] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [countries, setCountries] = useState("");
  const [loading, setLoading] = useState(false);

  const selaE = seller?.email;
  const selaId = seller?._id;

  console.log(seller);
  

 const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `${server}/cpaoffer/create-cpaoffer`,
        {
            networkName,
            networkAffLink,
            aboutNetwork,
            offerName,
            networkLogoURL,
            offerLandingPageURL,
            offerPayout,
            offerKeywords,
            lastUpdated,
            countries,
            shopId : selaId,
            shopEmail: selaE,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("offer created successfully!");
        setLoading(false);
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data.message);
      });    
};

    
  return (
    <>
    {seller && seller?.role === "ProSeller" ? (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create CPA Offer</h5>
      {/* create eventAd form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Network Name: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="networkName"
            value={networkName}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setNetworkName(e.target.value)}
            placeholder="Enter network name..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            My Network Affiliate Link: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="networkAffLink"
            value={networkAffLink}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setNetworkAffLink(e.target.value)}
            placeholder="Enter network aff. link..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2 text-[blue]">
          About Network: <span className="text-red-500">*</span> 
          </label>
          <textarea
            cols="30"
            rows="8"
            type="text"
            name="aboutNetwork"
            value={aboutNetwork}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAboutNetwork(e.target.value)}
            placeholder="Enter About..."
            maxLength={5000}
          ></textarea>
        </div>
        <br />
        
        <div>
          <label className="pb-2">
            Offer Title: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="offerName"
            value={offerName}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOfferName(e.target.value)}
            placeholder="Enter offer title..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Network Logo address(URL): <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="networkLogoURL"
            value={networkLogoURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setNetworkLogoURL(e.target.value)}
            placeholder="Enter logo address..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
         Offer Landing Page Link: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="offerLandingPageURL"
            value={offerLandingPageURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOfferLandingPageURL(e.target.value)}
            placeholder="Enter offer landing page..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
         Payout <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="offerPayout"
            value={offerPayout}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOfferPayout(e.target.value)}
            placeholder="Enter payout..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Keywords/Category <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="offerKeywords"
            value={offerKeywords}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOfferKeywords(e.target.value)}
            placeholder="Enter keywords..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Last Updated: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="lastUpdated"
            value={lastUpdated}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setLastUpdated(e.target.value)}
            placeholder="Updated date..."
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
          Eligible Countries: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="countries"
            value={countries}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setCountries(e.target.value)}
            placeholder="Eligible countries..."
          />
        </div>
        <br />
        
        <div>
        <br />
        <div>
            <button 
            type="submit" 
            disabled={loading}
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700"
            >
            {loading ? "Sending..." : "Create Offer"}
            </button>
            </div>
        </div>
      </form>
    </div>
    ):(
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Only ProSellers Can Create Events Ad.
      </h1>
    )}
    </>
    
  );
};

export default CreateCPAOffer;
