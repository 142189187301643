import React, { useState, useEffect } from "react";
import freeApp from "../Assests/images/freeapp.jpg"
import freeMApp from "../Assests/images/movieapp.jpg"
import freeStuff from "../Assests/images/freestuff.jpg"
import { Link } from "react-router-dom";
import styles from "../styles/styles";




const HorizontalAds = () => {

    const [timerExpire, setTimerExpire] = useState(false);
    const [countdown, setCountdown] = useState(120);


    useEffect(() => {
        const timer =
        countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        return () => clearInterval(timer);
       
        }, [countdown]);

      useEffect(() => {
        if(countdown === 0){
          setTimeout(() => {
            setTimerExpire(true);
          }, 2000);
        }
      }, [countdown]);
  
     
  return (
    <div className="bg-[#F6F6F5] px-10 py-12 grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-3 xl:gap-[30px] my-12">
        
        <div className="w-full min-h-[400px] bg-[#fff] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={freeApp}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
            Act now, Access may be block soon!
           </h5>         
           <Link to="https://bestlocker.eu/iframe/9dc1e68e-f6b6-11ef-82fe-8a5fb7be40ea?allow_translate=1" className="inline-block">
            <div className={`${styles.buttonRed} !bg-[#000] mt-3`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 GAIN ACCESS  
                 </span>
            </div>
        </Link>
        </div>


        <div className="w-full min-h-[400px] bg-[#fff] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={freeMApp}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
        Please kindly watch the video guide before you continue 
        <Link to="/surveoo" className="inline-block">
            <div className={`${styles.buttonRed} !bg-[#000] mt-3`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 VIDEO GUIDE  
                 </span>
            </div>
        </Link>
        </h5>         
         </div>

        <div className="w-full min-h-[400px] bg-[#fff] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={freeStuff}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000]">
            Claim your Daily Site Visit Suprise Here! Get it now!
           </h5>         
           {timerExpire ? (
            <div className={`${styles.buttonRed} !bg-[grey] mt-3`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 EXPIRED!
                 </span>
            </div>
            ) : (
            <div className="flex">
            <Link to="https://bestlocker.eu/iframe/9dc1e68e-f6b6-11ef-82fe-8a5fb7be40ea?allow_translate=1" className="inline-block">
            <div className={`${styles.buttonRed} !bg-[#000] mt-3`}>
                 <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
                 CLAIM PRICE
                 </span>
            </div>
        </Link>

        <h5
        className={`${styles.button} !bg-[red] !w-[150px] font-[600] !text-[30px] text-center  py-[10px] px-[10px] !h-[42px] text-white ml-2 `}
        >
        {countdown} <small className="ml-2 text-[15px]">sec</small>
        </h5>
        </div>
           )}
        </div>


        
        

    </div>
          
    );
};

export default HorizontalAds;
