import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import styles from "../../styles/styles";
import { useNavigate, Link } from "react-router-dom";
import { server } from "../../server";
import GoogleSignUp from '../../components/Signup/GoogleSignUp'
import LinkedInSignUp from '../../components/Signup/LinkedInSignUp'
import FacebookSignUp from '../../components/Signup/FacebookSignUp'

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${server}/user/forgot-password`,
        { email : email }
      );
      toast.success(response.data.message);
      navigate("/login"); // Redirect to login page after success
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data.message || "Password Reset Failed!");
    }
  };

  return (
    <>

<div className="min-h-screen relative bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0c0a00]">
          Reset Password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
            
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Enter email:
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            
            <div>
            <button 
            type="submit" 
            disabled={loading}
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700"
            >
            {loading ? "Sending..." : "Send Reset Link"}
            </button>
            </div>

            <div className={`${styles.noramlFlex} w-full`}>
              <h4>Have a PIN?</h4>
              <Link to="/login-with-pin" className="text-blue-600 pl-2">
                Login with PIN
              </Link>
            </div>

            <div className={`${styles.noramlFlex} w-full`}>
              <h4>Don't have an account?</h4>
              <Link to="/sign-up" className="text-blue-600 pl-2">
                Signup
              </Link>
            </div>
          </form>
          <br />


          <GoogleSignUp />          

          <FacebookSignUp />

          <LinkedInSignUp />
        
        </div>
      </div>
    </div>
    </>
  );
};

export default ForgotPassword;