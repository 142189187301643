import React, { useState } from 'react';
import axios from 'axios';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import { server } from "../../server";

const CreateQuestions = () => {
  const [text, setText] = useState('');
  const [options, setOptions] = useState(['', '', '']);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [testId, setTestId] = useState('');
  


  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Ensure all fields are filled
    if (!text || !options.every(option => option.trim()) || !correctAnswer || !testId) {
      setError('Please fill in all fields correctly.');
      return;
    }
  
    try {
      // Send request to backend to create the question
      const response = await axios.post(`${server}/test/create-question`, {
        text,
        options,
        correctAnswer,
        testId,  // Ensure this is being sent correctly
      });
  
      // Reset form and display success message
      setText('');
      setOptions(['', '', '']);
      setCorrectAnswer('');
      setError('');
      setMessage('Question created successfully!');
    } catch (err) {
      setError('Failed to create question. Please try again.');
      console.error('Error creating question:', err.response?.data || err.message); // Log the specific error from backend
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
  <h1 className="text-2xl font-semibold text-center mb-6 text-teal-600">Create a New Question</h1>

  <form onSubmit={handleSubmit} className="space-y-6">

    {/* Text ID Input */}
    <div className="flex flex-col">
      <label className="text-lg font-medium text-gray-700 mb-2">Test ID (eng: 679bb4d1cb1a64df6bee1465)</label>
      <input
        type="text"
        value={testId}
        onChange={(e) => setTestId(e.target.value)}
        required
        className="px-4 py-2 border border-teal-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 outline-none transition duration-300"
      />
    </div>

    {/* Question Text Input */}
    <div className="flex flex-col">
      <label className="text-lg font-medium text-gray-700 mb-2">Question Text</label>
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        required
        className="px-4 py-2 border border-teal-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 outline-none transition duration-300"
      />
    </div>

    {/* Options Inputs */}
    <div className="flex flex-col">
      <label className="text-lg font-medium text-gray-700 mb-2">Options</label>
      {options.map((option, index) => (
        <div key={index} className="mb-3">
          <input
            type="text"
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            required
            placeholder={`Option ${index + 1}`}
            className="px-4 py-2 border border-teal-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 outline-none transition duration-300"
          />
        </div>
      ))}
    </div>

    {/* Correct Answer Input */}
    <div className="flex flex-col">
      <label className="text-lg font-medium text-gray-700 mb-2">Correct Answer</label>
      <input
        type="text"
        value={correctAnswer}
        onChange={(e) => setCorrectAnswer(e.target.value)}
        required
        className="px-4 py-2 border border-teal-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 outline-none transition duration-300"
      />
    </div>

    {/* Error/Success Messages */}
    {error && <div className="text-red-600 text-center">{error}</div>}
    {message && <div className="text-green-600 text-center">{message}</div>}

    {/* Submit Button */}
    <div className="flex justify-center">
      <button
        type="submit"
        className="w-full py-3 bg-teal-500 text-white font-semibold rounded-lg hover:bg-teal-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400"
      >
        Create Question
      </button>
    </div>
  </form>
</div>
  );
};


const AdminCreateQuestionsPage = () => {
   
    return (
      <div>
          <Header />
          <CreateQuestions />
          <Footer />
      </div>
    )
  }

export default AdminCreateQuestionsPage;

