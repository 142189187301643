import React from 'react'
import ForgotPassword from "../components/Login/ForgotPassword.jsx";

const ForgotPasswordPage = () => {
  
  return (
    <div>
        <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordPage;