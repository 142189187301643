import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import SpinnerX from "../../components/Spinner/SpinnerX";


const CPAOfferDetails = ({ data }) => {
         
      
      return (
        <>
        <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  <div className="block w-full 800px:flex">
                        
                  <div className="w-full pt-5">
  <h5 className="text-[25px] font-[600] mb-3 text-gray-800">
    Offer Details:
  </h5>
  <hr className="border-gray-300 mb-4" />

  <h4 className="mt-3 pb-3 font-[700] text-[20px] text-gray-900">
    {data.offerName}
  </h4>

  <div className="flex items-center mb-3">
    <h5 className="font-[300] text-gray-600">
      <small className="text-[15px] text-[blue]">Payment:</small> 
      <span className="text-lg font-semibold">{data.offerPayout}</span>
    </h5>
  </div>

  <div className="flex items-center mb-3">
    <h5 className="text-[14px] font-[400] text-gray-700">
      <small className="text-[15px] text-[blue]">Network:</small> {data.networkName}
    </h5>
  </div>

  <div className="flex items-center mb-3">
    <h5 className="font-[300] text-gray-600">
      <small className="text-[15px] text-[blue]">Landing Page:</small> 
      <Link to={data.offerLandingPageURL} target="_blank">
        <small className="text-[15px] text-[blue]"> Offer Landing Page</small>
      </Link>
    </h5>
  </div>

  <div className="flex items-center mb-3">
    <h5 className="font-[300] text-gray-600">
      <small className="text-[15px] text-[blue]">Category:</small> {data.offerKeywords}
    </h5>
  </div>

  <div className="flex items-center mb-3">
    <h5 className="font-[300] text-gray-600">
      <small className="text-[15px] text-[blue]">Eligible Countries:</small> {data.countries}
    </h5>
  </div>

  <div className="flex items-center mb-3">
    <h5 className="font-[300] text-gray-600">
      <small className="text-[15px] text-[blue]">Last Updated:</small> {data.lastUpdated}
    </h5>
  </div>

  <Link to={data.networkAffLink} target="_blank" className="inline-block">
    <div className={`${styles.button} mt-4 bg-blue-600 hover:bg-blue-700 rounded-md`}>
      <span className="text-[#fff] font-[Poppins] !text-[15px] flex items-center py-[10px] px-[10px] text-xl/4">
        Join Network
      </span>
    </div>
  </Link>
  
  <br />

  <Link to="/cpaoffers">
    <div className="flex items-center mt-4">
      <h5 className="font-[300] text-gray-600">
        <small className="text-[15px] text-[blue]">see more offers</small>...
      </h5>
    </div>
  </Link>
  
  <br />

  <div className="block mt-5">
    <h5 className="text-[25px] font-[600] text-gray-800">
      {data?.networkName}
    </h5>

    <h4 className="text-[18px] leading-8 text-gray-700 whitespace-pre-line">
      {data?.aboutNetwork}
    </h4>
  </div>
  <br />

            <Link to="/profile">
              <div className="w-full 800px:w-[40vh] mb-4 h-[40vh] sm:h-[40vh] md:h-[40vh] lg:h-[40vh] shadow rounded px-2 py-2 flex items-center justify-center">
                <img
                  src="https://i.ibb.co/RGssKc2V/campaign-cover2.jpg"
                  alt="Totlesoft"
                  className="object-contain w-full h-full rounded"
                />
              </div>
            </Link> 



                            
                           </div>
                        </div>
                      
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[20px] text-[500] mb-3">Loading Offer Details...!</h1>
                <SpinnerX />
                </div>}
            
                </div>
            </>
       );

        }

export default CPAOfferDetails;


