import React, { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../../styles/styles';
import axios from 'axios';
import { server } from "../../server";
import { dollarRate } from "../../dollarRate";

const KorapayPayment = () => {

  const [showdata, setShowData] = useState(true)
  const [orderData, setOrderData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const publicKey = "pk_test_qGTPXRyzt8wJ7octGKbVeRCnFDnEgrb1zXAdEiLS";
  const dollar_rate = dollarRate;

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
    
  }, []);



  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://korablobstorage.blob.core.windows.net/modal-bucket/korapay-collections.min.js";
    script.async = true;
    script.onload = () => console.log("Korapay script loaded");
    document.body.appendChild(script);
  }, []);
  
  function payKorapay() {
    if (!window.Korapay) {
      alert("Korapay is not loaded yet. Please wait or refresh the page.");
      return;
    }
  
    window.Korapay.initialize({
      key: publicKey,
      reference: String(Math.floor(Math.random() * 1000000000) + 1),
      amount: Math.round((orderData?.totalPrice || 0) * dollar_rate),
      currency: "NGN",
      customer: {
        name: user?.name || "Guest",
        email: user?.email || "guest@example.com",
      },
      onClose: function () {
        alert("Transaction was not completed, window closed.");
      },
      onSuccess: function () {
        korapayPaymentHandler();
      },
      onFailed: function () {
        alert("Sorry, Transaction failed! Please try again later.");
      },
    });
  }



  const sendGooglePurchaseEvent = (amount, email = null) => {
      if (window.gtag) {
        window.gtag("event", "purchase", {
          transaction_id: Date.now().toString(), // Unique ID for tracking
          value: amount,
          currency: "USD", // Change to your preferred currency
          items: [
            {
              name: "Order Purchase",
              category: "E-commerce",
            },
          ],
          ...(email && { user_email: email }), // Include email if available
        });
      }
    };




  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
  };

  //Korapay Payment Handler
  const korapayPaymentHandler = async () => {
       
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    order.paymentInfo = {
      type: "Korapay Payment",
      status: "Completed"
    };

    await axios
    .post(`${server}/order/create-order`, order, config)
    .then((res) => {
      sendGooglePurchaseEvent(order.totalPrice, order.user?.email);
      paywithKorapay();
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
   });
  };

    
  const paywithKorapay = () => {
    setShowData(false);
    goHome();
    
  }


  const goHome = () => {
    navigate("/order/success");
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
      window.location.reload();
  }

  return (

    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      
        
      <div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Confirm Your Order
        </h2>
      </div>
      <h3 className="block text-20px font-bold text-gray-800 text-center">Secured Payment!</h3>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" >
          <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                USD/Naira Rate:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  $1USD = N{user && dollar_rate} Naira
                </h4>
               </div>
                }
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Full Name:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  {user && user?.name}
                </h4>
               </div>
                }
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email address:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  {user && user?.email}
                </h4>
               </div>
               }
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Amount to Pay:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  N{user && orderData?.totalPrice * dollar_rate}
                </h4>
               </div>
               }
            </div>
          </form> 

          {showdata ? (
            <button 
            className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
            type="button" 
            onClick={payKorapay}> 
            Pay 
            </button>
          
          ) : (
            <div>
              <br />
            <button 
            className={`!bg-[#0fa311] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] pl-[10px] pr-[10px]`} 
           
            onClick={goHome}
            >Payment is Completed. Click to return</button>
            <br />
            </div>
          )}
        <div>
          <br />
      
        </div>       

       
        </div>
      </div>
      </div>
    
    </div>
  )
}


const Korapay = () => {
  return (
    <div>
     
      <KorapayPayment />
      
    </div>
  );
};

export default Korapay;