import React from 'react';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import RCHome from './RCHome';
import RCHomeFoot from "./RCHomeFoot"
import Articles from './Articles';
import ProductPageUpperButtonBar from "../../components/ProductPageUpperButtonBar";

function RCHomePage() {
  return (
    <div>
        <Header />
        <br />
        <div className="w-full sticky 800px:mt-0 mt-[18%]">
              <ProductPageUpperButtonBar />
            </div>
        <br />
        <RCHome />
        <Articles />
        <RCHomeFoot />
        <Footer />
    </div>
  )
}

export default RCHomePage