import React, { useEffect } from "react";
import { AiOutlineLogin, AiOutlineMessage } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineShoppingBag, HiBell } from "react-icons/hi";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { CiMoneyBill, CiMemoPad } from "react-icons/ci";
import {
  MdOutlineAdminPanelSettings,
 // MdOutlinePassword,
  MdOutlineTrackChanges,
} from "react-icons/md";
import { TbAddressBook, TbReportMoney } from "react-icons/tb";
import { FaLink } from "react-icons/fa6";
import { TiVendorAndroid } from "react-icons/ti";
import { FaTasks, FaJoget } from "react-icons/fa";
import { RxPerson } from "react-icons/rx";
import { Link } from "react-router-dom";
import styles from "../styles/styles";


const ProductPageUpperButtonBar = () => {
       
  
  return (
    <div className="!bg-[#F6F6F5] w-full">
      <div className={`${styles.section} !bg-[#F6F6F5] py-4 px-3 rounded-lg text-[#fff] shadow-lg`}>
      <div className="grid grid-cols-3 gap-[30px] md:grid-cols-5 md:gap-[30px] lg:grid-cols-9 lg:gap-[20px] xl:grid-cols-9 xl:gap-[20px]">
      
      <Link to="/academy/courses">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Courses
        </span>
      </div>
      </Link>

      <Link to="/tasks">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Tasks
        </span>
      </div>
      </Link>  

      <Link to="/s-tasks">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Projects
        </span>
      </div>
      </Link>    

      <Link to="/cpaoffers">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          CPA Offers
        </span>
      </div>
      </Link> 

      <Link to="/rewards-earning-structure">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Rewards
        </span>
      </div>
      </Link>    

      <Link to="/faq">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Tutorials
        </span>
      </div>
      </Link>

      <Link to="/tests">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Test Center
        </span>
      </div>
      </Link>


      <Link to="/profile/create-gig">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          Create Gig
        </span>
      </div>
      </Link>


      <Link to="https://tools.totlesoft.com" target="_blank">
      <div className="flex bg-[#000] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} />
        <span className="pl-3 text-sm/4 block">
          AI/Tools
        </span>
      </div>
      </Link>
      
      </div>
      </div>
    </div>
  );
};

export default ProductPageUpperButtonBar;
