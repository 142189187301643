import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SurveyDetails = () => {
    const [allSurveys, setAllSurveys] = useState([]);
    const { id } = useParams();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(300); // Initial countdown time
    const [isClaimTime, setIsClaimTime] = useState(false);
    const [startSurvey, setStartSurvey] = useState(false); // Controls the start of the survey

    useEffect(() => {
        axios.get(`${server}/survey/get-all-surveys`, {withCredentials: true}).then((res) => {
          setAllSurveys(res.data.surveys);
        })
    }, []);

    const data = allSurveys && allSurveys.find((i) => i._id === id);

    useEffect(() => {
        // Start countdown only when survey is started
        if (startSurvey && countdown > 0) {
            const timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [startSurvey, countdown]);

    useEffect(() => {
        if (countdown === 0) {
            setTimeout(() => {
                surveytaskUpdateHandler();
                setIsClaimTime(true);
            }, 2000);
        }
    }, [countdown]);

    const worker = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId: data?._id,
        taskPayment: data?.taskPayment,
        requiredSurveyTime: data?.surveyTimer,
    };

    const surveytaskUpdateHandler = async () => {
        const status = data?.taskStatus;

        await axios.put(
            `${server}/survey/update-survey-status/${id}`,
            { status: status, worker: worker },
            { withCredentials: true }
        )
        .then((res) => {
            toast.success("Survey started!");
            userBalanceForTaskDoneUpdateHandler();
        })
        .catch((error) => {
            toast.error(error.response.data.message);
        });
    };

    const userBalanceForTaskDoneUpdateHandler = async () => {
        const userId = user?._id;
        const oldBalance = user?.bonusBalance;
        const newPayment = data?.taskPayment;
        const newBalance = Number(oldBalance) + Number(newPayment);

        const oldTE = user?.totalEarning;
        const newTE = Number(oldTE) + Number(newPayment);

        await axios.put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            { newBalance: newBalance, newTE: newTE, taskE: newPayment },
            { withCredentials: true }
        )
        .then((res) => {
            toast.success("You've Earned!");
            navigate('/surveys');
            window.location.reload();
        })
        .catch((error) => {
            toast.error(error.response.data.message);
        });
    };

    return (
        <>       
            <div className="bg-white">
                {data ? (
                    <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                        <div className="w-full py-5">
                            <div className="block w-full 800px:flex">
                                <div className="w-full pt-5">
                                    <h5 className="text-[16px] leading-8 text-gray-700 mb-2">
                                        Click the 'Start' button bellow to complete the survey/offer and earn TST{data?.taskPayment}
                                    </h5>
                                    <h5 className="text-[16px] leading-8 text-gray-700 mb-2">
                                        Note: Offers/Survey are updated regularly; the landing page bellow may have been changed. This is normal.
                                    </h5>
                                    <div className="w-full 800px:w-[50%] mb-4 h-auto shadow rounded px-4 py-4 flex items-center justify-center">
                                        <img
                                            src={data?.imageurl}
                                            alt="survey"
                                            className="object-cover w-full h-full rounded"
                                        />
                                    </div>
                                    <h5 className="text-[18px] leading-8 text-gray-700 whitespace-pre-line">
                                        {data?.description}
                                    </h5>
                                    <br />

                                    <div className="py-2 flex 800px:w-[70%] items-center justify-between">
                                        <div className="flex">
                                            <h5 className={`${styles.productDiscountPrice}`}>
                                                <small className="font-[400] text-[13px] text-[green]">Earning:</small> TST{data.taskPayment}
                                            </h5>
                                        </div>
                                    </div>
                                    <br />

                                    <Link to={data?.taskRefURL} target="_blanck">
                                        <button
                                            className={`${styles.button} !h-[42px] !text-[14px] text-white 800px:ml-2`}
                                            onClick={() => setStartSurvey(true)} // Trigger countdown when Start is clicked
                                        >
                                            Start
                                        </button>
                                    </Link>

                                    {startSurvey && (
                                        <>
                                            {user && user?.role === "Worker" && (
                                                <div>
                                                    <div className="block 800px:flex w-full 800px:w-[400px]">
                                                        <div>
                                                            <h5
                                                                className={`${styles.button} !bg-[teal] !w-[200px] text-center !h-[42px] text-white 800px:ml-2 `}
                                                            >
                                                                Survey started ... <span className="text-[teal]">{countdown} sec</span>
                                                            </h5>
                                                        </div>

                                                        {isClaimTime ? (
                                                            <button
                                                                className={`${styles.button} !h-[42px] !text-[14px] text-white 800px:ml-2`}
                                                            >
                                                                TST{data?.taskPayment} Credited!
                                                            </button>
                                                        ) : (
                                                            <input
                                                                className={`${styles.button} !bg-[grey] !text-[14px] text-center !h-[42px] text-white 800px:ml-2 `}
                                                                value="Checking..."
                                                            />
                                                        )}
                                                    </div>

                                                    </div>
                                            )}
                                        </>
                                    )}

                                    <h5>Do not refresh page till the survey/offer is completed!</h5>
                                                
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full py-5 px-10">
                        <h1 className="text-[30px] text-[500]">Loading survey...!</h1>
                        <p>It may take a few seconds</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default SurveyDetails;