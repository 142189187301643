import React, { useEffect, useState } from 'react';
import CPAOfferCardHeader from '../components/Route/CPAOfferCard/CPAOfferCardHeader';
import CPAOfferCard from '../components/Route/CPAOfferCard/CPAOfferCard';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import axios from "axios";
import { server } from "../server";
import SpinnerFull from "../components/Spinner/SpinnerFull";
import { Link } from "react-router-dom";
import AdsCard from "../components/Route/AdsCard/AdsCard";
import { toast } from "react-toastify";
import { format } from "date-fns";  // Use date-fns for date formatting and comparison
import { useSelector } from "react-redux";


function CPAOffersPage() {

    const { user } = useSelector((state) => state.user);
    const [data, setData] = useState([]);
    const [mycontrolData, setMycontrolData] = useState([]);
    const [popUp2Open3, setPopUp2Open3] = useState(false); 
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


  useEffect(() => {
    axios.get(`${server}/cpaoffer/get-all-offers`, {withCredentials: true}).then((res) => {
        setData(res.data.cpaoffers);
    })
  }, []);  

  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  useEffect(() => {
    if (!user) return; // Early return if there is no user
    
    // Get the current date in 'yyyy-MM-dd' format
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    
    // Check if taskCompletionDate exists for the user
    const taskCompletionDate = user.hasSubscribedDate3;  // Assuming user has `hasSubscribedDate` field
    
    console.log("User:", user); // Debugging: Log user to check properties
    console.log("Task Completion Date:", taskCompletionDate); // Debugging: Log the task completion date
    
    // If no taskCompletionDate or user has not subscribed, show the popup
    if (!taskCompletionDate || user.hasSubscribed3 === "no") {
      console.log("Popup showing because no taskCompletionDate or user hasn't subscribed.");
      setPopUp2Open3(true);
      return;  // Exit early as no need to compare dates
    }
    
    // If taskCompletionDate exists, format it to 'yyyy-MM-dd'
    const storedTaskDate = taskCompletionDate ? format(new Date(taskCompletionDate), 'yyyy-MM-dd') : null;
    
    console.log("Stored Task Date:", storedTaskDate); // Debugging: Log stored task date
    
    // If taskCompletionDate is not the current date, show the popup for new day or incomplete task
    if (user.hasSubscribed3 === "yes" && storedTaskDate !== currentDate) {
      console.log("Popup showing because it's a new day or task hasn't been completed today.");
      setTimeout(() => {
        setPopUp2Open3(true);  // Show the popup if it's a new day or task hasn't been completed today
      }, 10000);  // Optional: Ensure this delay is necessary for UX
    }
  }, [user]);  // Make sure the effect runs when the entire `user` object changes





  // Filter data based on search term (networkName)
  const filteredData = data.filter((item) =>
    item.networkName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginate the filtered data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  // Handle next page click
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to page 1 when searching
  };






  const controlPin3 = "117755chris55";

  const mcDataToUse3 = mycontrolData && mycontrolData.find((itii) => itii.controlPin === controlPin3);


  const subscribeHandler3 = async () => {

    const userId = user?._id;

    await axios.put(
                `${server}/user/subscribetotel3/${userId}`,
                {
                  hasSubscribed3: "yes",
                    },
                { withCredentials: true }
              )
              .then((res) => {
                  setPopUp2Open3(false);
                 })
               .catch((error) => {
                toast.error(error.response.data.message);
                setPopUp2Open3(false);
              });
        }
   
  
  return (
    <div>
      <div className="w-full bg-[#F6F6F5] min-h-screen">
        <Header activeHeading={6}/>

        <div className="mt-8 w-[95%] 800px:w-[80%] mx-auto 800px:flex items-center justify-center gap-6 px-5">
        
        <Link to="/profile">
  <div className="w-full 800px:w-[40vh] mb-4 h-[40vh] sm:h-[40vh] md:h-[40vh] lg:h-[40vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/RGssKc2V/campaign-cover2.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>



<Link to="https://rytr.me/?via=totlesoft" target="_blank">
  <div className="w-full 800px:w-[40vh] mb-4 h-[40vh] sm:h-[40vh] md:h-[40vh] lg:h-[40vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/D6nJzYL/rytrcover.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

<Link to="https://singingfiles.com/show.php?l=0&u=2316937&id=68495">
  <div className="w-full 800px:w-[40vh] mb-4 h-[40vh] sm:h-[40vh] md:h-[40vh] lg:h-[40vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/tMNGs7b9/wapoland.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

<Link to="/dashboard">
  <div className="w-full 800px:w-[40vh] mb-4 h-[40vh] sm:h-[40vh] md:h-[40vh] lg:h-[40vh] shadow rounded px-2 py-2 flex items-center justify-center">
    <img
      src="https://i.ibb.co/Dfbn5jsM/campaign-cover1.jpg"
      alt="Totlesoft"
      className="object-contain w-full h-full rounded"
    />
  </div>
</Link>

        </div>


        <br />
        <CPAOfferCardHeader />
        <>
  <div className="search-container mb-4 w-full 800px:w-[80%] mx-auto flex items-center justify-center">
    <input
      type="text"
      value={searchTerm}
      onChange={handleSearchChange}
      placeholder="Search by network name"
      className="p-2 border border-teal-600 rounded"
    />
  </div>

  {filteredData.length > 0 ? (
    <div className="md:max-w-5xl md:mx-auto p-4 px-6 m-3 rounded mt-16 relative z-10 bg-white">
      <div className="w-full">
        {paginatedData.map((i, index) => (
          <CPAOfferCard data={i} key={index} />
        ))}
      </div>

      {/* Pagination controls */}
      <div className="mt-4 text-center">
        {currentPage > 1 && (
          <button
            onClick={prevPage}
            className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
          >
            Prev
          </button>
        )}

        {currentPage < totalPages && (
          <button
            onClick={nextPage}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Next
          </button>
        )}
      </div>
    </div>
  ) : (
    <h1 className="mt-8 w-full 800px:w-[80%] mx-auto flex items-center justify-center px-6">
      <SpinnerFull />
    </h1>
  )}
</>
       <br />

       {popUp2Open3 && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">

          <AdsCard 
          title = {mcDataToUse3 && mcDataToUse3.adsTitle3 || "Make $400+ weekly with Totlesoft & DeepSeek"}
          description = {mcDataToUse3 && mcDataToUse3.adsDetails3 || "Join the top freelancers that are making $100+ weekly using Totlesoft and DeepSeek"}
          imageUrl = {mcDataToUse3 && mcDataToUse3.adsCoverURL3 || "https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"}
          buttonText = {mcDataToUse3 && mcDataToUse3.buttonText3 || "Join Community"}
          buttonLink = {mcDataToUse3 && mcDataToUse3.adsLink3 || "https://t.me/+fShja-eUKkA2YzFk"}
          popUp2Open = {popUp2Open3}
          setPopUp2Open = {setPopUp2Open3}
          subscribeHandler = {subscribeHandler3}
          />

          </div>
          )}
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
}
       
export default CPAOffersPage;