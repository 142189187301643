import React from 'react';
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import BackgroundAnimate2 from '../BackgroundAnimate2';
import totlesoft from '../Assests/images/logo.png';
import suveooNG from '../Assests/images/surveoong.png';
import FlippingImage2 from "../components/FlippingImage2";

const SurveooNGPage = () => {

  return (
    <div>
      <div className="min-h-screen px-4 flex flex-col items-center text-white font-medium overflow-y-auto h-screen">
        <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
            <div className="mt-4 text-4xl font-bold flex items-center text-center">
              <h5>Make $200 - $1000 Monthly</h5>
            </div>
            <div className="text-base mt-2 flex items-center text-center">
              <h5 className="font-[500] text-[20px]">Responding to surveys. Join FREE. Multiple withdrawal options available.</h5>
            </div>
            <br />
            <br />
            <div className="relative mt-5">
              <FlippingImage2 />
            </div>
            <br />
            <div className="text-base mt-4 mb-4 flex items-center">
              <h5 className="font-[600] text-[25px] text-[#000] text-center">200+ Surveys Available.</h5>
            </div>

            <Link to="https://bestlocker.eu/iframe/9dc1e68e-f6b6-11ef-82fe-8a5fb7be40ea?allow_translate=1">
            
            <span className="bg-[#5E2F7D] px-3 py-2 text-[#fff] font-[500] !text-[17px] flex items-center text-center cursor-pointer">
                  Join Now           
            </span>

              </Link>
           
            
            <br />

            <Link to="https://youtu.be/yaJRFGx9aCE?si=WPr4eqXtHToXsTo7" target="_blank">
              <div className="text-base mt-4 mb-4 flex items-center cursor-pointer">
              <h5 className="font-[600] text-[20px] text-[blue] ml-2 text-center">Watch Video Guide</h5>
            </div>
            </Link>

            {/* Include the div for the external content */}
            

            <div className="text-base mt-4 flex items-center">
              <img src={suveooNG} width={80} height={60} className="mr-2" />
              <img src={totlesoft} width={80} height={80} />
            </div>

            </div>
        </div>
      </div>
      <BackgroundAnimate2 />      
      
    </div>
  );
};

export default SurveooNGPage;
