import React from 'react';

const CPAOfferCardHeader = () =>{
    return (
        <div className='w-full h-[100px] mb-10 relative z-1 bg-[#F6F6F5] items-center justify-center text-center pt-8 px-8'>
            <h1 className='text-[30px] font-[500]'>My CPA Offers</h1>
            <p>Register with each network to obtain your link so you can get your payout.</p>
        </div>
    )
}

export default CPAOfferCardHeader;