import React, { useEffect, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { PaystackButton } from "react-paystack";
import Ratings from "../Products/Ratings";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import "../../components/YoutubeEmbed.css";
import tPay from "../../Assests/logos/totlesoftPay.png";
import { dollarRate } from "../../dollarRate";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineFolderCopy } from "react-icons/md";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Spinner from "../../components/Spinner/Spinner";

  const ProductAffiliateDetails = ({ data }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [pemail, setPemail] = useState("");
  const [cemail, setCemail] = useState("");
  const [prodId, setProdId] = useState(null);
  const [affName, setAffName] = useState(null);
  const [open, setOpen] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);
  const [openCrypto, setOpenCrypto] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [pDtoUse, setPDtoUse] = useState([]);
  const [createCPayInvoice, setCreateCPayInvoice] = useState(true);
  const [showPayCPButton, setShowPayCPButton] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceOrderId, setInvoiceOrderId] = useState("");

  const { id, username } = useParams();

  const YoutubeVideoId = data?.videoSupportId;

  useEffect(() => {
    let pID = id;
    setProdId(pID);

    let sUA = username;
    setAffName(sUA);
  }, [])

    useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    }, [data]);

    const pIFocus = products && products.find((itm) => itm._id === prodId)

    const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

   
  const avg =  totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

    const publicKey = "pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964";
    const dollar_rate = dollarRate;
    const pOfProduct = pIFocus?.discountPrice;
    const paymentInNaira = Math.round(pOfProduct * 100 * dollar_rate)
       
  const componentProps = {
    email: email,
    amount: paymentInNaira,
    publicKey,
    referenceID: +Math.floor((Math.random() * 1000000000) + 1),
    text: "Pay now",
    onSuccess: () => paymentHandler(),
    onClose: function() {
      alert('Transaction was not completed, window closed.');
    },
   
  }

  
  const paymentHandler = async () => {

    const pPrice = pIFocus?.discountPrice;
    const prID = pIFocus?._id;
    const prN = pIFocus?.name;
    const shopId = pIFocus.shopId;
    const affCommision = pIFocus?.commision * pIFocus?.discountPrice / 100;
    
    await axios
            .post(
            `${server}/ordersbyaffiliate/create-oba`,
            {
              affilator: affName,
              buyerId: email,
              productPrice: pPrice,
              productId:  prID,
              productName: prN,
              shopId,
              affCommision,
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("Order was successful!");
              productUpdateHandler();
              navigate("/order/success");
              window.location.reload();
              })
           .catch((error) => {
           toast.error("Ops! Something went wrong!");
          });
  }


  const paypalHandler = async () => {

    const pPrice = pIFocus?.discountPrice;
    const prID = pIFocus?._id;
    const prN = pIFocus?.name;
    const shopId = pIFocus.shopId;
    const affCommision = pIFocus?.commision * pIFocus?.discountPrice / 100;
    
    await axios
            .post(
            `${server}/ordersbyaffiliate/create-oba`,
            {
              affilator: affName,
              buyerId: pemail,
              productPrice: pPrice,
              productId:  prID,
              productName: prN,
              shopId,
              affCommision,
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("Order was successful!");
              productUpdateHandler();
              navigate("/order/success");
              window.location.reload();
              })
           .catch((error) => {
           toast.error("Ops! Something went wrong!");
          });
  }


  const cryptoHandler = async () => {

    const pPrice = pIFocus?.discountPrice;
    const prID = pIFocus?._id;
    const prN = pIFocus?.name;
    const shopId = pIFocus.shopId;
    const affCommision = pIFocus?.commision * pIFocus?.discountPrice / 100;
    
    await axios
            .post(
            `${server}/ordersbyaffiliate/create-oba`,
            {
              affilator: affName,
              buyerId: cemail,
              productPrice: pPrice,
              productId:  prID,
              productName: prN,
              shopId,
              affCommision,
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("Order was successful!");
              productUpdateHandler();
              navigate("/order/success");
              window.location.reload();
              })
           .catch((error) => {
           toast.error("Ops! Something went wrong!");
          });
  }


  const productUpdateHandler = async () => {
            
    const oldPSold = Number(pIFocus.sold_out);
    const newPSold = Number(oldPSold) + 1;

    const oldStock = Number(pIFocus.stock);
    const newStock = Number(oldStock) - 1;

    
    await axios
    
          .put(
            `${server}/product/update-product-status/${id}`,
            {
                sold_out: newPSold,
                stock: newStock,
            },
            { withCredentials: true }
          )
          .catch((error) => {
            toast.error("Ops! product stock update failed!");
          });
  }


  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Affiliate Product Sale",
            amount: {
              currency_code: "USD",
              value: pOfProduct,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalHandler();
      }
    });
  };


  const pForCrypto = String(pIFocus?.discountPrice);

  const invData = {
    amount:  pForCrypto,
    currency: "USD",
    additional_data: cemail,
}


const creatCPInvoice = async () => {
setIsLoading(true);

try {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { data } = await axios.post(
    `${server}/payment/crypto-checkout`,
    invData,
    config
  );

  const url = data.url;
  const order_id = data.order_id;
  
  if(url !== null && order_id !== null){
    setInvoiceUrl(url);
    setInvoiceOrderId(order_id);
    setCreateCPayInvoice(false);
    setShowPayCPButton(true);
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
};  


const confirmCPaymentInfo = async () => {
setIsLoading(true);

try {

const order_id = invoiceOrderId;

     
const { data } = await axios.get(
    `${server}/payment/crypto-payment-info/${order_id}`);
    setPDtoUse(data);

    if(!data){
      toast.error("Payment not found with this order Id")
    }

  const status = pDtoUse.cryptopayments[0].status;
  const is_final = pDtoUse.cryptopayments[0].is_final;

  if(status === "paid" && is_final === true){
           
    cryptoHandler();
     
  }else{
    toast.error("Payment is still processsing");
    setIsLoading(false);
  }
}
catch (error) {
  toast.error(error);
  setIsLoading(false);
}
}; 


const orderID = invoiceOrderId;

  const writeTextToClipboard = (text) => {
    text = orderID;
    navigator.clipboard.writeText(text);
    toast.success("Id Copied!");
  };

  
  
  return (
    <>
    {isLoading ? <Spinner /> : (
      <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
	        
          <div className="block w-full 800px:flex">	
	
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data && data.images[select]?.url}`}
                  alt=""
                  className="w-[80%]"
                />
                <div className="w-full flex">
                  {data &&
                    data.images.map((i, index) => (
                      <div
                        className={`${
                          select === 0 ? "border" : "null"
                        } cursor-pointer`}
                      >
                        <img
                          src={`${i?.url}`}
                          alt=""
                          className="h-[200px] overflow-hidden mr-3 mt-3"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div>
              <div className="w-full 800px:w-[50%] pt-5">
                <h1 className={`${styles.productTitle}`}>{data.name}</h1><br />

                {YoutubeVideoId !== "" ? (
                  <div className="w-full">
                  <YoutubeEmbed embedId={data?.videoSupportId} />
                  </div>
                ) : null}
                <br />

                <p className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
                {data.description.length > 150 ? data.description.slice(0, 150) + "...see full product details bellow:" : data.description}
                </p>
                
                <div className="flex pb-4">
                    <Ratings rating={data?.ratings} />
                  </div>

                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                    {data.discountPrice}$
                  </h4>
                  
                  <h3 className={`${styles.price}`}>
                    {data.originalPrice ? data.originalPrice + "$" : null}
                  </h3>

		<h4 className="text-[blue] ml-4">sold: {data.sold_out}</h4>
                </div>
                <br />

            <div>
            <p className="pl-2 text-[#077f9c]">BUY NOW: Choose payment option:</p>
            <div className="flex justify-between w-[80%] 800px:w-[50%]">
            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center`}
            //onClick={() => setOpen(true)}
            >
                Naira
            </button>

            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center !px-3`}
            onClick={() => setOpenPaypal(true)}
            >
                USD/PayPal
            </button>

            <button 
            className={`${styles.button} !w-[80px] !text-[12px] text-[#fff] justify-center items-center text-center`}
            onClick={() => setOpenCrypto(true)}
            >
                Crypto
            </button>
            </div>
            </div>

            {open && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
              <div className={`w-[90%] 800px:w-[30%] bg-white shadow rounded h-[25%] min-h-[40vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <div>
          <form>
          <div>
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700"
              >
                Express Payment - NGN (Enter email only to pay now)
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="abc@gmail.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
           </div>
        </form>

        <PaystackButton {...componentProps}
             className={`${styles.button} !bg-[green] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
             type="submit"
             
             />
             <div>
             <img
                src={tPay} 
                width='120px'
                alt='totlesoftPay'
             />
             </div>
        </div>
       </div>
      </div>
    </div>
    )}



          {openPaypal && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                 <div
            className={`w-[90%] 800px:w-[50%] bg-white shadow rounded h-[unset] min-h-[50vh] p-3`}
          >
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenPaypal(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
         <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
         <div>
             <img
                src={tPay} 
                width='120px'
                alt='totlesoftPay'
             />
             </div>
              <br />

              <form>
          <div>
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700"
              >
                Enter email to pay now: <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="abc@gmail.com"
                  required
                  value={pemail}
                  onChange={(e) => setPemail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
           </div>
           <br />
        </form>


          <PayPalScriptProvider
            options={{
            "client-id":
            "AWhLs3ILRhUxqvgpcKjd6rvoU47rg_l7Yyc_GW-InHPkuLbpCouEE29HniCePvK8L1QxbneMxJezAzUq",
            }}
          >
          <PayPalButtons
          style={{ layout: "vertical" }}
          onApprove={onApprove}
          createOrder={createOrder}
          />
        </PayPalScriptProvider>       
        </div>
       </div>
      </div>
    </div>
    )}



            {openCrypto && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
              <div className={`w-[90%] 800px:w-[30%] bg-white shadow rounded h-[70%] min-h-[50vh] p-3`}>
             <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpenCrypto(false)}
                className="cursor-pointer"
              />
            </div>
            
        <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
          <>
          <h5 className="text-[red] text-[14px] font-[500]">Keep page open till the payment has been marked as "paid"</h5>
          <br />
     {createCPayInvoice && 
     <>
      <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
        <p>Enter email and click button bellow to generate crypto payment invoice</p><br /> 

        <form>
          <div>
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700"
              >
                Enter email: <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="abc@gmail.com"
                  required
                  value={cemail}
                  onChange={(e) => setCemail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
           </div>
           <br />
        </form>

          <div>
             <button
                 className={`${styles.button} !h-[42px] text-white`}
                 onClick={creatCPInvoice}
               >
                 Generate
               </button>
             </div>
          </div>
     </>
     }


     {showPayCPButton && 
     <>
 <div className="w-full 800px:w-[90%] 800px:mt-0 mt-8">
 <p className="text-[green] font-[600]">Invoice generated successfully</p> 
 <h5 className="text-[16px] font-[600]">Order Id: {invoiceOrderId} 
 <MdOutlineFolderCopy 
 onClick={writeTextToClipboard}
 size={20}
 className="cursor-pointer mb-2"
 />
 </h5> 
 <h5 className="text-[red] text-[14px] font-[500]">Please save this order_id, it will be requested if there's an error with this order.</h5><br />

 <Link to={`${invoiceUrl}`} target="_blank">
 <h5 className={`${styles.button} text-white !h-[42px] !rounded`}>
  Pay 
 </h5>
 </Link>
 </div>
 <br />
 <h5>Wait atleast a minute after the payment has marked 'Payment is paid' before clicking the Completed button.</h5>
 <h5 className={`${styles.button} text-white !h-[42px] !rounded`}
 onClick={confirmCPaymentInfo}
 >
  Completed 
 </h5>
 </>
     }  
        </>      
        </div>
        <br />
        <div>
             <img
                src={tPay} 
                width='120px'
                alt='totlesoftPay'
             />
        </div>
      </div>
    </div>
    )}

        

               
                
                <div className="flex items-center pt-8">
                  <Link to={`/shop/preview/${data?.shop._id}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div className="pr-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="pb-3 text-[15px]">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                  <Link to="https://wa.me/447418375846" target="_blank" className="800px:ml-3">
                  <div
                    className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11`}
                  >
                    <span className="text-white flex items-center">
                      WhatsApp <AiOutlineMessage className="ml-1" />
                    </span>
                  </div>
                </Link>
                </div>
              </div>
            </div>
          </div> <br />

          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
    )}
    </>
   
   );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h5>
          {active === 2 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(3)}
          >
            Seller Information
          </h5>
          {active === 3 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <p className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
            {data.description}
          </p>
        </>
      ) : null}

      {active === 2 ? (
        <div>
          {data && data.reviews === undefined ? (
            <h1 className="w-full flex justify-center font-[500] text-[20px]">No review given yet</h1>
          ): (
            <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data &&
            data.reviews.map((item, index) => (
              <div className="w-full flex my-2">
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=""
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div className="pl-2 ">
                  <div className="w-full flex items-center">
                    <h1 className="font-[500] mr-3">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p>{item.comment}</p>
                </div>
              </div>
            ))}

          <div className="w-full flex justify-center">
            {data && data.reviews.length === 0 && (
              <h5>No Reviews given for this product!</h5>
            )}
          </div>
        </div>
          )}
        </div>
      ) : null}

      {active === 3 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className="flex items-center">
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-3">
                  <h3 className={`${styles.shop_name}`}>{data.shop.name}</h3>
                  <h5 className="pb-2 text-[15px]">
                    ({averageRating}/5) Ratings
                  </h5>
                </div>
              </div>
            </Link>
            <p className="pt-2">{data.shop.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="text-left">
              <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop?.createdAt?.slice(0, 10)}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Products:{" "}
                <span className="font-[500]">
                  {products && products.length}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Reviews:{" "}
                <span className="font-[500]">{totalReviewsLength}</span>
              </h5>
              <Link to={`/shop/preview/${data?.shop._id}`}>
                <div
                  className={`${styles.button} !rounded-[4px] !h-[39.5px] mt-3`}
                >
                  <h4 className="text-white">Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductAffiliateDetails;

// https://marketplace.abantecart.com/image/thumbnails/1a/ad/main_image_png-109265-750x400.png