import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const SurveyCard = ({ data, userDoingTask }) => {

    const isDoneTaskAlready =  data.workers.some((item) => item['workerId'] === userDoingTask );
   
  
  return (
    <>
    
      <div className="w-full h-[500px] bg-[#F6F6F5] rounded-lg shadow-sm p-3 relative cursor-pointer">
        <h4 className="font-[500] text-[14px]">
       {data.name.length > 20 ? data.name.slice(0, 20) + "..." : data.name}
       </h4>  
       
        

        <Link to={`/surveys/${data._id}`}>
          <img
            src={data?.imageurl}
            alt="code vendor"
            className="w-full object-contain py-3"
          />
          </Link>

          <div className="block">
       <h4 className="text-[14px]">
       <small className="font-[400] text-[12px] text-[blue]">Contry: </small> {data?.eligibleCountry}
        </h4>  

        <h4 className="text-[14px]">
        <small className="font-[400] text-[12px] text-[blue]">Devices: </small> {data?.eligibleDevice}
        </h4>     
       </div>
       <hr />
        

          <h4 className="py-2 font-[400]">
          {data.description.length > 60 ? data.description.slice(0, 60) + "..." : data.description}
          </h4>

         <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
              <small className="font-[400] text-[12px] text-[blue]">Payment:</small> TST{data.taskPayment}
              </h5>
              
            </div>
            </div>

            {isDoneTaskAlready ? (
                <div className={`${styles.button} !bg-[grey] mb-5`}>
                <span className="text-[#fff] font-[Poppins] text-[16px]">
                   completed!
                </span>
           </div>
            ) : (
        <Link to={`/surveys/${data._id}`}>
        <div className={`${styles.button} mb-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[16px]">
                    Details
                 </span>
            </div>
        </Link>
            )}        
        
      </div>
      
    </>
  );
};

export default SurveyCard;
