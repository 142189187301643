import React from 'react';
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

const AdsCard = ({ title, description, imageUrl, buttonText, buttonLink, popUp2Open, setPopUp2Open, subscribeHandler }) => {
  return (
    <div className="max-w-[90%] md:w-1/2 p-4">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
        {/* Image Section */}
        <div className="w-full md:w-1/2 h-64 md:h-auto">
          <img
            src={imageUrl}
            alt="Totlesoft"
            className="object-cover w-full h-full"
          />
        </div>

        {/* Text Content Section */}
        <div className="p-6 flex flex-col justify-between">
          <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
          <p className="text-gray-600 mb-4">{description}</p>
          <Link
            to={buttonLink} target="_blank"
            className="inline-block text-white bg-teal-600 hover:bg-teal-800 py-2 px-4 rounded-md text-center"
            onClick={subscribeHandler}
          >
            {buttonText}
          </Link>

          <RxCross1 size={20} className="cursor-pointer mt-2" onClick={() => setPopUp2Open(false)} />
        </div>
      </div>
    </div>
  );
};

export default AdsCard;

// usage

/*

<AdsCard 
title = {}
description = {}
imageUrl = {}
buttonText = {}
buttonLink = {}
/>

*/