import { isVisible } from "@testing-library/user-event/dist/utils";
import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./countup.css";
import growth from "../Assests/images/line-chart.gif";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function CounterUp({ className, ...rest }) {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  const [viewPortEntered, setViewPortEntered] = useState(false);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

 

  return (
    <>
      <section className="counter">
        <div className="counter-row">
        <div className="counter-column">
        <img
            src={growth}
            width='100px'
            alt=""
            className="ml-2 mr-2"
          />
        </div>

          <div className="counter-column">
            <strong data-number="905070">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={905070} suffix="+" duration={5} delay={0}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={() => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>
              {t('Stats1a')} <br />
              {t('Stats1b')}
            </span>
          </div>

          <div className="counter-column">
            <strong data-number="1260">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={1260} suffix="+" duration={7} delay={0}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={() => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>
              {t('Stats2a')} <br />
              {t('Stats2b')}
            </span>
          </div>

          <div className="counter-column">
            <strong data-number="4260">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={4260} suffix="+" duration={7} delay={0}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={() => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>
              {t('Stats3a')} <br />
              {t('Stats3b')}
            </span>
          </div>
          
        </div>
      </section>
    </>
  );
}


export default CounterUp;