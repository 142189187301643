import React, { useEffect, useState } from "react";
//import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SurveyDetails from "../components/Survey/SurveyDetails";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import Spinner from "../components/Spinner/Spinner";


const SurveyDetailsPage = () => {
  const [allSurveys, setAllSurveys] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/survey/get-all-surveys`, {withCredentials: true}).then((res) => {
      setAllSurveys(res.data.surveys);
        setIsLoading(false);
    })
  }, []);
  

    const data = allSurveys && allSurveys.find((i) => i._id === id);
      
   
 
  return (
    <>
    {isLoading ? 
    <Spinner /> : (
        <div>
      <Header />
      {data ? (
        <SurveyDetails data={data} />
      ) : 
        <div className="w-full py-5 px-10">
          <h1 className="text-[30px] text-[500]">Ops! Loading taking longer!</h1>
          <p>Please wait a little or try again later.</p>
        </div>
     }
     
    </div>
    )}
    </>
    
  );
};

export default SurveyDetailsPage;
