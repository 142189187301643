import { React, useState, useEffect } from "react";
import { Country } from "country-state-city";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { TbWorldDownload } from "react-icons/tb";
import styles from "../../styles/styles";
import { freelanceCategoriesData } from "../../static/data";
import { Link, useParams } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'


const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const SingupRef = () => {

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [uname, setUName] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [specialization, setSpecialization] = useState("");

   const { username } = useParams();

   const referrer = username;
  

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  const bonusBalance = 1;

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(`${server}/user/create-user`, { name, uname, email, phoneNumber, referrer, country, bonusBalance, password, avatar, specialization })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        setName("");
        setUName("");
        setEmail("");
        setPhoneNumber(0);
        setCountry("");
        setPassword("");
        setAvatar();
        setSpecialization();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      <div className="w-[200px] justify-center">
        <div className="flex w-[full] justify-start mx-6 items-center">
          <div>
              <TbWorldDownload
                size={30}
                className="mb-3 mt-10 cursor-pointer"
                onClick={() => setDropDown(!dropDown)}
              />
                    
            {dropDown ? (
              <ul className="pb-4 px-4 w-[210px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm">
              <li>
                <span>{t('language')}</span>
              </li>
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code) && setDropDown(false)
                    }}
                  >
                    <span
                      className={`fi fi-${country_code} mx-2`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    ></span>
                    {name}
                  </a>
                </li>
              ))}
            </ul>
            ) : null}
          </div>
        </div>
      </div>

      <div className="min-h-screen relative bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0c0a00]">
          {t('r_user_Reg_title')}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">

            <div>
              <button
                className="group relative w-full h-[40px] flex justify-center mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#0c0a00] hover:bg-blue-700"
              >
                Find Task / Sell Projects
              </button>
            </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_full_name_label')} <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="fullName"
                  autoComplete="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_username_label')} <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="username"
                  autoComplete="username"
                  required
                  value={uname}
                  onChange={(e) => setUName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_r_email_label')} <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone: <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  required
                  value={phoneNumber}
                  onChange={(e) =>  setPhoneNumber(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
            <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Skill Strength: <span className="text-red-500">*</span>
              </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          >
            <option value="Select">Select</option>
            {freelanceCategoriesData &&
              freelanceCategoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />

            <div>
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_country_label')} <span className="text-red-500">*</span>
              </label>
                    <div className="mt-1">
                    <select
                      name=""
                      id=""
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                       {t('cycty')}
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
              <h6
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_c_sub')} <span className="text-blue-500">{t('c_sub_email')}</span>
              </h6>
            </div>

            <div>
              <label
                htmlFor="referrer"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_ref_label')} 
              </label>
              <div className="mt-1">
                 <h5 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                {referrer}
                </h5>
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                {t('r_r_password_label')} <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span>{t('r_upload_a_file')}</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                {t('r_r_submit')}
              </button>
            </div>
            <div className={`${styles.noramlFlex} w-full`}>
              <h4>{t('r_already_have_an_account')}</h4>
              <Link to="/login" className="text-blue-600 pl-2">
                {t('r_r_sign_in')}
              </Link>
              </div><br />
          </form>
        </div>
      </div>
    </div>
    </>
    )}
    </>
    
  );
};


export default SingupRef;


