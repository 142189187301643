import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header2 from "../components/Layout/Header2";
//import Spinner from "../components/Spinner/Spinner";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import ExtProductCard from "../components/Route/ExtProductCard/ExtProductCard";
import styles from "../styles/styles";
import { categoriesData } from "../static/data";
import axios from "axios";
import { server } from "../server";
import { Link } from "react-router-dom";
import ProductPageUpperButtonBar from "../components/ProductPageUpperButtonBar";
import AdsCard from "../components/Route/AdsCard/AdsCard";
import { toast } from "react-toastify";
import { format } from "date-fns";  // Use date-fns for date formatting and comparison
import worker from "../Assests/images/worker.gif";


const ProductsPage = () => {
  const {allProducts} = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [categorry, setCategorry] = useState("");
  const [active, setActive] = useState(1);
  //const [isLoading, setIsLoading] = useState(false);
  const [extP, setExtP] = useState([]);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [popUp2Open, setPopUp2Open] = useState(false);  


  useEffect(() => {
    axios.get(`${server}/extproduct/get-all-extproducts`, {withCredentials: true}).then((res) => {
      setExtP(res.data.extproducts);
    })
  }, []); 
  
  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  useEffect(() => {
    if (!user) return; // Early return if there is no user
    
    // Get the current date in 'yyyy-MM-dd' format
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    
    // Check if taskCompletionDate exists for the user
    const taskCompletionDate = user.hasSubscribedDate;  // Assuming user has `hasSubscribedDate` field
    
    console.log("User:", user); // Debugging: Log user to check properties
    console.log("Task Completion Date:", taskCompletionDate); // Debugging: Log the task completion date
    
    // If no taskCompletionDate or user has not subscribed, show the popup
    if (!taskCompletionDate || user.hasSubscribed === "no") {
      console.log("Popup showing because no taskCompletionDate or user hasn't subscribed.");
      setPopUp2Open(true);
      return;  // Exit early as no need to compare dates
    }
    
    // If taskCompletionDate exists, format it to 'yyyy-MM-dd'
    const storedTaskDate = taskCompletionDate ? format(new Date(taskCompletionDate), 'yyyy-MM-dd') : null;
    
    console.log("Stored Task Date:", storedTaskDate); // Debugging: Log stored task date
    
    // If taskCompletionDate is not the current date, show the popup for new day or incomplete task
    if (user.hasSubscribed === "yes" && storedTaskDate !== currentDate) {
      console.log("Popup showing because it's a new day or task hasn't been completed today.");
      setTimeout(() => {
        setPopUp2Open(true);  // Show the popup if it's a new day or task hasn't been completed today
      }, 10000);  // Optional: Ensure this delay is necessary for UX
    }
  }, [user]);  // Make sure the effect runs when the entire `user` object changes
  
  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category !== "Course");
      setData(d);   
  }, [allProducts]);


  const controlPin2 = "114455chris2";

  const mcDataToUse2 = mycontrolData && mycontrolData.find((itii) => itii.controlPin === controlPin2);
  

  const searchbyCategoryHandler = () => {
    const sbyC = data && data.filter((i) => i.category === categorry);
    setData(sbyC);
  }  

  const searchbyCategoryHandler2 = () => {
    const sbyC2 = extP && extP.filter((i) => i.category === categorry);
    setExtP(sbyC2);
  }  


  const subscribeHandler = async () => {

    const userId = user?._id;

    await axios.put(
                `${server}/user/subscribetotel/${userId}`,
                {
                  hasSubscribed: "yes",
                    },
                { withCredentials: true }
              )
              .then((res) => {
                  setPopUp2Open(false);
                 })
               .catch((error) => {
                toast.error(error.response.data.message);
                setPopUp2Open(false);
              });
        }




  return (
  <div>
      <Header2 activeHeading={2} />
      <br />
      <div className={`${styles.section} bg-[#F6F6F5] px-4`}>
      <div className="w-full sticky 800px:mt-0 mt-[18%]">
              <ProductPageUpperButtonBar />
            </div>

              <div className="w-full flex items-center text-center justify-center">
              <Link to="https://ylkhts.cc/?id=3596799" target="_blank">
              <h4 className="mt-5 text-center text-[blue] font-[600] text-[18px] cursor-pointer">
              Grow Your Social Media Accounts Fast For FREE using YLHits 
              </h4>
              </Link>
            <Link to="https://ylkhts.cc/?id=3596799" target="_blank">
            <img
            src={worker}
            width='60px'
            alt=""
            className="ml-2 mt-2"
          />
          </Link> 
         
          </div>

      <div className="w-full flex px-9 pt-2 pb-2 mt-4"> 
      <div className="relative">
            <h5
              className={
                `${
                  active === 1 ? "text-[teal]" : "text-[#000]"
                } text-[15px] leading-5 font-[500] cursor-pointer 800px:text-[16px]`
              }
              onClick={() => setActive(1)}
            >
              Hot Products
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>


          <div className="relative">
            <h5
              className={
                `${
                  active === 2 ? "text-[teal]" : "text-[#000]"
                } text-[15px] ml-4 leading-5 font-[500] cursor-pointer 800px:text-[16px]`
              }
              onClick={() => setActive(2)}
            >
              External Products
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>

          
          <div className="relative">
            <Link to="/cpaoffers">
            <h5
              className={
                `${
                  active === 3 ? "text-[teal]" : "text-[#000]"
                } text-[15px] ml-4 leading-5 font-[500] cursor-pointer 800px:text-[16px]`
              }
           >
              CPA Offers
            </h5>
            </Link>
            {active === 3 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
      </div>

      <br />
           

      {active === 2 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
            onClick={searchbyCategoryHandler2}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>
          
  
          
            </div>
  
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {extP && extP.map((i, index) => <ExtProductCard data={i} key={index} />)}
          </div>
          {extP && extP.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No External products found!
            </h1>
          ) : null}
        </div>
      ) : null};


      {active === 1 ? (
        <div className={`${styles.section} bg-[#fff] p-4`}>

        <div>
              <h1 className="font-[500]">Search/Filter by:</h1>
            </div>
  
        <div className="mb-4 block 800px:flex">
            
          <div className="flex 800px:ml-4">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={categorry}
              onChange={(e) => setCategorry(e.target.value)}
            >
              <option value="Select">Category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
  
            <div className={`${styles.button} !rounded-[5px] !w-[70px] !h-[30px] mb-5 ml-1`}
            onClick={searchbyCategoryHandler}
            >
            <span className="text-[#fff] font-[Poppins] text-[14px]">
              Search
            </span>
          </div>
          </div>        
            
            </div>
  
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {data && data.map((i, index) => <ProductCard data={i} key={index} user={user} />)}
          </div>
          {data && data.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
             No products found...!
            </h1>
          ) : null}
        </div>
      ) : null};


          {popUp2Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">

          <AdsCard 
          title = {mcDataToUse2 && mcDataToUse2.adsTitle || "Make $500+ weekly with Totlesoft & ChatGPT"}
          description = {mcDataToUse2 && mcDataToUse2.adsDetails || "Join the top freelancers that are making $100 - $500+ weekly using Totlesoft and ChatGPT"}
          imageUrl = {mcDataToUse2 && mcDataToUse2.adsCoverURL || "https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"}
          buttonText = {mcDataToUse2 && mcDataToUse2.buttonText || "Join Community"}
          buttonLink = {mcDataToUse2 && mcDataToUse2.adsLink || "https://t.me/+fShja-eUKkA2YzFk"}
          popUp2Open = {popUp2Open}
          setPopUp2Open = {setPopUp2Open}
          subscribeHandler = {subscribeHandler}
          />

          </div>
          )}


      </div>
    </div>
  
  );
};

export default ProductsPage;


/*

<AdsCard 
title = "Make $300 Weekly"
description = "Join the top freelancers that are making $500+ weekly using Totlesoft and ChatGPT"
imageUrl = "https://res.cloudinary.com/totlesoft/image/upload/v1712407101/articles/wtgy7xllb6qneof6l6oi.jpg"
buttonText = "Join Community"
buttonLink = "https://t.me/+fShja-eUKkA2YzFk"
/>

*/